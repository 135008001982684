app.controller('QuickSignUpValidate', [
  '$scope',
  '$filter',
  '$timeout',
  '$location',
  'mobilePhoneService',
  'quickSingUpService',
  'locationService',
  '$window',
  function (
    $scope,
    $filter,
    $timeout,
    $location,
    mobilePhoneService,
    quickSingUpService,
    locationService,
    $window,
  ) {
    var pathArray = window.location.pathname.split('/');
    var urlParam = locationService.getQueryParams();
    $scope.userId = pathArray[pathArray.length - 2];

    if (!urlParam.mobile_phone) {
      window.location.href = '/users/sign_up/quick';
    }

    $scope.user = {
      countryCallingCode: urlParam.country_calling_code,
      mobilePhone: urlParam.mobile_phone,
    };

    $scope.state = {
      smsSent: false,
      isLoading: false,
    };
    $scope.count = 0;
    $scope.countDownText = '';
    $scope.errorText = '';
    $scope.validateCode = '';
    $scope.products = {};
    $scope.mobilePhone = '';

    $window.onpageshow = function (event) {
      if (event.persisted) {
        $window.location.reload();
      }
    };

    $scope.mobilePhone =
      $scope.user.mobilePhone &&
      intlTelInputUtils.formatNumber(
        '+' + $scope.user.countryCallingCode + $scope.user.mobilePhone,
        '',
        intlTelInputUtils.numberFormat.NATIONAL,
      );

    $location
      .search({
        country_calling_code: null,
        mobile_phone: null,
      })
      .replace();

    $scope.sendCode = function () {
      $scope.errorText = '';
      mobilePhoneService
        .sendCode({
          countryCallingCode: $scope.user.countryCallingCode,
          mobilePhone: $scope.user.mobilePhone,
        })
        .then(
          function () {
            $scope.state.smsSent = true;
            $scope.count = 60;
            countDown();
          },
          function (data) {
            if (data.data.location) {
              window.location.href = data.data.location;
            }
            $scope.errorText = data.data.error;
            scorllToError();
          },
        );
    };

    $scope.submit = function () {
      var params = {
        countryCallingCode: $scope.user.countryCallingCode,
        mobilePhone: $scope.user.mobilePhone,
        code: $scope.validateCode,
      };
      $scope.errorText = '';

      mobilePhoneService.verifyCode(params).then(
        function () {
          quickSingUpService
            .update($scope.userId, $scope.user.name, $scope.user.password)
            .then(
              function () {
                window.location.href = '/';
              },
              function (data) {
                $scope.errorText = data.data.error;
                scorllToError();
              },
            );
        },
        function (error) {
          $scope.errorText = error.data.error_messages.join();
          scorllToError();
        },
      );
    };

    var countDown = function () {
      $scope.count--;
      if ($scope.count) {
        $scope.countDownText = $filter(
          'translate',
        )('user.quick_signup.validate.hint.send', { count: $scope.count });
      } else {
        $scope.countDownText = $filter('translate')(
          'user.quick_signup.validate.hint.resend',
        );
        return;
      }
      $timeout(countDown, 1000);
    };

    var scorllToError = function () {
      $('html, body').animate(
        {
          scrollTop: $('.content.validate').offset().top - 20,
        },
        200,
      );
    };
  },
]);
