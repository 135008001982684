app.directive('storePickUpOptions', [
  'mainConfig',
  '$filter',
  'pickUpConfig',
  'addresses',
  function (mainConfig, $filter, pickUpConfig, addresses) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/shared/checkout/templates.store_pick_up_options.html'),
      scope: {},
      link: function (scope) {
        scope.language = mainConfig.localeData.loadedLanguage.code;
        scope.fields = [];
        scope.options = {};
        scope.selectedStore = null;
        scope.selectField = {};
        scope.storeOptions = [];

        var getTranslateLabel = function (fields, index) {
          var field = Object.values(fields).find(function (value) {
            return value[index].label;
          });
          return field ? field[index].label : '';
        };

        if (
          pickUpConfig.level_fields &&
          pickUpConfig.level_fields.length !== 0
        ) {
          pickUpConfig.level_fields.forEach(function (levelField, index) {
            if (index === 0) {
              scope.options[levelField] = addresses;
            } else {
              scope.options[levelField] = [];
            }
          });
          Object.keys(scope.options).forEach(function (key) {
            pickUpConfig.dropdown_fields_v2[scope.language].forEach(function (
              field,
              index,
            ) {
              if (field.field === key) {
                scope.fields.push({
                  field: field.field,
                  label:
                    field.label ||
                    getTranslateLabel(pickUpConfig.dropdown_fields_v2, index),
                });
              }
            });
          });
        } else {
          scope.storeOptions = addresses;
        }

        scope.getLabel = function (option, key) {
          if (!option) return '';
          return $filter('translateModel')(option[key]);
        };

        scope.getStoreLabel = function (option) {
          var storeAddress = scope.getLabel(option, 'store_address');
          return (
            scope.getLabel(option, 'store_name') +
            (storeAddress ? ' - ' + storeAddress : '')
          );
        };

        scope.onLevelChange = function (option, index) {
          if (!option) return;

          var nextLevelIndex = index + 1;
          scope.storeOptions = [];
          scope.selectedStore = null;
          if (pickUpConfig.level_fields.length > nextLevelIndex) {
            pickUpConfig.level_fields.forEach(function (field, levelIndex) {
              if (levelIndex === nextLevelIndex) {
                scope.options[field] = option.next_level;
              } else if (levelIndex > nextLevelIndex) {
                scope.options[field] = [];
              }
            });
          } else {
            scope.storeOptions = option.next_level;
          }
        };

        scope.shouldShowFullWidth = function (index) {
          return scope.fields.length % 2 === 1 && index === 0;
        };
      },
    };
  },
]);
