app.directive("checkoutPage", [
  '$filter'
  '$http'
  'cart'
  'flash'
  'merchantAffiliateService'
  'checkoutService'
  'trackerService'
  'featureService'
  'cartService'
  'mainConfig'
  'Analytics'
  'productService'
  'braintreeService'
  'tappayService'
  'telService'
  '$rootScope'
  '$timeout'
  '$window'
  '$q'
  'stripePublishableKey'
  'creditCardServices'
  'gaService'
  'LOCALE_CODE'
  'CROSS_BORDER_CODE'
  'selectedCountry'
  'riskResponse'
  'isCheckoutableWithoutEmail'
  'slFeatureService',
  'browserService',
  'slPixelService',
  'RecaptchaService',
  'merchantService'
  'ordersService'
  'hiidoTrackerService'
  'slLogisticsYtoService',
  'logger',
  'familyMartFreezeService',
  'staticImageHost',
  'fbService',
  (
    $filter
    $http
    cart
    flash
    merchantAffiliateService
    checkoutService
    trackerService
    featureService
    cartService
    mainConfig
    Analytics
    productService
    braintreeService
    tappayService
    telService
    $rootScope
    $timeout
    $window
    $q
    stripePublishableKey
    creditCardServices
    gaService
    LOCALE_CODE
    CROSS_BORDER_CODE
    selectedCountry
    riskResponse
    isCheckoutableWithoutEmail
    slFeatureService,
    browserService
    slPixelService
    RecaptchaService
    merchantService
    ordersService
    hiidoTrackerService
    slLogisticsYtoService
    logger
    familyMartFreezeService
    staticImageHost
    fbService
  ) ->
    loadScript = (u) ->
      d = document
      t = 'script'
      o = d.createElement(t)
      s = d.getElementsByTagName(t)[0]
      o.src = '//' + u;
      deferred = $q.defer()
      o.addEventListener 'load'
        ,(e) ->
          deferred.resolve()
          return
        ,false
      s.parentNode.insertBefore o, s
      deferred.promise
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        cartService.pageId = cart.pageId;
        scope.errors = {}
        scope.phonesCountryCodes = {}
        scope.orderCustomerPhone = ''
        scope.errors['flash'] = cart.error_message if !_.isEmpty(cart.error_message)
        scope.isLoading = false
        scope.parsedPhones = []
        scope.payment = cart.payment_method # hack for apple pay scope binding
        scope.countries = []
        scope.shoplinePaymentV2Content = {};
        scope.shoplinePaymentV2Session = {};
        useShoplinePaymentV2 = cart.payment_method.type == 'shopline_payment' && slFeatureService.hasFeature('sl_payment_standard_v2')
        stripe = {
          api: null,
          number: null,
          expiry: null,
          cvc: null
        }

        scope.consignee_id_type = 'id_no'; # could be 'id_no' or 'passport'
        scope.consignee_id_no = '';
        scope.deliveryFormErrors = {
          maxMailingAddressLength: null
        };

        scope.browserService = browserService;
        scope.staticImageHost = staticImageHost;
        scope.clickFBLogin = (appId, version) ->
          fbService.fbLogin(appId, version).then((res) ->
            if res.authResponse
              window.location.reload();
          );
        scope.shoplinePaymentCashierElement = null
        useStripeV3Payment = (() -> cart.payment_method.type is 'stripe' && cart.payment_method.config_data.stripe_payment is 'card' && cart.payment_method.config_data.user_id )
        # TODO: use positive logic after SL-15677 released
        useShoplinePaymentCreditCard = (() -> cart.payment_method.type is 'shopline_payment' && cart.payment_method.config_data.shopline_payment_payment isnt 'fpx' && !useShoplinePaymentV2)
        useTwoCheckoutPayment = (() -> cart.payment_method.type is 'two_checkout')
        usePaypalCheckoutjs = (() -> cart.payment_method.type is 'paypal_express')
        useOmiseCredieCard = (() -> cart.payment_method.type == 'omise_payment' && cart.payment_method.config_data && cart.payment_method.config_data.omise_payment_payment == 'credit')
        useOmisePayNow = (() -> cart.payment_method.type == 'omise_payment' && cart.payment_method.config_data && cart.payment_method.config_data.omise_payment_payment == 'paynow')
        isStripe3DSecureEnabled = (() -> cart.payment_method.config_data.stripe_3d_secure == 'true')
        if window.performance.navigation.type == 0 && /\/?page_id=/.test(document.location.search)
          # Tracking cartItems when page of checkout rendered if ExpressCheckoutPages is, and tracking page was accessed by navigation
          cartService.fetchItems(() ->
            cartService.getTrackItems().forEach(
              (item) -> cartService.tracking('UpdateItem', item, { eventCategory: 'ExpressCheckoutPages' })
            )
          )

        renderErrors = () ->
          $el = element.find('#checkout-errors')
          if _.keys(scope.errors).length > 0
            $el.html(_.values(scope.errors).join("<br/>"))
            $el.show()
            angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
            return
          else
            $el.hide()

        # Create ladda button for checkout
        scope.state = {
          isCheckoutLoading: false
        }
        btnPlaceOrder = Ladda.create(angular.element('.btn-place-order')[0])
        scope.btnPlaceOrder = btnPlaceOrder
        # Move custom fields on different screen size
        screenWidth = null
        resizeAction = _.throttle (() ->
          return if screenWidth == angular.element(window).width() # We only care the width change
          if angular.element(window).width() < 768
            element.find(".order-form").insertAfter element.find(".payment-form")
            element.find(".remark-form").insertAfter angular.element(element.find(".invoice-form")[0] || element.find(".order-form")[0] || element.find(".payment-form")[0])
          else
            element.find(".order-form").insertAfter element.find(".customer-form")
            element.find(".remark-form").insertAfter angular.element(element.find(".order-form")[0] || element.find(".customer-form")[0])
          screenWidth = angular.element(window).width()
        ), 500

        if useStripeV3Payment()
          loadScript('js.stripe.com/v3/').then ->
            merchantPublishableKey = cart.payment_method.config_data.publishable_key
            stripe.api = if merchantPublishableKey? then Stripe(merchantPublishableKey) else Stripe(stripePublishableKey, {stripeAccount: cart.payment_method.config_data.user_id})
            stripe.elements = stripe.api.elements()

            elementFocusHandler = ($el) ->
              $el.closest(".form-group").removeClass("has-error")

            card = {
              'number': { type: 'cardNumber', elementId: '#stripe-card-number', options: { placeholder: '' } },
              'expiry': { type: 'cardExpiry', elementId: '#stripe-card-expiry', options: { placeholder: 'MM/YY' } }
              'cvc': { type: 'cardCvc', elementId: '#stripe-card-cvc', options: { placeholder: '' } }
            }
            _.each card, (data, key) ->
              stripe[key] = stripe.elements.create(data.type, data.options)
              stripe[key].mount(data.elementId)
              stripe[key].on('focus', (() -> elementFocusHandler $(data.elementId)))

        angular.element(window).resize(resizeAction)
        resizeAction()

        element.on "input", "#order-customer-email", (event) ->
          return true unless isCheckoutableWithoutEmail

          customerEmail = checkoutService.getElementValue("order[customer_email]", element)
          memberSignup = checkoutService.getElement('saveFields[all]')
          emailRequired = element.find('.email-required')

          hasEmail = customerEmail.length > 0
          memberSignup.prop('checked', hasEmail)
          memberSignup.prop('disabled', !hasEmail)
          if hasEmail then emailRequired.hide() else emailRequired.show()

        element.on 'input', '#order-customer-phone', (event) ->
          element.find(".save-customer-phone").attr('style', 'display: block')

        # Auto insert slash for expiry date
        element.on "keyup", 'input[name="order[payment_data][expiry_date]"]', ->
          value = $(this).val()

          #mobile input do nothing because input focus to last
          if value.indexOf('/') != -1
            dateAray = value.split('/')
            if dateAray[0].length <= 2 && dateAray[1].length <= 2
              return true

          $(this).val(creditCardServices.creditCardDateFormat($(this).val()))

        element.on "keypress", '#payment-credit-card-number, #payment-credit-card-holder-expiry, #payment-credit-card-holder-cvc', (event) ->
          keyCode = event.keyCode || event.charCode
          return if _.contains([8, 37, 39, 46], keyCode) # Ignore delete, backspace, left and right keys
          !/[^\d]/.test(String.fromCharCode(keyCode))

        element.on "click", ".signup-checkbox", () ->
          return unless checkoutService.getElement('saveFields[all]').is(":disabled")

          customerEamil = element.find("#order-customer-email")
          angular.element('html, body').animate({ scrollTop: customerEamil.parent().offset().top - 100}, 'slow')
          $timeout () ->
            customerEamil.focus()

        scope.isTrialPlan = merchantService.isTrialPlan();

        getNavHeight = ->
          return $('.NavigationBar:visible').height()

        # Form validation
        scope.onPlaceOrderBtnClick = () ->
          scope.checkoutSubmitted = true;
          # enable address module validation
          setTimeout ->
            unless scope.validateForm()
              angular.element('html, body').animate({ scrollTop: element.find(".form-group.has-error,.form-group-address.has-error").first().offset().top - getNavHeight()}, 'slow')
              RecaptchaService.reset('place-order-recaptcha')
              return
            if familyMartFreezeService.isFreezeType(scope.formData.order.delivery_option.region_type)
              familyFreezeSpaceConfrim()
              return
            if scope.isTrialPlan && !slFeatureService.hasFeature('trial_limit_whitelist')
              ordersService.allowTrialPlanOrders(mainConfig.merchantId).then((res) ->
                isAllowCheckout = res.data.allow_place_order
                if !isAllowCheckout || (isAllowCheckout && res.data.orders_counts == 0)
                  # show trial plan popup
                  $rootScope.currentModal = checkoutService.openTrialOrderLimitPopup(isAllowCheckout)
                  $rootScope.currentModal.result.then(() ->
                    isAllowCheckout && placeOrder(scope.formData)
                  )
                else
                  placeOrder(scope.formData)
              )
              return
            placeOrder(scope.formData)

        # for Paypal
        scope.onValidateBtnClick = () ->
          scope.checkoutSubmitted = true;
          setTimeout -> # workaround for calling $digest in validateForm(), can refer to onPlaceOrderBtnClick()
            unless scope.validateForm()
              angular.element('html, body').animate({ scrollTop: element.find(".form-group.has-error,.form-group-address.has-error").first().offset().top - 100}, 'slow')
              RecaptchaService.reset('validate-order-recaptcha')
              return
            _.each document.querySelectorAll('.cart-summary + div, .cart-summary ~ .panel'), (el) ->
              el.style.display = 'none'
            document.querySelector('.place-order-btn-container').style.display = 'block'
            $('.collapse').collapse('show')

        # Paypal back to order details
        element.on "click", ".btn-order-details", () ->
          _.each document.querySelectorAll('.cart-summary + div, .cart-summary ~ .panel'), (el) ->
            el.style.display = 'block'
          document.querySelector('.place-order-btn-container').style.display = 'none'
          RecaptchaService.reset('validate-order-recaptcha')
          $('.collapse').collapse('hide')


        # Manually remove error on focus
        element.on "focus", '.form-control, input[type="checkbox"][required]', () ->
          angular.element(this).closest(".form-group").removeClass("has-error")
          angular.element(this).closest(".form-group").find('.error-block').remove()
        element.on "destroy", (() -> element.off()) # Unbind events

        # Listen to angular digest cycle
        didAfterDigestRegistered = false
        scope.$watch () ->
          return if didAfterDigestRegistered
          didAfterDigestRegistered = true
          scope.$$postDigest () ->
            didAfterDigestRegistered = false
            afterDigest()

        angular.element('[data-toggle="popover"]').popover()

        # Listen to apple pay availability error
        scope.$on 'checkout.payments.apple_pay.ready', (() -> btnPlaceOrder = Ladda.create(angular.element('#apple-pay-button')[0]))
        scope.$on 'checkout.payments.apple_pay.not_supported', () ->
          scope.errors['apple_pay'] = $filter('translate')('checkout.payments.apple_pay.not_supported')
          renderErrors()
        scope.$on 'checkout.payments.google_pay.ready', (() -> btnPlaceOrder = { start: angular.noop, stop: angular.noop })
        scope.$on 'checkout.payments.google_pay.not_supported', () ->
          scope.errors['google_pay'] = $filter('translate')('checkout.payments.google_pay.not_supported')
          renderErrors()
        scope.$on 'checkout.payments.paypal_express.ready', (() -> btnPlaceOrder = Ladda.create(angular.element('#paypal-checkout-button')[0]))
        scope.$on 'checkout.payments.paypal_cn.ready', (() -> btnPlaceOrder = Ladda.create(angular.element('#paypal-cn-checkout-button')[0]))
        scope.$on 'checkout.delivery.yto_store.loaded.error', () ->
          scope.errors['delivery_errors'] = $filter('translate')('orders.fields.delivery_data.sl_logistics_yto_store.city_list.error')
          renderErrors()
        if slFeatureService.hasFeature('payment_condition')
          scope.$on 'checkout.payment.condition.updated', (event, data) ->
            $timeout () ->
              scope.disabledByPaymentCondition = data.difference > 0
        if featureService.hasFeature('taxes_settings')
          scope.calculateTaxFee = () ->
            selectedAddress = $('form[name="deliveryForm"]').find('input[type="radio"]:checked')
            if selectedAddress.length > 0 && (selectedAddress.val() != 'new')
              addressData = JSON.parse(selectedAddress.siblings('input[name="order[delivery_address]"]').val())
              params = {
                is_checkout_tax_update: true,
                cart: {
                  region_code: addressData.region_code,
                  postcode: addressData.postcode
                }
              }
            else
              params = {
                is_checkout_tax_update: true,
                cart: {
                  region_code: $('#order-delivery-region-code').val() || null,
                  postcode: $('#order-delivery-postcode').val() || null
                }
              }
            scope.taxFeeStatus = 'updating'
            $rootScope.$broadcast 'tax.fee.update', params

          scope.$on 'tax.fee.address.changed', () ->
            $timeout () ->
              scope.taxFeeStatus = 'shouldUpdate'

          scope.$on 'tax.fee.isUpdated', (event, taxData) ->
            $timeout () ->
              scope.taxFeeStatus = 'updated'
              element.find('#summary-header-total-price').text(taxData.total.label)

        if(featureService.hasRolloutFeature('country_code'))
          countryData = if $rootScope.currentUser
            $.fn.intlTelInput.getCountryData().find((country) ->
              country.dialCode == $rootScope.currentUser.country_calling_code
            )
          if $rootScope.currentUser
            $rootScope.currentUser.delivery_addresses.forEach (address, i) ->
              target = $('.delivery-country-code-sign[index="' + i + '"]')
              if (intlTelInputUtils.isValidNumber('+' + address.recipient_phone))
                target.show()
              else
                target.hide()

          $('.order-customer-phone-input, #recipient-phone, #order-customer-phone')
            .intlTelInput { preferredCountries: [mainConfig.requestCountry.toLowerCase()] }

          setTimeout ->
            if $rootScope.currentUser &&
            $rootScope.currentUser.phones.length > 0 &&
            scope.phonesCountryCodes[checkoutService.getElement('order[customer_phone]').val()]
              $('.contry-content')
                .intlTelInput('setCountry', scope.phonesCountryCodes[checkoutService.getElement('order[customer_phone]').val()].iso2)
            else if flash.data
              $('.order-customer-phone-input').intlTelInput('setCountry', flash.data.customer_country)
              $(' #recipient-phone').intlTelInput('setCountry', flash.data.recipient_country)
            else if countryData
              $('#order-customer-phone').intlTelInput('setCountry', countryData.iso2)

        if (flash && flash.data && flash.data.customer_country)
          $('.order-customer-phone-with-countrycode').intlTelInput('setCountry', flash.data.customer_country)

        scope.addMobilePhonePrefix = () ->
          mobilePhoneInput = $('.intl-tel-input')
          nationalMobilePhoneNumber = mobilePhoneInput.intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL).replace(/\D/g, '')
          scope.orderCustomerPhone = nationalMobilePhoneNumber

        vaildatePhone = (element) ->
          if featureService.hasRolloutFeature('country_code')
            value = element[0].value
            internationalNumber =
              if element.is('input')
              then element.intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL).replace(/\D/g, '')
              else value

            country = if element.is('input')
            then element.intlTelInput('getSelectedCountryData').iso2
            else $('.contry-content').intlTelInput('getSelectedCountryData').iso2

            return intlTelInputUtils.isValidNumber(value, country) && internationalNumber == value.replace(/\D/g, '')
          else
            return true

        validateCustomerPhone = (element) ->
          return true unless isCheckoutableWithoutEmail

          internationalNumber = element.intlTelInput('getNumber', intlTelInputUtils.numberFormat.NATIONAL).replace(/\D/g,'')
          nationalNumber = element.intlTelInput('getNumber', intlTelInputUtils.numberFormat).replace(/\D/g,'')
          value = element.val()
          country = element.intlTelInput('getSelectedCountryData').iso2
          if(country == 'hk')
            return /^[4-9]{1}\d{7}$/.test(value) && (value == internationalNumber || value == nationalNumber)
          return intlTelInputUtils.isValidNumber(value, country) && (value == internationalNumber || value == nationalNumber);

        validateMailingAddress = () ->
          shouldValidate = ['2', '3'].includes(element.find('[name="order[invoice][invoice_type]"]').val()) &&
            element.find('[name="invoiceAddressType"]:checked').val() == 'same-as-order';
          mailingFields = ['postcode', 'state', 'city', 'address_2', 'address_1'];
          mailingAddress = scope.currentCountryName + mailingFields.reduce((accu, curr) ->
            return accu + (element.find('[name="order[delivery_address][' + curr + ']"]').val() || '')
          , 0);
          scope.deliveryFormErrors.maxMailingAddressLength = shouldValidate && mailingAddress && mailingAddress.length > 100;
          scope.$digest();

        scope.validateForm = -> # do not early return true cause form data will be setted by setFieldValue at the end of this function
          scope.errors = {}
          element.find('#checkout-errors').hide()
          errorFormControls = element.find('.form-group.has-error')
          errorFormControls.find('.error-block').remove()
          errorFormControls.removeClass('has-error')
          resetFormData()

          # Show error for stripe form elements
          $('.StripeElement--empty,.StripeElement--invalid').each(() ->
            $(this).closest('.form-group').addClass('has-error')
          )

          if slFeatureService.hasFeature('payment_condition') && scope.disabledByPaymentCondition
            return false

          # valid 7-11 cross border support country
          if !slFeatureService.hasFeature('sl_logistics_modularize') && !slFeatureService.hasFeature('dynamic_shipping_rate_refactor')
            return false unless validateSevenCrossBorderLocation()

          if $('.checkout-payment-form #cardholdersname') && $('.checkout-payment-form #cardholdersname').val() == ''
            $rootScope.$broadcast 'cardholdername.invalid'
            return false

          if cart.use_shopline_payment_cashier == 'true' && cart.payment_method.config_data.shopline_payment_payment != 'alipay' && !scope.shoplinePaymentCashierElement.getSupportSubmit()
            element.find('#checkout-shopline-payment-cashier-form').addClass('has-error') # to support scrolling to target position
            scope.shoplinePaymentCashierElement.formError()
            return false
          else if useOmiseCredieCard() && !scope.paymentElement.getSupportSubmit()
            # Omise is using cashier SDK but not intergrated with sl payment so the logic is similiar to the case above
            element.find('#checkout-omise-credit-card-form').addClass('has-error') # to support scrolling to target position
            scope.paymentElement.formError()
            return false

          if mainConfig.merchantData.invoice_activation == 'active'
            validateMailingAddress()

          checkoutService.getFormFields(element.find("form"), scope)
            .each ($field) ->
              if !_.isEmpty($field.val())
                $field.val($field.val().trim())
              if $field.attr("name") == "order[delivery_address]" && !$field.is(":checked")
                validateDeliveryLocation($field)
              if checkoutService.validateFormField($field) ||
              (($field.hasClass('order-customer-phone-input') ||
              $field.attr('id') == 'order-customer-phone' ||
              $field.attr('id') == 'recipient-phone') &&
              !vaildatePhone($field)) ||
              ($field.attr('id') == 'order-customer-phone' && !validateCustomerPhone($field))
                $field.closest(".form-group").addClass("has-error")
                $field.closest(".form-group").find('.error-block').remove()
                if (!checkoutService.validateFormField($field))
                  fieldName = $filter('translate')($field.attr('name').match(/([a-zA-Z0-9\_\-]+)/g).join('.').toUnderscore())
                  $field.data('error-message', $filter('translate')('form.validation.pattern', { field_name: fieldName }))
                # manually set error msg
                $field.closest(".form-group").append("<div class='error-block help-block'>" + $field.data('error-message') + "</div>") if $field.data('error-message') && !$field.attr('hide-error')
                return false
              setFieldValue($field)
          element.find(".form-group.has-error").length <= 0 && _.keys(scope.errors).length == 0 && element.find(".form-group-address.has-error").length <= 0;

        validateSevenCrossBorderLocation = () ->
          return true unless ['cross_border_711_store_pick_up', 'cross_border_711_home_delivery'].includes(scope.formData.order.delivery_option.region_type)
          specificRegions = scope.formData.order.delivery_option.config_data.specific_regions
          storeAddress = element.find('#seven-eleven-cross-border-address').find('span,input').toArray()
            .filter((field) ->
              field.attributes.name && !_.isEmpty(field.attributes.name.value)
            )
          formObject = {}
          _.each(storeAddress, (field) ->
            value = if field.tagName == 'SPAN' then field.outerText else field.value
            formObject[field.attributes.name.value] = value
          )
          postalCode = parseInt(formObject["order[delivery_data][postal_code]"])
          overDuePostCode = getOverDuePostCode()
          errorMessage =
            if isNaN(postalCode) && isNaN(overDuePostCode)
              null
            else
              if !_.isEmpty(formObject["order[delivery_data][destination]"]) && formObject["order[delivery_data][destination]"] != cart.country
                $filter('translate')('form.validation.delivery_option.country')
              else if cart.country == 'MY'
                detachCode = CROSS_BORDER_CODE['SEVEN_ELEVEN']['MY'] # code is 87000
                # if area is only contain west MY, postalCode need to less than detachCode
                # if area is only contain east MY, postalCode need to more than or equal to detachCode
                if !_.contains(specificRegions, 'MY_west') && (postalCode < detachCode || overDuePostCode < detachCode)
                  $filter('translate')('form.validation.delivery_option.cross_border.MY', area: 'west')
                else if !_.contains(specificRegions, 'MY_east') && (postalCode >= detachCode || overDuePostCode >= detachCode)
                  $filter('translate')('form.validation.delivery_option.cross_border.MY', area: 'east')
          scope.errors['delivery_errors'] = errorMessage if errorMessage
          renderErrors()

        validateDeliveryLocation = ($field) ->
          deliveryOption = scope.formData.order.delivery_option
          supportedCountries = deliveryOption.supported_countries
          regionType = deliveryOption.region_type
          country = JSON.parse($field.attr("value"))['country']
          postcode = JSON.parse($field.attr("value"))['postcode']
          deliveryTargetArea = deliveryOption.config_data && deliveryOption.config_data.delivery_target_area
          errorMessage =
            # 全球
            if supportedCountries.indexOf('*') >= 0
              if selectedCountry != country
                $filter('translate')('form.validation.delivery_option.country')
              else if country == 'TW'
                if slLogisticsYtoService.homeDeliveryKeys.includes(regionType) || (deliveryOption.config_data && deliveryOption.config_data.company_code == 'YTO' && deliveryOption.config_data.supported_address_type && deliveryOption.config_data.supported_address_type.includes('HOME_STREET'))
                  validateMessage(deliveryTargetArea, LOCALE_CODE['NORMAL_OUTLYING'], postcode)
                else
                  validateRegion(deliveryTargetArea, regionType, postcode)
            else
              if supportedCountries.indexOf(country) < 0
                $filter('translate')('form.validation.delivery_option.country')
              else if country == 'TW'
                if slLogisticsYtoService.homeDeliveryKeys.includes(regionType) || (deliveryOption.config_data && deliveryOption.config_data.company_code == 'YTO' && deliveryOption.config_data.supported_address_type && deliveryOption.config_data.supported_address_type.includes('HOME_STREET'))
                  validateMessage(deliveryTargetArea, LOCALE_CODE['NORMAL_OUTLYING'], postcode)
                else
                  validateRegion(deliveryTargetArea, regionType, postcode)
          scope.errors['delivery_errors'] = errorMessage if errorMessage
          renderErrors()

        getOverDuePostCode = () =>
          overDuePostCode = if !!element.find('#order-delivery-postcode')[0] then parseInt(element.find('#order-delivery-postcode')[0].value) else NaN
          if isNaN(overDuePostCode) && !isNaN(parseInt($('input[name="form[address]"]:checked').val()))
            countryAddresses = $rootScope.currentUser.delivery_addresses.filter((address) ->
              address.country == cart.country
            )
            selectedCode = countryAddresses[$('input[name="form[address]"]:checked').val()].postcode
            overDuePostCode = parseInt(selectedCode)
          overDuePostCode

        validateRegion = (deliveryTargetArea, regionType, postcode) ->
          if regionType == 'custom' || regionType == 'international'
            validateMessage(deliveryTargetArea, LOCALE_CODE['NORMAL_OUTLYING'], postcode)
          else if LOCALE_CODE['TCAT_DELIVERY'].indexOf(regionType) >= 0
            if LOCALE_CODE['TCAT_NOT_SEND'].indexOf(postcode) >= 0
              $filter('translate')('form.validation.delivery_option.region')
            else
              validateMessage(deliveryTargetArea, LOCALE_CODE['TCAT_OUTLYING'], postcode)
          else if regionType.match(/^sl_logistics_hct/)
            if LOCALE_CODE['HCT_NOT_SEND'].indexOf(postcode) >= 0
              $filter('translate')('form.validation.delivery_option.region')
            else
              validateMessage(deliveryTargetArea, LOCALE_CODE['HCT_OUTLYING'], postcode)


        validateMessage = (deliveryTargetArea, constant, postcode) ->
          if (postcode != undefined && postcode != '')
            if constant.indexOf(postcode) >= 0
              if deliveryTargetArea == 'localOnly'
                $filter('translate')('form.validation.delivery_option.outlying')
            else
              if deliveryTargetArea == 'outlyingIslandOnly'
                $filter('translate')('form.validation.delivery_option.local')

        familyFreezeSpaceConfrim = () ->
          if !scope.formData.order.delivery_data || !scope.formData.order.order_delivery || !scope.formData.order.order_delivery.ref_data
            scope.errors.checkout = $filter('translate')('orders.fields.delivery_data.family_mart_freeze.error.locale_code')
            return renderErrors()
          payload = {
            station_space: {
              region_type: scope.formData.order.delivery_option.region_type,
              option: {
                merchant_id: mainConfig.merchantId,
                reserved_nos: JSON.parse(scope.formData.order.order_delivery.ref_data.reserved_nos),
                check_status: 1,
                channel_code: 'fmt'
              }
            }
          }
          familyMartFreezeService.confirmStationSpace(payload)
            .then((response) ->
              placeOrder(scope.formData)
            )
            .catch((error) ->
              scope.errors.checkout = $filter('translate')('checkout.station_space_confirm_error')
              RecaptchaService.reset('validate-order-recaptcha')
              renderErrors()
            )

        scope.getFormData = () ->
          resetFormData()
          checkoutService.getFormFields(element.find("form"), scope).each((field) -> setFieldValue(angular.element(field)))
          scope.formData

        scope.onStripePaymentWillStart = () ->
          # note: be careful for validateForm() here, it uses $digest()
          # TODO: wrap validateForm() with setTimeout
          isValid = scope.validateForm()

          if isValid
            btnPlaceOrder.start()
            scope.state.isCheckoutLoading = true

          if _.fetchpath(scope.formData, 'order.payment_data.token')
            processCheckout()
            return false

          isValid

        scope.onStripeChargeDidEnd = (error) ->
          triggerCheckoutTracking()
          if error
            btnPlaceOrder.stop()
            scope.state.isCheckoutLoading = false
            if scope.payment.config_data.stripe_payment == 'google_pay'
              scope.errors['payment_failed'] = $filter('translate')('checkout.payment_failed')
            else # apple_pay
              scope.errors['apple_pay'] = error
            renderErrors()

        scope.onStripeChargeCancel = () ->
          btnPlaceOrder.stop()
          scope.state.isCheckoutLoading = false

        scope.onStripeChargeCreated = (charge) ->
          _.deepExtend scope.formData.order, {
            payment_data: {
              token: charge.id
            }
          }
          processCheckout(false)

        # for window.open in async function on ios (pv_payment)
        windowOpenReference = () -> null

        scope.onPaypalCharge = () ->
          deferred = $q.defer()
          btnPlaceOrder.start()
          processCheckout()
            .then ((order) ->
              deferred.resolve order
            ), (error) ->
              deferred.reject scope.errors.checkout
          deferred.promise

        scope.onPaypalRenderErrors = () ->
          renderErrors()

        resetFormData = () ->
          scope.formData =
            order:
              delivery_option: cart.delivery_option
              payment_method: cart.payment_method
              seller_id: mainConfig.merchantId
              delivery_data: (cart.delivery_data || {})
              custom_fields_translations: []
              invoice: {}
              # "invoice": {
              #   "invoice_type": "2",
              #   "carrier_type": 0,
              #   "n_p_o_b_a_n": "101",
              #   "buyer_name": "Philip",
              #   "tax_id": "23123123",
              #   "mailing_address": "Rm 3312 lol"
              # },
              delivery_address: {}
            saveFields:
              phone: false
              delivery_address: false
              all: false
              marketing: false
              customer_info: {}

        setFieldValue = ($field) ->
          $field.closest(".form-group").removeClass("has-error")
          value = checkoutService.getFormFieldValue($field)
          pointer = scope.formData
          keys = $field.attr("name").match(/([a-zA-Z0-9\_\-]+)/g)
          for key, i in keys
            if i == (keys.length - 1)
              pointer[key] = value
            else
              pointer[key] = {} if pointer[key] is undefined
              pointer = pointer[key]

        if Analytics.configuration.enhancedEcommerce
          gaService.sendPageView()

        triggerCheckoutTracking = () ->
          # Track GA before api request to give more time to GA to trigger
          if Analytics.configuration.enhancedEcommerce && !cartService.isAllRedeemGift()
            gaService.setUserId()
            Analytics.trackCheckout(3,"Filled Form")
            Analytics.trackEvent('UX', 'place_order', 'Place Order', undefined, true)

          # Check the confirmed order is Checkout or ExpressCheckoutPages
          event_name = if /\/?page_id=/.test(document.location.search) then 'ECP_Checkout' else 'Checkout'
          # shoplytics tracking
          trackerService.userAction(event_name, 'Order')
          # hiido tracking checkout
          hiidoTrackerService.checkout.placeOrder()

        placeOrder = () ->
          if scope.state.isCheckoutLoading # prevent multiple checkout due to recaptcha callback
            return
          btnPlaceOrder.start()
          scope.state.isCheckoutLoading = true
          triggerCheckoutTracking()

          if cart.total.cents == 0
            # Free checkout, process right away
            processCheckout()
          else if cart.payment_method.type is 'braintree'
            braintreeService.getClientToken(cart.payment_method._id)
              .then (token) ->
                braintreeService.processCard(token, scope.formData.order.payment_data)
              .then (nonce) ->
                _.deepExtend scope.formData.order, {
                  payment_data: {
                    nonce: nonce
                  }
                }
                processCheckout()
              .catch ->
                scope.state.isCheckoutLoading = false
                btnPlaceOrder.stop()
                scope.errors.checkout = "Error With Payment Gateway"
                renderErrors()
          else if _.contains(['taishin', 'sinopac', 'new_sinopac', 'tappay_ctbc', 'tappay_nccc'], cart.payment_method.type) or (cart.payment_method.type is 'esun' && cart.esun_tappay_active)
            tappayService.getPrime()
              .then (result) ->
                _.deepExtend scope.formData.order, {
                  payment_data: {
                    tappay_result: result
                  }
                }

                if result.card && result.card.prime
                  _.deepExtend scope.formData.order, {
                    payment_validation_data: {
                      card: {
                        prime: result.card.prime
                      }
                    }
                  }

                processCheckout()
              .catch (err) ->
                btnPlaceOrder.stop()
                scope.state.isCheckoutLoading = false # sync state and loading style
                if err
                  scope.errors.checkout = $filter('translate')('checkout.card_info_error')
                  renderErrors()
          else if useStripeV3Payment()
            if slFeatureService.hasFeature('stripe_payment_intents')
              createStripePaymentIntentCheckout()
            else if isStripe3DSecureEnabled()
              createStripeSourceCheckout()
            else
              createStripeTokenCheckout()
          else if cart.use_shopline_payment_cashier == 'true'
            if scope.shoplinePaymentCashierElement.getSystemInfo
              cashierSystemInfo = scope.shoplinePaymentCashierElement.getSystemInfo()
              if cashierSystemInfo && cashierSystemInfo.systemVersion
                scope.formData.order.cashierVersion = cashierSystemInfo.systemVersion
            # check here to add checkout process
            processCheckout()
          else if useShoplinePaymentCreditCard()
          # if there's no error raised, it would go to onCardTokenized to proceed checkout
            Frames.submitCard().catch (err) ->
              btnPlaceOrder.stop()
              scope.state.isCheckoutLoading = false # sync state and loading style
              if err
                scope.errors.checkout =  $filter('translate')('checkout.card_info_error')
                renderErrors()
          else if useTwoCheckoutPayment()
            scope.universalPay.getToken()
              .then (result) ->
                scope.cardToken = result.token
                if !scope.cardToken
                  btnPlaceOrder.stop()
                  scope.state.isCheckoutLoading = false # sync state and loading style
                  scope.errors.checkout =  $filter('translate')('checkout.card_info_error')
                  renderErrors()
                else
                  processCheckout()
          else if useOmiseCredieCard() 
            scope.paymentElement.getToken()
              .then (result) ->
                scope.cardToken = result.token
                if !scope.cardToken
                  btnPlaceOrder.stop()
                  scope.state.isCheckoutLoading = false # sync state and loading style
                  scope.errors.checkout = $filter('translate')('checkout.card_info_error')
                  renderErrors()
                else
                  processCheckout()
          else if useOmisePayNow()
            if Object.values(scope.omisePayNow).some((val) -> !val)
              scope.state.isCheckoutLoading = false
              btnPlaceOrder.stop()
              scope.errors.checkout = "Error With Payment Gateway"
              renderErrors()
            else
              processCheckout()
          else if cart.payment_method.type is 'pv_payment'
            windowOpenReference = window.open()
            processCheckout()
          else if useShoplinePaymentV2
            scope.shoplinePaymentV2Content.validate().then (valid) ->
              if valid
                scope.shoplinePaymentV2Content.getPaySession().then (data) ->
                  scope.shoplinePaymentV2Session = data
                  if data.paymentError
                    logger.error(data.paymentError.msg || data.paymentError, data.paymentError)
                  processCheckout()
              else
                scope.errors.payment_failed = $filter('translate')('checkout.payment_failed')
                renderErrors()
                scope.state.isCheckoutLoading = false
                btnPlaceOrder.stop()
          else
            processCheckout()

        prepareCheckoutCommonData = ->
          # Hacky handling to fit our checkout API
          _.extend (scope.formData.order.delivery_address || {}), _.pick((scope.formData.order.delivery_data || {}), 'recipient_name', 'recipient_phone'), { country: cart.country }
          _.extend scope.formData.order, { coupons: _.map(cart.coupon_codes, ((code) -> { coupon_item: { coupon_code: code } })) }
          _.each scope.formData.saveFields.customer_info, (value, key) -> (scope.formData.saveFields.customer_info[key] = { value: value } if isjs.not.json(value))
          if !scope.formData.order.delivery_option.requires_customer_address
            # custome delivery should send country
            scope.formData.order.delivery_address.country = cart.country
          scope.formData.order.payment_method = null if cart.payment_method.type == 'free_checkout'

          if featureService.hasRolloutFeature('country_code')
            scope.parsedPhones.forEach (parsedPhone, i) ->
              if parsedPhone == scope.formData.order.customer_phone
                telInputEle = telService.buildFakeTelInput($rootScope.currentUser.phones[i])
                telInputEle.intlTelInput('setCountry', $('.contry-content').intlTelInput('getSelectedCountryData').iso2)
                if !scope.phonesCountryCodes[parsedPhone]
                  telInputEle.intlTelInput('setNumber', scope.formData.order.customer_phone)

                $rootScope.currentUser.phones[i] = telInputEle.intlTelInput('getNumber').replace('+', '')

            scope.formData.order.customer_phone =
              if $rootScope.currentUser && $rootScope.currentUser.mobile_phone_confirmed_at
                "+#{$rootScope.currentUser.country_calling_code}#{scope.formData.order.customer_phone.substr(1)}"
              else
                $('#order-customer-phone').intlTelInput('getNumber')

            if ($('input[name="form[address]"]:checked').val() == 'new' || !$('input[name="form[address]"]:checked').val())
              recipientPhone = $('#recipient-phone').intlTelInput('getNumber').replace('+', '')
              scope.formData.order.delivery_data.recipient_phone_country_code = $('#recipient-phone').intlTelInput('getSelectedCountryData').dialCode
              scope.formData.order.delivery_address.recipient_phone = recipientPhone
              scope.formData.order.delivery_data.recipient_phone = recipientPhone
            else
              if (intlTelInputUtils.isValidNumber('+' + scope.formData.order.delivery_address.recipient_phone))
                telInput = telService.buildFakeTelInput(scope.formData.order.delivery_address.recipient_phone)
                scope.formData.order.delivery_data.recipient_phone_country_code = telInput.intlTelInput('getSelectedCountryData').dialCode

          scope.formData.order.user_phones = if $rootScope.currentUser then $rootScope.currentUser.phones else []

          if scope.formData.invoiceAddressType == 'same-as-order'
            addr = scope.formData.order.delivery_address
            scope.formData.order.invoice.mailing_address = _.findWhere(scope.countries, { code: addr.country }).name +
              (addr.postcode || '') +
              (addr.state || '') +
              (addr.city || '') +
              (addr.address_2 || '') +
              (addr.address_1 || '')
          else if scope.formData.invoiceAddressType == 'new' || !scope.formData.invoiceAddressType
            scope.formData.order.invoice.mailing_address = $('#built-invoice-mailing-address').val()

          if isCheckoutableWithoutEmail
            scope.formData.order.checkoutable_without_email = true
            scope.formData.order.country_calling_code = $('#order-customer-phone').intlTelInput('getSelectedCountryData').dialCode
            scope.formData.order.customer_phone_country_code = $('#order-customer-phone').intlTelInput('getSelectedCountryData').dialCode

          if useShoplinePaymentCreditCard()
            scope.formData.order.risk_response = riskResponse
            cardHolderName = $("#cardholdersname").val()
            _.deepExtend scope.formData.order, {
              payment_data: {
                card_data: scope.cardData,
                cardHolderName: cardHolderName
              }
            }

          if useTwoCheckoutPayment() || useOmiseCredieCard()
            _.deepExtend scope.formData.order, { payment_data: { token: scope.cardToken } }

          if (scope.formData.order.delivery_option.fee_type == 'sl_logistic')
            scope.formData.order.delivery_address.logistic_code = $("#dynamic-deliver-fee-logistic-code").val()

          if slFeatureService.hasFeature('modularize_address_format')
            # get the selected address-module
            addressInputSelector = if $("input[name='form[address]'][value!='new']:checked").length
            then "input[name='form[address]'][value!='new']:checked ~ [address-module]"
            else "#delivery-form-content"
            scope.formData.order.delivery_address.logistic_codes = _.map($(addressInputSelector + " .logistic-codes-input"), (element, index) ->
              return $(addressInputSelector + " input[name='order[delivery_address][logistic_codes][" + index + "]']").val();
            );
            scope.formData.order.delivery_address.address_node_ids = _.map($(addressInputSelector + " .address-node-ids-input"), (element, index) ->
              return $(addressInputSelector + " input[name='order[delivery_address][address_node_ids][" + index + "]']").val();
            );
          if (scope.formData.order.delivery_option.config_data && scope.formData.order.delivery_option.config_data.company_code == 'HKZEEK')
            _.extend scope.formData.order.delivery_address, _.pick(scope.formData.order.delivery_data, 'city', 'province', 'address_1')
          if scope.shoplinePaymentV2Session
            scope.formData.order.pay_session = scope.shoplinePaymentV2Session


        scope.processCheckoutPaypalCn = ->
          prepareCheckoutCommonData()

          orderParams = angular.extend({}, scope.formData.order);
          if orderParams.delivery_option.requires_customer_address == false
            deliveryAddress = angular.extend(orderParams.delivery_address, { country: orderParams.delivery_address.country });
            orderParams.delivery_address = deliveryAddress;
          $http({
            method: 'POST',
            url: '/api/orders/checkout',
            data: _.extend({
              order: orderParams,
              saveFields: scope.formData.saveFields,
              benchatFields: scope.formData.benchatFields,
              page_id: cart.pageId,
              recaptchable: true
            }),
          })

        scope.isCreatingNewAddress = ->
          return $('input[name="form[address]"]:checked').val() == 'new'

        processCheckout = (recaptchable, extraToken) ->
          deferred = $q.defer()
          prepareCheckoutCommonData()
          if (extraToken)
            _.deepExtend scope.formData.order, { payment_data: { token: extraToken } }

          cartService.requestCheckout scope.formData.order, scope.formData.saveFields, scope.formData.benchatFields, recaptchable, (order, message, data) ->
            scope.errors = {}
            if !order
              if message
                # console.log message
                scope.errors.checkout = message

              if scope.formData.order.payment_method && scope.formData.order.payment_method.type == 'linepay' && message
                scope.errors.checkout = $filter('translate')('checkout.payment_general_error')

              if data && data.ezship
                scope.errors.checkout = ezshipService.getEzshipErrorMessage(data.ezship.order_status)

              if shouldBlockTimeoutCheckout(data)
                # Do not re-enable checkout button, add cusom error message
                scope.errors.checkout = $filter('translate')('checkout.timeout')
              else
                # Re-enable checkout button
                scope.state.isCheckoutLoading = false
                if btnPlaceOrder
                  btnPlaceOrder.stop()
                if scope.applePayLadda
                  scope.applePayLadda.stop()

              deferred.reject()
              renderErrors()
            else
              order.order_payment.object_data.payment_fee_dollars = +cart.payment_fee_dollars || 0;
              slPixelService.hdTracking('signUp');

              if useShoplinePaymentV2
                scope.shoplinePaymentV2Content.pay(order.next_action).then (resp) ->
                  if resp && resp.paymentError
                    logger.error(resp.paymentError.msg || resp.paymentError, resp.paymentError)
                    scope.errors.payment_failed = $filter('translate')('checkout.payment_failed')
                    renderErrors()
                    scope.state.isCheckoutLoading = false
                    btnPlaceOrder.stop()
                  else
                    trackCheckout(data, order)
              else if data.stripe_client_secret
                stripe.api.confirmCardPayment(data.stripe_client_secret).finally ->
                  trackCheckout(data, order)
              else
                trackCheckout(data, order)
              RecaptchaService.reset('place-order-recaptcha')
              deferred.resolve order
          deferred.promise

        trackCheckout = (data, order) ->
          if slFeatureService.getFeatureLimit('shopcom_push_api')
            return if usePaypalCheckoutjs()
            performPaymentRedirection data, order
          else
            merchantAffiliateService.trackCheckout(order).finally ->
              return if usePaypalCheckoutjs()
              performPaymentRedirection data, order
        # After a checkout attempt has failed,
        # determine whether the checkout button should be re-enabled for click
        shouldBlockTimeoutCheckout = (errorPayload) ->
          nonRedirectGateways = ['stripe', 'custom']

          # for timeout cases on specific gateways (that does not require redirection)
          # keep ladda state to avoid re-click, preventing duplicated orders
          return errorPayload?.message == 'timeout' &&
                 _.contains(nonRedirectGateways, cart.payment_method.type)

        performPaymentRedirection = (data, order) ->
          paymentType = cart.payment_method.type;
          paymentType = "free_checkout" if order.total.cents == 0
          if (['alipay_hk', 'alipay_cn'].indexOf(paymentType) >= 0)
            paymentType = 'alipay'

          switch paymentType
            when "paypal", "allpay", "ecpay", "asiapay", "esun", 'alipay', 'neweb_pay', 'molpay', 'oceanpay', 'neweb_pay_mpg', 'neweb_pay_v2', 'shopline_payment'
              # This is a hackaround for user membership credit
              # https://developer.paypal.com/docs/classic/paypal-payments-standard/integration-guide/Appx_websitestandard_htmlvariables/
              # Because discount_amount_cart is the deduct total amount part
              # They don't have 2nd option for us to deduct the total
              if paymentType is "paypal"
                order.paypal_discount_amount_cart = order.order_discount.total.dollars
                order.paypal_discount_amount_cart += order.user_credits.value if order.user_credits?

              # Assigning values to angular and wait for the HTML digest cycle
              # Trigger afterDigest AFTER assigning redirectWithPayment and order
              # It will work to redirect to payment page.
              if paymentType is "esun" && cart.esun_tappay_active
                window.location.href = data.redirect_to
              else if paymentType is "shopline_payment" && useShoplinePaymentV2
                return
              # TODO: use positive logic after SL-15677 released
              else if paymentType is "shopline_payment" && cart.payment_method.config_data.shopline_payment_payment isnt 'fpx'
                if cart.use_shopline_payment_cashier == 'true'
                  if !scope.shoplinePaymentCashierElement
                    throw "Can't find shoplinePaymentCashierElement"
                  scope.shoplinePaymentCashierOrderData = data
                  scope.btnPlaceOrder = btnPlaceOrder
                  scope.renderErrors = renderErrors
                  scope.shoplinePaymentCashierElement.orderAction(order.cashier_submit_params)
                else
                  window.location.href = data.redirect_to
              else if paymentType is "neweb_pay" && cart.payment_method.config_data && cart.payment_method.config_data.neweb_pay_payment is 'apple_pay'
                window.location.href = data.redirect_to
              else
                scope.order = order
                scope.redirectWithPayment = paymentType
            when "asiabill_payment"
              orderPaymentField = order.payment_field_data;
              fieldData = {
                merNo: orderPaymentField.merNo,
                gatewayNo: orderPaymentField.gatewayNo,
                orderNo: orderPaymentField.orderNo,
                orderCurrency: order.currency_iso,
                orderAmount: order.total.dollars.toFixed(2),
                signInfo: orderPaymentField.signInfo,
                returnUrl: orderPaymentField.returnUrl,
                firstName: order.customer_name,
                lastName: order.customer_name,
                email: order.customer_email,
                phone: order.customer_phone,
                paymentMethod: 'Credit Card',
                country: order.delivery_address.country_code,
                callbackUrl: orderPaymentField.callbackUrl,
                goods_detail: order.goods_detail,
                interfaceinfo: 'Shopline'
              }
              scope.processAsiabillPay(fieldData)
            when 'pv_payment'
              windowOpenReference.location = "/pv_payment/payment_requests/" + order._id + "/redirect_pv_payment"
              location.href = "/pv_payment/payment_requests/" + order._id
            when 'omise_payment'
              if useOmisePayNow()
                scope.omisePayNow.pay(order.pay_data, scope.omisePayNow.deviceType, scope.omisePayNow.env);
              else
                window.location.href = data.redirect_to
            else
              window.location.href = data.redirect_to

        afterDigest = () ->
          if scope.redirectWithPayment? && scope.order? && !scope.submitted
            form_key = sprintf('#%s_form', scope.redirectWithPayment)
            if form_key == '#neweb_pay_form'
              $(form_key).attr('action', scope.order.redirect_to)
            $(form_key).submit()
            scope.submitted = true

        scope.resetFormData = resetFormData
        scope.getFormFields = checkoutService.getFormFields(element.find("form"), scope)
        scope.setFieldValue = setFieldValue
        scope.handleShoplinePaymentCheckout = processCheckout
        scope.handleApplePayWithNewebpay = (applePayToken) ->
          processCheckout(false, applePayToken)
        scope.renderErrors = renderErrors
        renderErrors()

        createStripeTokenCheckout = () ->
          stripe.api.createToken(stripe.number, { name: scope.formData.order.payment_data.holdername })
            .then (result) ->
              if result.error
                btnPlaceOrder.stop()
                scope.state.isCheckoutLoading = false # sync state and loading style
                scope.errors.checkout = result.error.message
                renderErrors()
              else
                scope.formData.order.payment_data.token = result.token.id
                processCheckout()

        createStripeSourceCheckout = () ->
          stripe.api.createSource(stripe.number, { type: 'card', currency: scope.currentCurrency.iso_code, owner: { name: scope.formData.order.payment_data.holdername } })
            .then (result) ->
              if result.error
                btnPlaceOrder.stop()
                scope.state.isCheckoutLoading = false # sync state and loading style
                scope.errors.checkout = result.error.message
                renderErrors()
              else
                scope.formData.order.payment_data.source = result.source.id
                scope.formData.order.payment_data.three_d_secure = result.source.card.three_d_secure
                processCheckout()

        createStripePaymentIntentCheckout = () ->
          stripe.api.createPaymentMethod(type: 'card', card: stripe.number, billing_details: name: scope.formData.order.payment_data.holdername).then (result) ->
            if result.error
              btnPlaceOrder.stop()
              scope.state.isCheckoutLoading = false # sync state and loading style
              scope.errors.checkout = result.error.message
              renderErrors()
            else
              scope.formData.order.payment_data.stripe_payment_method_id = result.paymentMethod.id
              scope.formData.order.payment_data.three_d_secure = result.paymentMethod.card.three_d_secure_usage.supported
              processCheckout()

        $http({
          method: 'GET',
          url: '/api/merchants/' + mainConfig.merchantId + '/countries'
        }).then((res) ->
          scope.countries.push.apply(scope.countries, res.data);
          scope.currentCountryName = _.findWhere(scope.countries, { code: cart.country }).name;
        )

        sendSlpixelTracking = (cart) ->
          cartItems = []
          pushItem = (item) ->
            if(!_.isObject(item) || !_.isObject(cart) || !_.isObject(item.product) || item.type == 'custom_discount')
              return
            price = cartService.getItemPrice(item)

            cartItems.push {
              productID: item.product_id,
              type: item.type,
              name: $filter('translateModel')(item.product.title_translations),
              currency: price && price.currency_iso,
              price: price && price.dollars,
              quantity: item.quantity,
              variationID: item.variation_id
            }

          if (Array.isArray(cart.items))
            cart.items.forEach (item) ->
              pushItem(item)
              if (Array.isArray(item.addon_items))
                item.addon_items.forEach (addonItem) ->
                  pushItem(addonItem)

          try
            slPixelService.hdTracking(
              'checkout',
              null,
              {
                cartItems: cartItems,
                total: cart.total && cart.total.dollars,
                currency: cart.total && cart.total.currency_iso,
                deliveryFee: cart.delivery_fee && cart.delivery_fee.dollars,
                discount: cart.discount && cart.discount.dollars,
                subtotal: cart.subtotal && cart.subtotal.dollars,
                coupon: cart.coupon_codes,
                additionalFee: cart.payment_fee && cart.payment_fee.dollars,
                affiliate: cart.referral_code,
                tax: JSON.parse(cart.tax).dollars,
                country: cart.user_country
              }
            )
          catch e
            console.log(e)

        sendSlpixelTracking(cart)

        # hiido tracking checkout pageview
        hiidoTrackerService.checkout.pageView()
    }
])
