app.directive('pageItemInstagram', [
  '$timeout',
  '$http',
  'mainConfig',
  'featureService',
  'imageService',
  'slFeatureService',
  function (
    $timeout,
    $http,
    mainConfig,
    featureService,
    imageService,
    slFeatureService,
  ) {
    return {
      restrict: 'E',
      templateUrl: require('../../../../../public/themes/v1/default/views/pages.items.instagram.html'),
      scope: {
        shouldDisplayLikes: '=',
        shouldDisplayCaptions: '=',
        itemsPerPage: '@',
        itemsPerRow: '@',
        itemsPerColumn: '@',
        rowCount: '@',
        colClass: '@', // Keeping the boostrap col for controlling size on different devices
      },
      link: function (scope, element) {
        scope.state = {
          loading: true,
        };
        var postCount = scope.itemsPerPage || 24;
        var usingOldSetting = !scope.itemsPerRow || !scope.itemsPerColumn;

        scope.slFeatureService = slFeatureService;

        scope.getItemsPerColumn = function () {
          if (usingOldSetting) {
            if (scope.rowCount === '4') {
              return 1;
            } else if (scope.rowCount === '3') {
              return 2;
            } else {
              return 4;
            }
          } else {
            return scope.itemsPerColumn;
          }
        };

        scope.getItemsPerRow = function () {
          return (
            scope.itemsPerRow ||
            Math.ceil(parseInt(scope.itemsPerPage) / scope.itemsPerColumn)
          );
        };

        if (!usingOldSetting) {
          postCount = scope.itemsPerColumn * scope.itemsPerRow;
        }

        scope.getStyle = function () {
          return { width: (100 / scope.getItemsPerColumn()).toFixed(3) + '%' };
        };

        var accessToken = mainConfig.merchantData.instagram_access_token;
        var instagramId = mainConfig.merchantData.instagram_id;

        var jsonLink =
          'https://api.instagram.com/v1/users/self/media/recent/?access_token=' +
          accessToken +
          '&count=' +
          postCount;
        if (slFeatureService.hasFeature('ig_new_api')) {
          jsonLink =
            'https://graph.instagram.com/' +
            instagramId +
            '/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=' +
            accessToken +
            '&limit=' +
            postCount;
        }
        $http.jsonp(jsonLink, { jsonpCallbackParam: 'callback' }).then(
          function (response) {
            scope.posts = response.data.data;
            scope.state.loading = false;
            $timeout(function () {
              window.resizeImages(element[0]);
            }, 0);
          },
          function () {
            scope.posts = [];
          },
        );

        scope.getImageStyle = function (post) {
          return {
            'background-image': 'url(' + post.images.low_resolution.url + ')',
          };
        };

        scope.getNewIgImageStyle = function (post) {
          if (!_.isObject(post)) return '';
          var image_url =
            post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url;
          return {
            'background-image': 'url(' + image_url + ')',
          };
        };
      },
    };
  },
]);
