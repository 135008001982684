app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('vi', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.vi': 'Việt nam',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'form.action.delete': 'Xóa',

      'recaptcha.language': 'vi',

      'form.validation.required': '{{field_name}} là bắt buộc',
      'form.validation.maxlength': '{{field_name}} quá dài',
      'form.validation.maxlength.with.number':
        '{{field_name}} is up to {{max_length}} characters',
      'form.validation.minlength': '{{field_name}} quá ngắn',
      'form.validation.invalid': '{{field_name}} không hợp lệ',
      'form.validation.pattern': '{{field_name}} không hợp lệ',
      'form.validation.pattern.recipient-name':
        'Không cho phép dùng dấu cách và biểu tượng, hoặc quá {{limit_length}} ký tự.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} phải nhập 10 chữ số',
      'form.validation.email': '{{field_name}} không hợp lệ',
      'form.validation.id_no':
        'Vui lòng điền số CMND thực của người nhận để khai báo nhập khẩu thuận lợi',
      'form.validation.passport':
        'Vui lòng điền số hộ chiếu thực của người nhận để khai báo nhập khẩu thuận lợi',
      'form.validation.unique':
        '{{field_name}} đã có người dùng. Xin hãy chọn lại.',
      'form.validation.confirmation': 'Mật khẩu không khớp.',
      'form.validation.policy':
        'Bạn phải đồng ý với các điều khoản và điều kiện để tiếp tục.',
      'form.validation.delivery_option.outlying':
        'Loại giao hàng bạn đã chọn không được cung cấp cho các đảo xa ở Đài Loan, vui lòng thay đổi địa chỉ giao hàng hoặc chọn phương thức giao hàng khác.',
      'form.validation.delivery_option.local':
        'Loại giao hàng bạn đã chọn không được cung cấp cho các đảo ở Đài Loan, vui lòng thay đổi địa chỉ giao hàng tới các đảo xa hoặc chọn phương thức giao hàng khác.',
      'form.validation.delivery_option.country':
        'Loại giao hàng bạn đã chọn không được cung cấp ở quốc gia này, vui lòng thay đổi địa chỉ giao hàng hoặc chọn phương thức giao hàng khác.',
      'form.validation.delivery_option.region':
        'Loại giao hàng bạn đã chọn không được cung cấp ở vùng này, vui lòng thay đổi địa chỉ giao hàng hoặc chọn phương thức giao hàng khác.',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Ngày sinh không hợp lệ, phải trên {{minimumAgeLimit}} tuổi',
      'form.validation.email.duplicate':
        'Email này đã được sử dụng, vui lòng điền một email khác.',
      'form.validation.email.hint': 'Vui lòng nhập một địa chỉ email hợp lệ.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',
      'form.validation.calling_code': 'Mã quốc gia là bắt buộc',
      'form.validation.phone.error': ' không chính xác, vui lòng kiểm tra lại',
      'dropdown.hint': 'Xin hãy lựa chọn',
      'redirect.go_home_in_seconds': 'Quay lại Trang chủ sau {{seconds}} giây',

      'session.signup.title': 'Đăng ký',
      'session.terms.facebook': 'Đăng ký bằng Faceboo',
      'session.signup.facebook': 'Đăng nhập Faceboo',
      'sessions.or': 'HOẶ',
      'session.terms':
        'Bằng việc tiếp tục, bạn đồng ý với các điều khoản và điều kiện của chúng tôi',
      'session.signin.submit': 'Đăng nhập',
      'session.forgot_password': 'Quên mật khẩ',
      'session.signin.title': 'Đăng nhập',
      'session.signin.facebook': 'Đăng nhập Facebook',
      'session.mobile_signup.check_mobile.title':
        'Xin hãy kiểm tra xem số điện thoại di động của bạn có đúng không bên dưới, tin nhắn sms sẽ được gửi tới số di động này',
      'session.mobile_signup.check_mobile.your_number':
        'Số điện thoại di động của bạn',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Vui lòng nhập số điện thoại di động ở Đài Loan (bắt đầu bằng 09)',
      'session.mobile_signup.check_mobile.send_code': 'Gửi mã kiểm tra cho tôi',
      'session.mobile_signup.check_mobile.go_back.1': 'bạn có thể',
      'session.mobile_signup.check_mobile.go_back.2': 'quay lại',
      'session.mobile_signup.check_mobile.go_back.3':
        'để sửa lại số di động nếu bị sai',
      'session.mobile_signup.input_code.hint':
        'Xin hãy nhập mã kiểm tra bạn đã nhận',
      'session.mobile_signup.input_code.submit': 'Đi',
      'session.mobile_signup.input_code.resend.countdown':
        'Gửi lại sms mã kiểm tra tới số di động của tôi ({{resendCountdown}} giây)',
      'session.mobile_signup.input_code.resend':
        'Gửi lại sms mã kiểm tra tới số di động của tôi',
      'session.forget_passowrd.check_phone.error':
        'Số di động không chính xác, vui lòng kiểm tra lại',
      'session.mobile_center.check_phone.error': 'Số di động này đã được dùng',

      'user.quick_signup.title': 'Gia nhập làm thành viên ngay!',
      'user.quick_signup.title.content': 'Đừng bỏ lỡ những ưu đãi đặc biệt!',
      'user.quick_signup.promotion':
        'Tôi muốn nhận thông tin cập nhật và chương trình khuyến mãi mới nhất',
      'user.quick_signup.policy': 'Tôi đồng ý với',
      'user.quick_signup.policy.url_content':
        ' Điều khoản Sử dụng và Chính sách Quyền riêng tư của cửa hàng',
      'user.quick_signup.submit': 'Tham gia ngay',
      'user.quick_signup.error.format': 'Lỗi Định dạng',
      'user.quick_signup.error.confirmed':
        'Số điện thoại di động này đã được sử dụng. ',
      'user.quick_signup.error.confirmed.url': 'Đăng nhập ngay.',
      'user.quick_signup.validate.title': 'Tiếp tục Đăng ký',
      'user.quick_signup.validate.success':
        'Cảm ơn bạn đã gia nhập  làm thành viên！ <br/> Bạn có thể hoàn tất quy trình đăng ký, <br/>hoặc ',
      'user.quick_signup.validate.success.shop': 'bắt đầu mua sắm ngay',
      'user.quick_signup.validate.finish_hint':
        'Hoàn tất đăng ký để kiểm tra và sử dụng điểm của cửa hàng ngay!',
      'user.quick_signup.validate.phone': 'Xác thực',
      'user.quick_signup.validate.send.sms': 'Gửi mã số xác thực',
      'user.quick_signup.validate.wrong.number':
        'Nếu số di động không đúng, xin hãy',
      'user.quick_signup.validate.previous': 'quay lại',
      'user.quick_signup.validate.modify': ' và cập nhật',
      'user.quick_signup.validate.hint.password': 'Ít nhất 8 ký tự',
      'user.quick_signup.validate.hint.send':
        'Đã gửi mã số xác thực. Bạn có thể gửi lại mã mới sau {{count}}giây.',
      'user.quick_signup.validate.hint.resend':
        'Bạn chưa nhận được mã kiểm tra? Vui lòng bấm vào đây để gửi lại.',
      'user.quick_signup.validate.submit': 'Hoàn tất Đăng ký',
      'user.quick_signup.validate.placeholder.sms': 'Nhập mã số xác thực',
      'user.quick_signup.validate.placeholder.name': 'Tên',
      'user.quick_signup.validate.placeholder.password': 'Nhập mật khẩu',
      'user.sign_up.email_verification.dialog.title':
        'Email Xác thực đã được gửi tới {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        'Xin hãy xác thực email ngay và địa chỉ email mới sẽ được cập nhật. Nếu bạn chưa nhận được thư, xin hãy nhập lại email và lưu lại.',
      'user.sign_up.email_verification.dialog.confirm': 'Xác nhận',
      'user.delivery_data.recipient_phone': 'Số điện thoại của người nhận',

      'product.out_of_stock': 'Hết hàn',
      'product.addon_products.label': 'Sản phẩm Bổ sung',
      'product.bundled_products.label': 'Giá gộp',
      'product.bundle_group_products.label': 'Nhóm gộp',
      'product.addon_products.errors.main_product_out_of_stock':
        'Sản phẩm chính không có đủ hàng',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Giới hạn {{ message }} mỗi đơn hàng.',
      'product.addon_products.errors.addon_product_out_of_stock':
        'Sản phẩm bổ sung không có đủ hàng',
      'product.addon_products.errors.larger_addon_product':
        'Số lượng sản phẩm bổ sung không được nhiều hơn số lượng sản phẩm chính',
      'product.addon_products.errors.out_of_stock':
        'Sản phẩm bổ sung {{title}} không có đủ hàng.',
      'product.addon_products.tips.limit_exceed':
        'Khi bạn điều chỉnh số lượng, số lượng của sản phẩm bổ sung không thể lớn hơn số lượng của sản phẩm chính.',
      'product.addon_products.add_to_cart.hint':
        'Sản phẩm đã được thêm vào giỏ hàng thành công',

      'product.add_to_cart': 'THÊM VÀO GIỎ HÀNG',
      'product.add_to_cart_fail': 'Giỏ hàng của bạn đã đầy!',
      'product.buy_now': 'MUA NGAY',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'member.center.load.more': 'Nhiều hơn',
      'member.center.load.more.error':
        'Không thể tải dữ liệu, vui lòng thử lại.',

      'wishlist.signin_register': 'Đăng nhập / đăng ký ngay bây giờ',
      'wishlist.messages.remove_error':
        'Không thể xóa sản phẩm trong danh sách mong muốn.',
      'back_in_stock.subscibe.success.message':
        'Khi hàng mới về, bạn sẽ nhận được email thông báo',
      'back_in_stock.added': 'Đã bật thông báo khi có hàng mới về',
      'back_in_stock.avaiable': 'Gửi thông báo khi có hàng',

      orders: 'Đơn hàng',
      order: 'Đơn hàng',
      'orders.fields.billing_address': 'Địa chỉ thanh toán',
      'orders.fields.order_number': 'Số Đơn hàng',
      'orders.fields.order_date': 'Ngà',
      'orders.fields.order_status': 'Tình trạn',
      'orders.fields.order_remarks': 'Ghi chú Đơn hàn',
      'orders.fields.customer_name': 'Tên Khách hàn',
      'orders.fields.product_subscription_period': '{{period}}t',
      'orders.fields.status.temp': 'Đang xử lý',
      'orders.fields.status.pending': 'Đang xử lý',
      'orders.fields.status.confirmed': 'Đã xác nhậ',
      'orders.fields.status.shipped': 'Đã gửi hàn',
      'orders.fields.status.completed': 'Đã hoàn tất',
      'orders.fields.status.received': 'Đã nhậ',
      'orders.fields.status.cancelled': 'Đã huỷ',
      'orders.fields.status.returning': 'Đang trả lạ',
      'orders.fields.status.returned': 'Đã trả lạ',
      'orders.fields.preorder_item': 'Sản phẩm Đặt trướ',
      'orders.fields.customer': 'Khách hàn',
      'orders.fields.customer.phone': 'Số điện thoại của khách hà',
      'orders.fields.billto': 'Gửi hóa đơn tớ',
      'orders.fields.payment_type': 'Loại hình Thanh toá',
      'orders.fields.payment_instructions': 'Hướng dẫn Thanh toán',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'Giao dịch không thành công: Giá trị đặt hàng tối đa với Thanh toán CVS là NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'Giao dịch không thành công: Giá trị thanh toán thấp nhất với Thanh toán CVS là NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'Giao dịch không thành công: Giá trị đặt hàng tối đa với Thanh toán Mã vạch là NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Giao dịch không thành công: Giá trị đặt hàng thấp nhất với Thanh toán Mã vạch là NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'Giới hạn trên với mỗi giao dịch sử dụng CVS là NTD20.000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'Giới hạn trên với mỗi giao dịch sử dụng Mã vạch là NTD20.000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'Giới hạn trên với mỗi giao dịch sử dụng WebATM là NTD30.000',
      'orders.fields.delivery_method': 'Phương thức Giao hàng',
      'orders.fields.delivery_description': 'Mô tả hàng hóa',
      'orders.fields.delivery_fee': 'Phí Giao hàng',
      'orders.fields.delivery_address': 'Địa chỉ Giao hàng',
      'orders.fields.delivery_address.remarks': 'Ghi chú Giao hàng',
      'orders.fields.accept_terms.validation.required':
        'Bạn phải đồng ý với các điều khoản và điều kiện để tiếp tục',
      'orders.fields.options.blacklist.erro':
        'Sản phẩm của bạn có các phương thức giao hàng hoặc phương thức thanh toán khác nhau. Xin hãy chọn các phương thức hợp lệ khác hoặc đặt hàng cho phù hợp.',
      'orders.show.message.thankyou.title': 'Cảm ơn bạn đã đặt hàng',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': 'Tiếp tục mua sắm ngay!',

      'orders.show.message.shipped.title': 'Sản phẩm của bạn đã được gửi đi',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': 'Số lượng đơn hàng',
      'orders.payment_slip.order_info.instructions': 'Hướng dẫn thanh toán',
      'orders.payment_slip.order_info.read_more':
        'Xem hướng dẫn thanh toán đầy đủ ',
      'orders.payment_slip.upload_form.title':
        'Sau khi hoàn thành thanh toán, vui lòng tải lên thông tin thanh toán chuyển khoản chi tiết',
      'orders.payment_slip.upload_form.hint':
        'Sau khi xác nhận thanh toán, chúng tôi sẽ sắp xếp giao hàng trong thời gian sớm nhất!',
      'orders.payment_slip.upload_form.notification':
        'Để nhận được thông báo lệnh gần nhất, xin vui lòng đăng ký',
      'orders.payment_slip.upload_form.image_input.title':
        'Tải thông tin chuyển khoản chi tiết lên',
      'orders.payment_slip.upload_form.image_input.description':
        'Vui lòng xác nhận rằng hình ảnh rõ ràng và bao gồm 5 chữ số cuối của số giao dịch, thời gian và số tiền chuyển.',
      'orders.payment_slip.upload_form.image_input.change':
        'Nhấp để thay đổi hình ảnh',
      'orders.payment_slip.upload_form.image_input.error':
        'Kích thước hình ảnh phải nhỏ hơn 10MB',
      'orders.payment_slip.upload_form.paid_time': 'Chọn thời gian thanh toán',
      'orders.payment_slip.upload_form.message':
        'Điền vào ghi chú trong Thanh toán của bạn',
      'orders.payment_slip.upload_form.message.error':
        'Không được vượt quá {{ n }} ký tự',
      'orders.payment_slip.uploaded.alert':
        'Cảm ơn bạn đã tải lên chi tiết chuyển khoản thanh toán, chúng tôi sẽ xác nhận trong thời gian sớm nhất!',
      'orders.payment_slip.uploaded.notification':
        'Đăng ký để nhận thông tin đặt hàng mới nhất',
      'orders.payment_slip.uploaded.title':
        'Dưới đây là chi tiết dưới đây chuyển của bạn',
      'orders.payment_slip.uploaded.paid_time': 'Thời gian thanh toán:',
      'orders.payment_slip.uploaded.upload_time': 'Cập nhật Proof Thời gian:',
      'orders.payment_slip.uploaded.order_link': 'Xem đơn hàng',
      'orders.payment_slip.uploaded_without_login.title':
        'Cảm ơn bạn! Chi tiết chuyển khoản thanh toán đã được tải lên cho đơn đặt hàng này',
      'orders.payment_slip.uploaded_without_login.hint':
        'Hãy đăng nhập để xem chi tiết',
      'orders.payment_slip.footer':
        'Thông tin thanh toán bạn đã điền chỉ để kiểm tra đơn đặt hàng và nó được mã hóa an toàn. Bạn có thể hoàn tất thanh toán của mình một cách dễ dàng.',

      'product_review_comments.orders.back_to_my_account':
        '< Quay lại Tài khoản của tôi',
      'product_review_comments.orders.back_to_the_store': 'Quay lại cửa hàng',
      'product_review_comments.orders.comment': 'Nhận xét',
      'product_review_comments.orders.comment_placeholder':
        '(tuỳ chọn) Bạn có hài lòng với trải nghiệm mua sắm vừa rồi? Hãy chia sẻ suy nghĩ với chúng tôi!',
      'product_review_comments.orders.completed_all_reviews':
        'Chúc mừng! Bạn đã hoàn thành tất cả đánh giá!',
      'product_review_comments.orders.orders_to_view': 'Đơn hàng cần xem xét',
      'product_review_comments.orders.order_id': 'ID đơn hàng:',
      'product_review_comments.orders.purchased_date': 'Ngày mua hàng:',
      'product_review_comments.orders.rate_other_order':
        'Đánh giá đơn hàng khác',
      'product_review_comments.orders.review': 'Gửi',
      'product_review_comments.orders.send': 'Gửi',
      'product_review_comments.orders.thanks_for_your_review':
        'Cảm ơn đánh giá của bạn!',
      'product_review_comments.orders.max_length_of_characters':
        'Tối đa 2000 ký tự',
      'product_review_comments.orders.write_a_review': 'Viết đánh giá',
      'product_review_comments.orders.upload_image':
        'Tải hình ảnh (tối đa 9 ảnh)',

      'product.product_review.out_of_5_stars': 'sao',
      'product.product_review.rating_source_amazon': 'Nguồn đánh giá: Amazon',
      'product.product_review.rating_source_shopline':
        'Nguồn đánh giá: Trên cửa hàng này',
      'product.product_review.reviews': 'Đánh giá',
      'product.product_review.stars': 'sao',
      'product.product_review.no_review': 'Sản phẩm này chưa có đánh giá nào',
      'product_review_entry.review_description_title':
        'Chúng tôi vẫn đang đợi phản hồi của bạn!',
      'product_review_entry.review_description_content':
        'Bạn có hài lòng với trải nghiệm mua sắm vừa rồi? Hãy chia sẻ suy nghĩ với chúng tôi!',
      'product_review_entry.review_button_text': 'Viết đánh giá',

      // customer cancel order section
      'orders.show.check_order.title': 'Kiểm tra đơn hàng',
      'orders.show.check_order.description':
        'Bạn bạn thể xem hoặc huỷ đơn hàng tại <a href="{{pagePath}}">Trung tâm thành viên> Đơn hàng</a>',
      'orders.show.customer_cancel_order.confirm.title':
        'Bạn có chắc chắn muốn hủy bỏ?',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        'Rất tiếc khi thấy bạn hủy. Vui lòng cho chúng tôi biết lý do, chúng tôi sẽ cố gắng làm cho trải nghiệm mua sắm tiếp theo của bạn tốt hơn! Tiền sẽ được hoàn sau khi hủy.',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        'Rất tiếc khi thấy bạn hủy. Vui lòng cho chúng tôi biết lý do, chúng tôi sẽ cố gắng làm cho trải nghiệm mua sắm tiếp theo của bạn tốt hơn! Có thể sẽ mất vài ngày để xử lý hoàn tiền, vui lòng kiên nhẫn chờ đợi.',
      'orders.show.customer_cancel_order.confirm.loading.title': 'Đang xử lý',
      'orders.show.customer_cancel_order.confirm.loading.description':
        'Vui lòng đợi trong giây lát. Đừng rời khỏi hoặc quay lại trang trước.',
      'orders.show.customer_cancel_order.confirm.success.title':
        'Yêu cầu hủy được gửi.',
      'orders.show.customer_cancel_order.confirm.success.description':
        'Thông báo qua email sẽ được gửi sau khi hủy thành công',
      'orders.show.customer_cancel_order.confirm.failed.title':
        'Rất tiếc! Việc hủy đã thất bại. Vui lòng thử lại.',
      'orders.show.customer_cancel_order.confirm.failed.description':
        'Nếu thử lại không thành công, vui lòng liên hệ với chúng tôi tại "Bình luận của cửa hàng và khách hàng" bên dưới.',
      'orders.show.customer_cancel_order.button.cancel': 'Hủy đơn hàng',
      'orders.show.customer_cancel_order.button.ok': 'OK',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default':
        'Vui lòng chọn một lý do để hủy bỏ',
      'orders.show.customer_cancel_order.reasons.change_mind':
        'Thay đổi suy nghĩ của bạn',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        'Giá đắt hơn những nơi khác',
      'orders.show.customer_cancel_order.reasons.repeat_purchase':
        'Đơn hàng bị trùng',
      'orders.show.customer_cancel_order.reasons.wait_too_long':
        'Đợi quá lâu để nhận được hàng',
      'orders.show.customer_cancel_order.reasons.change_item':
        'Muốn thay đổi mục mua hàng',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        'Nhập thông tin giao hàng hoặc thanh toán không chính xác',
      'orders.show.customer_cancel_order.reasons.other': 'Khác',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        'Xin vui lòng cho chúng tôi biết lý do để chúng tôi có thể làm tốt hơn vào lần tới!',
      'orders.show.customer_cancel_order.reasons.hint': 'Lý do hủy bỏ',

      'orders.index.no_records.title': 'Bạn không có đơn hàng nào.',
      'orders.index.no_records.description':
        'Có vẻ bạn chưa từng đặt hàng ở cửa hàng này.',
      'orders.index.no_records.action': 'Mua hàng',
      'orders.index.title': 'Đơn hàng',
      'orders.index.action.view': 'Xem',
      'address.fields.address.recipient_name': 'Tên Người nhận',
      'address.fields.address': 'Địa chỉ',
      'address.fields.city': 'Thành phố',
      'address.fields.postcode': 'Mã bưu chính',
      'address.fields.country': 'Quốc gia',

      'orders.fields.subtotal': 'Tổng phụ',
      'orders.fields.total': 'Tổng giá trị đơn hàng',
      'order.billing_address.same': 'Giống như địa chỉ gửi hàng',

      'orders.coupons.label': 'Mã phiếu giảm giá',
      'orders.coupons':
        'Xin chúc mừng! Bạn được hưởng các ưu đãi giảm giá sau:',
      'orders.coupons.placeholder': 'Nhập Mã Phiếu giảm giá',
      'orders.promotion_coupons.placeholder': 'Nhập Mã Phiếu giảm giá',

      'orders.coupons.apply': 'Sử dụng Phiếu giảm giá',
      'orders.fields.order_discount': 'Giảm giá áp dụng',
      'orders.fields.order_custom_discount': 'Giảm giá (tùy chỉnh)',
      'orders.coupons.invalid':
        'Rất tiếc, phiếu giảm giá của bạn không hợp lệ.',
      'orders.coupons.validation.minamount':
        'Rất tiếc, đơn hàng của bạn không đạt giá trị cần thiết để sử dụng phiếu giảm giá này',
      'orders.coupons.invalid.order':
        'Vui lòng xóa mã phiếu giảm giá không hợp lệ trước khi đặt hàng.',

      'orders.actions.title': 'Quản lý Tình trạng Đặt hàng',
      'orders.action.cancel': 'Hủy Đơn hàng',
      'orders.action.confirm': 'Xác nhận Đơn hàng',
      'orders.action.ship': 'Đánh dấu Đã gửi',
      'orders.general.title': 'Chi tiết đơn hàng',
      'orders.payment.title': 'Thông tin thanh toán',
      'orders.delivery.title': 'Thông tin giao hàng',
      'orders.products.title': 'Thông tin Sản phẩm',

      'orders.fields.accept_terms':
        'Bạn đồng ý với Điều khoản Sử dụng và Chính sách Quyền riêng tư của Cửa hàng',
      'orders.action.checkout': 'Đặt hàng',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Tên Người nhận',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'Tên Cửa hàng 7-11',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'Mã Cửa hàng 7-11',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Tên Người nhận',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Tên Cửa hàng Family Mart',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Mã Cửa hàng Family Mart',
      'orders.fields.delivery_data.tw_ezship.location_code.hint':
        'Tìm cửa hàng',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        'Mã Cửa hàng',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        'Tên Cửa hàng',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Tên Người nhận',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'ID Tham chiếu',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        'Family Mart',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label':
        'Cửa hàng',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        'Đã xảy ra lỗi trong phương thức giao hàng, vui lòng làm mới trang hoặc <a href="/cart" class="alert-highlight">quay lại trang trước để thay đổi phương thức giao hàng</a>.',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        'Lỗi địa chỉ, vui lòng chọn lại',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        'Mã Shop là bắt buộc',

      // Checkout page error message field name
      'order.customer_name': 'Tên khách hàng',
      'order.email': 'Địa chỉ email',
      'order.customer_email': 'Địa chỉ email của khách hàng',
      'order.customer_phone': 'Số điện thoại của khách hàng',
      'order.delivery_data.recipient_name': 'Tên người nhận',
      'order.delivery_data.recipient_phone': 'Số điện thoại của người nhận',
      'order.delivery_data.consignee_id_no': 'Số CMND hoặc Hộ chiếu người nhận',
      'order.delivery_address.address_1': 'Địa chỉ',
      'order.delivery_address.city': 'Thành phố',
      'order.delivery_address.state': 'Quận/Bang/Tỉnh',
      'order.delivery_data.location_code': 'Mã Cửa hàng',
      'order.delivery_data.location_name': 'Tên Cửa hàng',
      'order.delivery_data.time_slot': 'Thời gian Giao hàng',
      'order.delivery_data.scheduled_delivery_date': 'Ngày đến',
      'order.delivery_data.time_slot_key': 'Khoảng thời gian đến',
      'order.invoice.carrier_number': 'Hãng vận chuyển',
      'order.invoice.mailing_address': 'Địa chỉ',
      'order.invoice.tax_id': 'ID Thuế',
      'order.payment_data.holdername': 'Họ tên Chủ thẻ',
      'order.payment_data.expiry_date': 'Ngày hết hạn',
      'order.payment_data.cvc': 'CVC',
      'order.payment_data.credit_card_number': 'Số thẻ tín dụng',

      'lock_inventory.locked_quantity_unit': 'sản phẩm',

      'delivery.sfexpress.district': 'SF Express Quận',
      'delivery.sfexpress.store': 'SF Express Địa điểm',
      'delivery_address.region.hk': 'Vùng',
      'delivery_address.district.hk': 'Quận',
      'delivery_address.region.tw': 'Bang/Thành phố',
      'delivery_address.district.tw': 'Quận',
      'save_fields.customer_info.birthday': 'Sinh nhật khách hàng',

      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'Tỉnh/ Thành phố',
      'delivery_address.district.th': 'Quận/ Huyện',
      'delivery_address.ward.th': 'Phường/ Xã',
      'delivery_address.street': 'Đường / Phố',
      'delivery_address.store': 'Cửa hàng {{ station_name }}',

      'delivery_options.fields.delivery_type': 'Loại Giao hàng',
      'delivery_options.fields.delivery_types.pickup': 'Đến lấy',
      'delivery_options.fields.delivery_types.local': 'Trong nước',
      'delivery_options.fields.delivery_types.email': 'Email',
      'delivery_options.fields.delivery_types.international': 'Quốc tế',
      'delivery_options.fields.delivery_types.custom': 'Tùy chỉnh',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11 Đến lấy (chỉ ở TW)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Family Mart Đến lấy (chỉ ở TW)',
      'delivery_options.fields.select_date': 'Ngày đến',

      'ezship.error.invalid_input':
        'Một hoặc nhiều trường không hợp lệ hoặc bị thiếu',
      'ezship.error.account_not_exist':
        'Tài khoản ezShip không tồn tại. Xin hãy liên hệ với người bán',
      'ezship.error.no_permission': 'Tài khoản ezShip không đủ cấp phép',
      'ezship.error.no_easybag':
        'Tài khoản ezShip không có Easybag hoặc Minibag khả dụng',
      'ezship.error.invalid_store': 'Cửa hàng Đến lấy Không hợp lệ',
      'ezship.error.invalid_amount':
        'Số tiền không hợp lệ. Số tiền tối đa được phép khi sử dụng ezShip là 6000 TWD mỗi đơn hàng.',
      'ezship.error.invalid_email': 'Email không hợp lệ',
      'ezship.error.invalid_mobile': 'Số điện thoại không hợp lệ',
      'ezship.error.invalid_name': 'Tên người nhận không hợp lệ',
      'ezship.error.system_error':
        'Lỗi hệ thống ezShip. Vui lòng chọn phương thức giao hàng khác hoặc thử lại sau.',

      'payments.fields.types.paypal': 'Thẻ tín dụng hoặc Paypal',
      'payments.fields.types.paypal_express': 'Thẻ tín dụng hoặc Paypal',
      'payments.fields.types.credit_card': 'Thẻ tín dụng',
      'payments.fields.types.bank_transfer': 'Chuyển khoản Ngân hàng',
      'payments.fields.types.cash_on_delivery': 'Hàng đến trả tiền',
      'payments.fields.types.free_checkout': 'Thanh toán miễn phí',
      'payments.fields.types.custom': 'Tùy chỉnh',
      'payments.fields.types.allpay_barcode': 'Mã vạch qua Allpay',
      'payments.fields.types.allpay_credit': 'Thẻ tín dụng qua Allpay',
      'payments.fields.types.allpay_cvs': 'CVS qua Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM qua Allpay',
      'payments.fields.types.asiapay': 'Thẻ tín dụng qua Asiapa',
      'payments.fields.types.prizm': 'Thẻ tín dụng Visa / Master',
      'payments.fields.types.esun': 'Thẻ tín dụng Esu',

      'payment.cc.expirydate': 'Ngày hết hạn',
      'payment.cc.holdername': 'Tên Chủ thẻ',
      'payment.cc.number': 'Số thẻ tín dụng (Visa / Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'Tháng/Năm',
      'payment.cc.holdername.placeholder': 'Tên Chủ thẻ',
      'payment.cc.holdername.hint': 'Giống như họ tên đầy đủ trên thẻ',
      'payment.cc.number.placeholder': 'Số Thẻ tín dụng (không có dấu cách)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint':
        'Mã bảo mật gồm 3 chữ số thường thấy ở mặt sau thẻ.',
      'payment.action.confirm': 'Xác nhận',
      'payment.cc.update.instructions':
        'Vui lòng nhập thẻ tín dụng bên dưới để cập nhật',
      'payment.cc.fields.accept_terms':
        'Tôi đồng ý với Điều khoản sử dụng và Chính sách Quyền riêng tư',
      'payment.cc.fields.accept_terms.validation.required':
        'Bạn phải đồng ý với các Điều khoản và Điều kiện để tiếp tục',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship':
        'Thanh toán khi đến nhập hàng tại cửa hàng qua ezShip',

      'cart.title': 'Giỏ hàng',
      'cart.clean': 'Thanh toán ngay bây giờ!',
      'cart.over_limit':
        'Giỏ hàng vượt quá số lượng <span class="limit-number">{{limit_number}}</span> mặt hàng tối đa, vui lòng kiểm tra trước khi thêm mặt hàng mới. Bạn cũng có thể đăng nhập để thêm các mục này vào danh sách yêu thích của mình!',
      'cart.over_limit.login':
        'Giỏ hàng vượt quá số lượng <span class="limit-number">{{limit_number}}</span> mặt hàng tối đa, vui lòng kiểm tra trước khi thêm mặt hàng mới. Bạn cũng có thể thêm mặt hàng này vào danh sách yêu thích của mình!',
      'cart.item.remove': 'Xóa',
      'cart.items.remove': 'Xóa',
      'cart.checkout': 'Thanh toán',
      'cart.empty': 'Giỏ hàng của bạn đang trống.',
      'cart.empty.description': 'Thêm sản phẩm vào giỏ hàng để tiếp tục.',
      'cart.empty.continue': 'Tiếp tục mua sắm ngay!',

      'cart.promotion.has_items_selected':
        '<b>{{quantity}}</b> sản phẩm đã được chọn',
      'cart.promotion.bundle_group.has_items_selected':
        'Nhóm A <b style="color: #f05c53">{{red_quantity}}</b> sản phẩm đã được chọn{{red_reminder}}; Nhóm B <b style="color: #1ba462">{{green_quantity}}</b> sản phẩm đã được chọn{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'Nhóm A <b style="color: #f05c53">{{red_quantity}}</b> sản phẩm; Nhóm B <b style="color: #1ba462">{{green_quantity}}</b> sản phẩm',

      'cart.copy.info.copied': 'Đã sao chép địa chỉ trang web:',
      'cart.copy.info.affiliate_code': 'Mã giới thiệu ({{code}}), ',
      'cart.copy.info.product_count': '{{count}} sản phẩm',

      'cart.promotion_reminder.separator': '. ',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">Nhóm A</span> còn {{gap}} sản phẩm nữa',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">Nhóm B</span> còn {{gap}} sản phẩm nữa',
      'cart.promotion_reminder.group_gap': ' (còn {{gap}} sản phẩm nữa)',
      'cart.promotion_reminder.item': 'Mua thêm {{gap}} sản phẩm ',
      'cart.promotion_reminder.amount': 'Mua thêm {{gap}} ',
      'cart.promotion_reminder.multiple_step': 'để tiết kiệm hơn!',
      'cart.promotion_reminder.stackable': 'Mua nhiều hơn, tiết kiệm hơn!',
      'cart.promotion_reminder.applied.free_shipping':
        'Tận hưởng miễn phí ship',
      'cart.promotion_reminder.applied.gift': 'Tận hưởng Quà tặng miễn phí',
      'cart.promotion_reminder.applied.discount_prefix': '. ',
      'cart.promotion_reminder.applied.discount': 'Tận hưởng ưu đãi, ',
      'cart.promotion_reminder.applied_highest.free_shipping':
        'Tận hưởng miễn phí ship!',
      'cart.promotion_reminder.applied_highest.gift':
        'Tận hưởng Quà tặng miễn phí!',
      'cart.promotion_reminder.applied_highest.discount': 'Tận hưởng ưu đãi!',
      'cart.promotion_reminder.discount_target.free_shipping':
        'để được miễn phí ship!',
      'cart.promotion_reminder.discount_target.gift':
        'để được quà tặng miễn phí!',
      'cart.promotion_reminder.discount_target.discount':
        'để nhận được ưu đãi!',

      'cart.share.btn.tooltip':
        'Sao chép các sản phẩm trong giỏ hàng và chia sẻ cho bạn bè',
      'checkout.instructions': 'Chi tiết đơn hàng',
      'checkout.fields.email': 'Email của Khách hàng',
      'checkout.fields.email.hint':
        'Vui lòng điền địa chỉ email chính xác vì xác nhận đơn hàng sẽ được gửi tới email của bạn.',
      'checkout.fields.phone.hint':
        'Xin hãy điền số điện thoại di động của bạn để nhận cập nhật tình trạng giao hàng qua SMS',
      'checkout.fields.phone.invali': 'Số điện thoại không hợp lệ',
      'checkout.fields.email.placeholder': 'Địa chỉ Email',
      'checkout.fields.remarks': 'Ghi chú cho Cửa hàng',
      'checkout.fields.remarks.placeholder':
        'Để lại ghi chú về đơn hàng cho Cửa hàng',
      'checkout.fields.delivery_method': 'Phương thức Giao hàng',
      'checkout.fields.out_of_stock':
        'Rất tiếc, không còn đủ hàng, vui lòng xóa sản phẩm khỏi giỏ hàng và thanh toán lại.',
      'checkout.fields.phone': 'Số điện thoại liên hệ',
      'checkout.fields.phone.placeholder': 'Nhập số điện thoại liên hệ của bạn',
      'checkout.fields.add_new': 'Thêm mới...',
      'checkout.fields.select': 'Chọn một...',

      'checkout.delivery_option.title': 'Địa chỉ Giao hàng',
      'checkout.instalment_amount':
        'khoảng {{amount}} x {{period}} lần thanh toán',
      'checkout.labels.promotion.free_shipping': 'Miễn phí vận chuyển',
      'checkout.labels.promotion.coupon': 'Phiếu giảm giá',
      'checkout.labels.promotion.promotion': 'Khuyến mãi',
      'checkout.labels.promotion.membership': 'Khuyến mãi dành cho thành viên',

      'checkout.payment_method.title': 'Thanh toán',

      'checkout.discount.applied_promotions': 'Khuyến mãi đã sử dụng',
      'checkout.discount.applied_coupons': 'Phiếu giảm giá đã sử dụng',

      'checkout.promotion.tags.promotion': 'Khuyến mãi',
      'checkout.promotion.tags.coupon': 'Phiếu giảm giá',
      'checkout.promotion.tags.membership': 'Ưu đãi cho Thành viên',
      'checkout.promotion.tags.free_shipping': 'Miễn phí Vận chuyển',

      'checkout.payment_condition.title':
        'Vẫn thiếu {{difference}} để đáp ứng điều kiện thanh toán!',
      'checkout.payment_condition.description':
        'Để có thể tiếp tục thanh toán, vui lòng quay lại trang giỏ hàng để chọn phương thức thanh toán khác hoặc tiếp tục mua sắm',
      'checkout.payment_condition.continue_shopping': 'Tiếp tục mua sắm',
      'checkout.payment_condition.hint':
        'Đơn hàng này phải đáp ứng số tiền đặt hàng tối thiểu để có thể tiếp tục thanh toán',

      'checkout.payments.apple_pay.not_supported':
        'Thiết bị này không hỗ trợ Apple Pay, xin hãy kiểm tra dưới đây<ul><li>Vui lòng ĐĂNG NHẬP vào trình duyệt bằng Tài khoản Apple của bạn</li>' +
        '<li>Xin hãy THÊM  phương thức thanh toán vào Tài khoản Apple Pay của bạn</li><li>Xin hãy NÂNG CẤP thiết bị và trình duyệt lên phiên bản mới nhất</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'Thiết bị này không hỗ trợ Google Pay, xin hãy kiểm tra dưới đây<ul><li>Vui lòng ĐĂNG NHẬP vào trình duyệt bằng Tài khoản Google của bạn</li>' +
        '<li>Xin hãy THÊM  phương thức thanh toán vào Tài khoản Google Pay của bạn</li><li>Xin hãy NÂNG CẤP thiết bị và trình duyệt lên phiên bản mới nhất</li></ul>',
      'checkout.payment_failed':
        'Giao dịch không thành công, vui lòng thử lại.',
      'checkout.cart_over_limit_error':
        'Tối đa 100 sản phẩm, vui lòng điều chỉnh lại số lượng mua hàng.',

      'checkout.auto_fill.popup.title':
        'Nhập điện thoại của bạn để thanh toán nhanh chóng',
      'checkout.auto_fill.popup.continue': 'Tiếp tục',
      'checkout.auto_fill.popup.error': 'Vui lòng nhập đúng định dạng',
      'checkout.auto_fill.popup.cancel': 'Hủy bỏ',
      'checkout.auto_fill.toast.error':
        'Nếu không có hồ sơ về đơn hàng cuối cùng được phát hiện, dữ liệu thanh toán nhanh sẽ không được đưa vào',

      'address.fields.recipient_name': 'Tên Người nhận',
      'address.fields.address.city': 'Thành phố',
      'address.fields.address.state': 'Quận/Bang/Tỉnh',
      'address.fields.address.postcode': 'Mã bưu chính (nếu có)',
      'address.fields.address.country': 'Quốc gia',
      'address.fields.address.remarks': 'Ghi chú',

      'messages.form.send': 'Gửi',
      'action.add_photo': 'Thêm ảnh',
      'action.send': 'Gửi',
      'action.load_earlier': 'Tải trước đó',
      'action.load_more': 'Tải thêm',

      'users.fields.name': 'Họ tên',
      'users.fields.email': 'Email',
      'users.fields.mobile_phone_or_email': 'Email hoặc số di động',
      'users.fields.password': 'Mật khẩu',
      'users.fields.name.placeholder': 'Họ tên',
      'users.fields.email.placeholder': 'Email',
      'users.fields.password.placeholder': 'Mật khẩu',
      'users.fields.callingCode': 'Mã vùng',
      'users.fields.callingCodeHint':
        'Vui lòng chọn mã quốc gia cho số điện thoại',
      'users.save.success': 'Các thay đổi của bạn đã được lưu.',
      'users.save.email_verification.success':
        'Các thay đổi của bạn đã được lưu. Địa chỉ email sẽ được cập nhật sau khi hoàn tất xác thực.',
      'users.save.email_verification.send': 'Email xác minh đã được gửi',
      'users.add.phone': 'Thêm số mới',
      'users.add.phone.maximum': 'Bạn chỉ có thể thêm tối đa 5 số điện thoại.',
      'users.error.phone.format': 'Số điện thoại không hợp lệ',
      'users.error.phone.required': 'Bắt buộc phải có số điện thoại’',
      'users.add.delivery_address': 'Thêm địa chỉ mới',
      'users.add.delivery_address.maximum':
        'Bạn chỉ có thể thêm tối đa 5 địa chỉ.',
      'users.error.no_changes': 'Không có thay đổi nào cần lưu.',
      'users.error.maxlength': ' quá dài.',
      'users.signup.male': 'Nam',
      'users.signup.female': 'Nữ',
      'users.signup.other': 'Không khai báo',
      'users.signup.email': 'Đăng ký qua Email',
      'users.signup.mobile_phone': 'Đăng ký qua số điện thoại di động',
      'users.signup.unconfirmed':
        'Chào mừng bạn trở lại! Để bảo vệ tài khoản của bạn, cần xác thực để đăng nhập vào tài khoản của bạn. Email xác thực đã được gửi tới {{email}}. Vui lòng kiểm tra trong hòm thư, xin cảm ơn!',
      'users.signup.verification.hint.send':
        'Đã gửi email xác thực. Bạn có thể gửi lại mã mới sau {{count}}giây.',
      'users.einvoice_carrier':
        'Hóa đơn điện tử tổng hợp vào tài khoản của bạn',
      'users.edit_member_info': 'Chỉnh sửa thông tin thành viên',
      'users.member.normal-level': 'Basic',
      'users.membership.expiry_date': 'Ngày hết hạn thành viên',
      'users.membership.expire.unlimited': 'Never Expire',
      'users.membership.tier.expire.date.description':
        'Điều kiện nâng cấp sẽ được kiểm tra vào lúc nửa đêm. Việc nâng cấp thành viên sẽ không có hiệu lực nếu đơn hàng bị hủy/ hoàn tiền.',
      'users.membership.offer.discount': 'Ưu đãi dành cho thành viên',

      'users.membership.upgrade.next_tier': 'Nâng cấp thành {{tier_name}}',
      'users.membership.upgrade.next_tier.discount':
        ' để nhận {{discount}}% ưu đãi.',

      'users.membership.extension.condition': 'Điều kiện mở rộng',
      'users.membership.spending_single_purchase':
        'Nâng cấp bằng cách mua hàng đơn lẻ trên {{spending}} đồng',
      'users.membership.all.spending_within_months':
        'Tổng giá trị mua hàng trên {{spending}} đồng trong thời hạn {{months}} tháng là thành viên.',
      'users.membership.all_tiers': 'Xem tất cả các cấp độ thành viên',
      'users.membership.tier.upgrade.condition.dollar':
        'Hiện tại mua đơn lẻ vẫn chưa đạt trên {{dollar}}.',
      'users.membership.tier.upgrade.condition.continue': 'Tiếp tục mua hàng?',
      'users.membership.tier.upgrade.to_next_level.single': 'Nâng cấp lên?',

      'membership.profile.subscriptions.orders.update.status':
        'Cập nhật đơn hàng của tôi',
      'membership.profile.subscriptions.orders.comments.new':
        'Ý kiến mới từ người bán với đơn hàng của tôi',
      'membership.profile.subscriptions.messages.new':
        'Tin nhắn mới từ người bán',
      'membership.profile.subscriptions.marketing.news':
        'Cập nhật và khuyến mãi của cửa hàng',
      'membership.profile.subscriptions.none': 'Không có',
      'membership.profile.mobile.number.verified':
        'Số điện thoại di động đã được xác thực và sẽ thay thế số điện thoại bạn đã lưu trước đó.',
      'membership.profile.check.code.error':
        'Mã kiểm tra không đúng! Xin hãy nhập lại hoặc gửi lại tin nhắn sms mã kiểm tra',
      'membership.profile.verification_hint':
        'Nếu bạn thay đổi địa chỉ email thì cần phải xác minh sau khi lưu. Email sẽ được cập nhật thành {{unconfirmed_email}} sau khi xác minh xong.',

      'coupon.title': 'Mã giảm giá',
      'coupon.valid': 'Có hiệu lực',
      'coupon.invalid': 'Vô hiệu lực',
      'coupon.valid_until':
        'Kết thúc vào ngày <span class="coupon-period-time"><div class="coupon-period-tip">Hiển thị theo múi giờ của bạn <br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': 'Một lần',
      'coupon.first_shoppers': 'Chỉ {{number}} người mua đầu tiên',
      'coupon.reach_limit': 'Đạt giới hạn',
      'coupon.expired': 'Hết hạn',
      'coupon.no_valid_coupon': 'Không có mã giảm giá có hiệu lực',
      'coupon.no_invalid_coupon': 'Không có mã giảm giá vô hiệu lực',
      'coupon.select_coupon': 'Chọn mã giảm giá',
      'coupon.confirm': 'Xác nhận',
      'coupon.first_purchase_only': 'Đơn hàng đầu tiên',

      'store_credits.title': 'Điểm tại cửa hàng',
      'store_credits.credit_balance': 'Số dư điểm',
      'store_credits.credit_history': 'Lịch sử sử dụng điểm',
      'store_credits.fields.date': 'Ngày',
      'store_credits.fields.remarks': 'Lý do thay đổi điểm',
      'store_credits.fields.value': 'Biến động điểm',
      'store_credits.fields.expiry_date': 'Ngày hết hạn',
      'store_credits.fields.balance': 'Số dư điểm',
      'store_credits.fields.order_prefix': 'Sử dụng điểm cho đơn hàng',
      'store_credits.fields.welcome_credit': 'Điểm Chào mừng',
      'store_credits.fields.birthday_credit': 'Điểm Sinh nhật',
      'store_credits.fields.user_credit_expired': 'Điểm đã hết hạn',
      'store_credits.fields.never_expires': 'Không bao giờ hết hạn',
      'store_credits.fields.never_expires_point': 'Điểm không có thời hạn',
      'store_credits.fields.display.never_expired': 'Vô thời hạn',
      'store_credits.fields.auto_reward':
        'Điểm thưởng dựa trên giá trị đặt hàng tối thiểu trong đơn hàng',
      'store_credits.fields.revert_credit':
        'Điểm được trả lại từ đơn hàng bị hủy',
      'store_credits.fields.revert_credit.return':
        'Điểm tín dụng được hoàn lại từ đơn hàng hoàn lại',
      'store_credits.no_data': 'Không có hồ sơ điểm',

      'tax.sales_tax': 'Thuế bán hàng',
      'tax.sales_tax_rate': 'Thuế suất bán hàng',
      'tax.calculate_tax': 'Tính thuế',
      'tax.calculate_tax.info.1': 'Vui lòng cập nhật thuế và nhấp vào',
      'tax.calculate_tax.info.2': 'trước khi thanh toán',
      'tax.calculation_title': 'Cách tính thuế:',
      'tax.calculation_content':
        '(Tổng sản phẩm đã tính thuế - Số tiền chiết khấu) * Thuế + Phí giao hàng * Thuế',
      'tax.price_updated': 'Giá đã cập nhật',
      'tax.total': 'Tổng cộng',
      'tax.fee': 'Thuế',

      'dynamic.delivery.fee.no.state.notice':
        'Phí giao hàng sẽ được hiển thị trên trang thanh toán sau khi địa chỉ giao hàng được cập nhật.',
      'dynamic.delivery.fee.calculate.info':
        'Phí giao hàng: {{totalDeliveryFee}}',

      'error.404.title': 'Chúng tôi rất tiếc',
      'error.404.description': 'Trang này không tồn tại',
      'error.401.title': 'Chúng tôi rất tiếc',
      'error.401.description': 'Bạn không được ủy quyền để truy cập trang này',
      'error.403.title': 'Chúng tôi rất tiếc',
      'error.403.description': 'Bạn không được ủy quyền để truy cập trang này',
      'error.403.not_signed_in.message': ', có thể do bạn chưa ',
      'error.403.not_signed_in.anchor': 'đăng nhập',
      'error.continue': 'Tiếp tục mua sắm ngay!',

      'orders.comments.title': 'Ý kiến của cửa hàng và khách hàng',
      'orders.comments.text.name': 'Ý kiến',
      'orders.comments.text.placeholder': 'Nhập ý kiến cho đơn hàng này',

      'orders.fields.payment_fee': 'Phí Thanh toán',

      'orders.invoices.title': 'Hóa đơn',
      'orders.invoices.carrier': 'Hãng vận chuyển chung',
      'orders.invoices.carrier_type.member': 'Thành viên',
      'orders.invoices.carrier_type.mobile_barcode': 'Mã vạch Di động',
      'orders.invoices.carrier_type.npc_barcode': 'Mã vạch NPC',
      'orders.invoices.get_invoice': 'Tôi cần biên lai',

      'orders.notifications.title': 'Thông báo Cập nhật Đơn hàng',

      'order.payments.login.confirm':
        'Vui lòng đăng nhập và vào thanh toán lại!',

      'model.credit_card.title': 'Thông tin thẻ tín dụng của bạn',
      'model.credit_card.subtitle':
        'Vui lòng nhập thẻ tín dụng bên dưới để cập nhật',

      'sms_messenger_checkbox.subscribe': 'Gửi qua tin nhắn',

      'facebook_messenger_checkbox.subscribed':
        'Đã được gửi tới {{topic}} qua <img src="https://{{image_host}}/assets/misc/fb_messenger_36x.png" class="fb-app-icon">Messenger',
      'facebook_messenger_checkbox.turn_off': 'Tắt',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'thông báo cập nhật đơn hàng',
      'facebook_messenger_checkbox.topics.MARKETING':
        'cập nhật và khuyến mãi của cửa hàng',

      'media.validation.type': 'chỉ cho phép các định dạng jpeg và png',
      'media.validation.error.file_size': 'Hãy tải lên hình ảnh nhỏ hơn 5MB.',
      'media.validation.error.file_attachment_size':
        'Hãy tải lên một tập tin nhỏ hơn 2MB.',
      'media.validation.error': 'Lỗi tải ảnh lên',

      'attachment.upload.description':
        'Tệp phải nhỏ hơn 2MB \nLoại tệp cho phép sử dụng: pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': 'Tải ảnh lên',

      'promotions.section.discount_summary.set.on_item':
        'Được giảm giá <b>{{ discount_value }}</b> cho <b>các Sản  phẩm Nhất định</b>',
      'promotions.section.discount_summary.set.on_order':
        'Được giảm giá <b>{{ discount_value }}</b> cho <b>Toàn bộ Đơn hàng</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? Currency : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Sản phẩm Nhất định</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Toàn bộ Đơn hàng</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        ' khi mua các <b>Sản phẩm Nhất định</b> trên <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' khi mua trên <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }} sản phẩm',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Sản phẩm được chọn: Mua {{ red_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm A</span> và {{ green_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm B</span> với giá {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        'Sản phẩm được chọn: Mua {{ red_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm A</span> và {{ green_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm B</span> giảm giá {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        'Sản phẩm được chọn: Mua {{ red_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm A</span> và {{ green_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm B</span> giảm giá {{ total }}%',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        'Sản phẩm được chọn: : Mua {{ red_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm A</span> và {{ green_count }} trong <span class="bundle-group-label bundle-group-red">Nhóm B</span> tặng {{ total }} phần quà ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        '指定商品： 任選 {{ itemCount }} 件 共 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        'Sản phẩm được chọn: Mua {{ itemCount }} mặt hàng với giá {{ discountvalue }}',
      'promotions.section.discount_summary.criteria.bundle_amount':
        'Sản phẩm được chọn: Mua {{ itemCount }} mặt hàng giảm giá {{ discountvalue }}',
      'promotions.section.discount_summary.criteria.bundle_gift':
        'Sản phẩm được chọn: Mua {{ itemCount }} mặt hàng tặng {{ discountvalue }} phần quà ({{ giftMame }})',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Mua nhiều hơn, tiết kiệm hơn!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': 'giảm giá {{ value }}%',

      'promotions.page.term.title': 'Điều khoản và điều kiện',
      'promotions.page.term.confirm': 'Xác nhận',
      'promotions.page.term.default':
        '＊ Khuyến mãi áp dụng tùy theo tổng giá trị đơn hàng trong giỏ hàng.\n＊ Khách hàng nên chọn phương thức thanh toán và giao hàng hợp lệ để được hưởng khuyến mãi.\n＊ Với chương trình khuyến mãi chỉ dành cho thành viên, khách hàng nên đăng nhập để thanh toán.',
      'promotions.page.display_local_timezone':
        'Hiển thị theo múi giờ của bạn<br/>(GMT{{offset}})',
      'promotions.page.start_at':
        'Bắt đầu vào <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        'Kết thúc vào <b class="local-datetime">{{date}}</b>',

      'promotions.exclude.items.hint.title':
        'Sản phẩm giá sốc sẽ không được áp dụng với:',
      'promotions.exclude.items.hint.order_discount': 'Giảm giá trên đơn hàng',
      'promotions.exclude.items.hint.member_tier': 'Giảm giá % cho thành viên',
      'promotions.exclude.items.hint.user_credit':
        'Sử dụng điểm tín dụng cửa hàng',
      'promotions.exclude.items.hint.member_point': 'Giảm giá điểm',

      'product.page.title.product-info': 'Sản phẩm',
      'product.page.quantity': 'Số lượng',
      'product.page.unit_price': 'Đơn giá',
      'product.page.subtotal': 'Tổng phụ',
      'product.page.add_to_cart_count':
        'Đã thêm <span class="order-detail-count">{{count}}</span> sản phẩm',

      'products.quick_cart.quantity': 'Số lượng',
      'products.quick_cart.preorder_now': 'ĐẶT TRƯỚC NGAY',
      'products.quick_cart.add_to_cart': 'THÊM VÀO GIỎ HÀNG',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': 'ĐÃ BÁN HẾT',
      'products.quick_cart.out_of_stock_hint':
        'Không đủ hàng.\n Sản phẩm của bạn không được thêm vào giỏ hàng.',
      'products.quick_cart.low_stock_hint':
        'Không đủ hàng.\n Xin hãy điều chỉnh số lượng.',
      'products.quick_cart.reached_max_purchase_quantity':
        'Giới hạn {{ message }} mỗi đơn hàng.',
      'products.quick_cart.quantity_of_stock_hint':
        'Chỉ còn lại {{message}} sản phẩm.',
      'products.quick_cart.messagetobuy':
        'Vui lòng gửi tin nhắn cho chủ cửa hàng về chi tiết đơn hàng.',
      'products.quick_cart.message': 'TIN NHẮN',
      'products.quick_cart.show_more': 'Thêm chi tiết',
      'products.message.title': 'Bạn có chắc bạn muốn xóa sản phẩm này không?',
      'products.message.button_cancel': 'Hủy',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint':
        'Giới hạn {{limit}} sản phẩm cho mỗi khách hàng',
      'purchase_limit_hint.purchasable':
        'Giới hạn {{limit}} sản phẩm cho mỗi khách hàng. Bạn chỉ có thể mua được {{purchasable_qty}} sản phẩm nữa.',
      'products.purchase_limit_hint_can_not_purchase':
        'Giới hạn {{limit}} sản phẩm cho mỗi khách hàng. Sản phẩm đã đạt số lượng giới hạn.',

      'products.category.advance_filter.title': 'Lọc',
      'products.category.advance_filter.clear': 'Bỏ lựa chọn',
      'products.category.advance_filter.clear_all': 'Xoá tất cả',
      'products.category.advance_filter.apply': 'Áp dụng lọc',
      'products.category.advance_filter.apply.hint':
        'Vui lòng chọn các tùy chọn bộ lọc trước',
      'products.category.advance_filter.show_more': 'Hiển thị thêm',
      'products.category.advance_filter.show_less': 'Hiện ít hơn',
      'products.category.advance_filter.type.color': 'Màu sắc',
      'products.category.advance_filter.type.size': 'Kích thước',
      'products.category.advance_filter.type.brand': 'Thương hiệu',
      'products.category.advance_filter.type.material': 'Vật chất',
      'products.category.advance_filter.type.price':
        'Khoảng giá ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price':
        'Số tiền tối thiểu',
      'products.category.advance_filter.placeholder.max_price':
        'Số tiền tối đa',

      'products.variant_selector.hide': 'Ẩn ',
      'products.variant_selector.view_other_variations':
        'Xem các tuỳ chọn {{count}} khác',

      'hk_sfplus.region': 'Vùng',
      'hk_sfplus.area': 'Khu vực',
      'hk_sfplus.district': 'Quận',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': 'Khoảng thời gian đến',
      'tcat.time_slot.01': 'Trước 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'Bất kỳ khoảng thời gian nào',

      'order_comment_and_message.general_error':
        'Hệ thống bị lỗi. Vui lòng liên hệ với chủ cửa hàng hoặc thử lại sau.',
      'order_comment_and_message.rate_limit_error':
        'Bạn đã đạt đến giới hạn tin nhắn hôm nay. Vui lòng tiếp tục vào ngày mai.',

      'member_referral.discount': 'Chiến dịch giới thiệu thành viên',
      'member_referral.rules': 'Quy tắc chiến dịch',
      'member_referral.code.copy_and_share':
        'Chia sẻ liên kết với bạn bè của bạn, cả hai bạn đều sẽ nhận được điểm tín dụng của cửa hàng.',
      'member_referral.code.remarks': 'Quy tắc chiến dịch',
      'member_referral.generate.code': 'Tạo liên kết giới thiệu',
      'member_referral.generate.code.success': 'Liên kết giới thiệu được tạo',
      'member_referral.copy.link': 'Sao chép liên kết',
      'member_referral.copy.link.success': 'Liên kết được sao chép',
      'member_referral.welcome_credit.popup.title':
        'Xin chúc mừng, bạn đã kiếm được {{reward_value}} điểm tín dụng',
      'member_referral.welcome_credit.popup.content':
        'Bạn đã kiếm được {{reward_value}} điểm tín dụng. Bạn có thể giới thiệu bạn bè của mình để kiếm thêm điểm tín dụng.',
      'member_referral.welcome_credit.popup.continue': 'Mua ngay',

      'member_points.redeem_cash_and_earn_from_order_description':
        'Cứ {{costMoney}} kiếm {{earnPoint}} điểm. Mỗi {{costPoint}} điểm Giảm giá {{discountMoney}}',
      'member_points.redeem_cash_description':
        'Mỗi {{costPoint}} điểm Giảm giá {{discountMoney}}',
      'member_points.earn_from_order_description':
        'Cứ {{costMoney}} kiếm {{earnPoint}} điểm',
      'member_points.equal_cash': 'Tương đương với {{money}}',
      'member_points.title': 'Điểm thành viên',

      'lock_inventory.tooltip':
        'Giữ hàng đến {{expired_time}}\n(Phát trực tiếp lúc {{start_time}}:{{event_name}})\nHiển thị theo múi giờ địa phương của bạn（GMT{{timezone}}）',
      'instagram_banner.description':
        'Trình duyệt bạn đang sử dụng hiện không cho phép cookie. Nếu bạn gặp sự cố khi đăng nhập, thanh toán hoặc thêm sản phẩm vào giỏ hàng của mình, vui lòng thử một trình duyệt khác. “<span> Sao chép link</span>”',
      'facebook_banner.description':
        'Bạn đang truy cập trang web trên trình duyệt trong ứng dụng Facebook, vui lòng sử dụng trình duyệt khác nếu bạn gặp sự cố khi tải lên hình ảnh. <span>Sao chép đường dẫn</span>',

      'product_set.build': 'Chỉnh sửa bộ sản phẩm',
      'product_set.special_offer': 'Ưu đãi bộ sản phẩm',
      'product_set.items_needed':
        'Còn thiếu <span class="highlight-label">{{ quantity }}</span> sản phẩm',
      'product_set.add_to_set': 'Thêm vào bộ sản phẩm',
      'product_set.items_needed.diff':
        'Đã chọn {{ currentQuantity }} sản phẩm, thêm <span class="highlight-label">{{ stillNeedQuantity }}</span> sản phẩm để nhận khuyến mãi từ bộ sản phẩm ',
      'product_set.confirm': 'Xác nhận',
      'product_set.items_selected':
        'Đã chọn <span class="highlight-label">{{ quantity }}</span> sản phẩm',
      'product_set.reach_limit': 'Đã đạt đến giới hạn mua hàng',
      'product_set.reach_limit.congrats':
        'Đã chọn <span class="highlight-label">{{ quantity }}</span> sản phẩm, chúc mừng bạn đã nhận được khuyến mãi từ bộ sản phẩm',
      'product_set.no_items_selected': 'Không có sản phẩm được chọn',
      'product_set.quick_cart.add_to_set': 'Thêm bộ sản phẩm',
      'product_set.exceed_limit':
        'Vượt quá giới hạn nhập hàng bộ sản phẩm, vui lòng điều chỉnh số lượng',
      'product_set.set_includes': 'Bộ sản phẩm bao gồm:',
      'product_set.quantity': 'Số lượng bộ sản phẩm',
      'product_set.sold_out': 'Không đủ hàng tồn kho',
      'product_set.please_select': 'Vui lòng chọn sản phẩm',
      'product_set.not_enough_stock.please_remove':
        'Thiếu hàng tồn kho, vui lòng loại bỏ sản phẩm này.',
      'product_set.not_enough_stock':
        'Thiếu hàng tồn kho, vui lòng điều chỉnh số lượng sản phẩm.',
    });
  },
]);
