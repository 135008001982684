app.directive('timeSlotOptions', [
  '$filter',
  function ($filter) {
    var componentTemplate =
      '<div class="form-group time-slots" ng-show="enabledTimeSlots">' +
      '<div class="time-slots-title">{{"tcat.time_slot.title" | translate}}</div>' +
      '<div class="select-cart-form">' +
      '<select class="form-control" name="order[delivery_data][time_slot_key]" required>' +
      '<option value="" ng-if="showDefaultOption">{{"dropdown.hint" | translate}}</option>' +
      '<option ng-repeat="item in timeSlotOptions" value="{{item.key}}">{{item.description}}</option>' +
      '</select>' +
      '</div>' +
      '</div>';

    return {
      restrict: 'E',
      replace: true,
      template: componentTemplate,
      scope: {
        timeSlots: '=',
        timeSlotsTranslations: '=',
      },
      link: function (scope) {
        scope.timeSlotOptions = [];

        if (
          scope.timeSlotsTranslations &&
          Object.keys(scope.timeSlotsTranslations[0]).length === 0
        ) {
          scope.enabledTimeSlots = false;
          return;
        }

        if (!_.isEmpty(scope.timeSlots)) {
          scope.enabledTimeSlots = true;
          scope.timeSlotOptions = scope.timeSlots.map(function (item) {
            return {
              key: item.key,
              description: $filter('translate')(item.description),
            };
          });
        }

        if (!_.isEmpty(scope.timeSlotsTranslations)) {
          scope.showDefaultOption = true;
          scope.enabledTimeSlots = true;
          scope.timeSlotOptions = scope.timeSlotsTranslations.map(function (
            item,
          ) {
            return {
              key: $filter('translateModel')(item),
              description: $filter('translateModel')(item),
            };
          });
        }
      },
    };
  },
]);
