app.directive("cartPage", [
  '$compile'
  'checkoutService'
  'cartService'
  'gaService'
  'Analytics'
  '$timeout'
  'trackerService'
  'slFeatureService'
  '$rootScope'
  '$filter'
  'cart'
  'slPixelService'
  'merchantService'
  'ordersService'
  'mainConfig'
  'hiidoTrackerService'
  'staticImageHost'
  'fbService'
  (
    $compile
    checkoutService
    cartService
    gaService
    Analytics
    $timeout
    trackerService
    slFeatureService
    $rootScope
    $filter
    cart
    slPixelService
    merchantService
    ordersService
    mainConfig
    hiidoTrackerService
    staticImageHost
    fbService
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) ->
        scope.errors = {
          fieldErrors: []
        }
        scope.validateItem = {
          data: [],
          message: ''
        }
        scope.state = {
          isCartLoading: false
        }
        scope.staticImageHost = staticImageHost;
        scope.clickFBLogin = (appId, version) ->
          fbService.fbLogin(appId, version).then((res) ->
            if res.authResponse
              window.location.reload();
          );

        if Analytics.configuration.enhancedEcommerce
          gaService.sendPageView()

        scope.isTrialPlan = merchantService.isTrialPlan();
        deviceCanUseApplePay = !!window.ApplePaySession

        element.on "click", ".btn-checkout", (event) ->
          if scope.isTrialPlan && !slFeatureService.hasFeature('trial_limit_whitelist')
            event.preventDefault()
            ordersService.allowTrialPlanOrders(mainConfig.merchantId).then((res) ->
              isAllowCheckout = res.data.allow_place_order
              if !isAllowCheckout || (isAllowCheckout && res.data.orders_counts == 0)
                # show trial plan popup
                $rootScope.currentModal = checkoutService.openTrialOrderLimitPopup(isAllowCheckout);
                $rootScope.currentModal.result.then(() ->
                  isAllowCheckout && window.location = '/checkout'
                )
              else
                window.location = '/checkout'
            )

          if ($('#order-payment-method').attr('attr-payment-method') == 'isNewWebApplePay' && !deviceCanUseApplePay)
            event.preventDefault()
            checkoutErrorDom = document.getElementById('checkout-errors')
            checkoutErrorDom.innerHTML = $filter('translate')('checkout.payments.apple_pay.not_supported')
            checkoutErrorDom.style.display = 'block'
            angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
            $('.btn-checkout').addClass('disabled')

          if scope.state.isCartLoading
            event.preventDefault()
          else if !cartService.isAllRedeemGift()
            trackerService.fbAddPaymentInfo($rootScope.currentCart.getItems(),  $rootScope.currentCart.getTotal())
            trackerService.track({
              type: trackerService.generalEventType.ADD_BILLING,
            });
            hiidoTrackerService.orderEdit.proceedToCheckout()
            if Analytics.configuration.enhancedEcommerce
              gaService.setUserId()
              Analytics.trackCheckout(2,"InitateCheckout")
              Analytics.trackEvent('UX', 'initate_checkout', 'InitateCheckout', undefined, true)

        reload = (keys) ->
          shouldReloadAll = keys is "all"
          keys = [] if not keys?

          element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').show() if shouldReloadAll or _.intersection(keys, ["items", "promotions"]).length > 0
          element.find('.shopping-cart .cart-share-btn').addClass('disable')
          scope.$broadcast("checkout.cart.items.reload") if shouldReloadAll or _.contains(keys, "items") or _.contains(keys, "promotions")
          scope.$broadcast("checkout.cart.promotions.reload") if shouldReloadAll or _.contains(keys, "promotions")

          if shouldReloadAll or _.contains(keys, "form")
            element.find(".order-form .loading-icon").show()
            scope.$broadcast("checkout.cart.form.reload")

          if shouldReloadAll or _.contains(keys, "summary")
            element.find(".order-summary .loading-icon").show()
            scope.$broadcast("checkout.cart.summary.reload")

        validateCart = (callback) ->
          checkoutService.requestValidate()
            .then ((res) -> scope.errors = res.data)
            .catch ((res) ->
              angular.element('html, body').animate({ scrollTop: angular.element("#checkout-container").offset().top }, 'slow')
              scope.errors = res.data
            )
            .finally () ->
              validateLocalData()
              setCartErrors()
              callback() if callback?

        validateLocalData = () ->
          scope.errors.fieldErrors = []
          angular.element(".form-group.has-error").removeClass("has-error")
          checkoutService.getFormFields(element.find("form"), scope)
            .each ($field) ->
              scope.errors.fieldErrors.push($field.attr('name')) if checkoutService.validateFormField($field)

        setCartErrors = () ->
          $el = element.find('#checkout-errors')
          errors = _.omit(scope.errors, 'fieldErrors')
          overLimitError = $filter('translate')('checkout.cart_over_limit_error')
          if _.keys(errors).length > 0 || (scope.validateItem && scope.validateItem.message)
            $el.show()
            errorArray = _.values(errors)
            if mainConfig.isExceedCartLimitation
              errorArray = errorArray.concat(overLimitError)
            if scope.validateItem
              errorArray = errorArray.concat(scope.validateItem.message)
            htmlContent = errorArray.join('<br>')
            $el.html(htmlContent)
          else if mainConfig.isExceedCartLimitation
            $el.show()
            $el.html(overLimitError)
          else
            $el.hide()

        setCartTips = (bool) ->
          # get error message form js
          tips = []
          tips.push $filter('translate')('product.addon_products.tips.limit_exceed') if bool
          $el = element.find('#checkout-tips')
          $el.html(_.values(tips).join("<br/>"))
          if _.isEmpty(tips)
            $el.hide()
          else
            $el.show()

        toggleCheckoutButton = () ->
          isShowing = scope.state.isCartItemInvalid || scope.state.isCartLoading || _.chain(scope.errors).reject((value) -> _.isEmpty(value)).values().some((isInvalid) -> isInvalid).value()
          element.find(".btn-checkout")[sprintf("%sClass", if isShowing then "add" else "remove")]("disabled")

        checkAddonSection = (cartItems) ->
          scope.show_cart_addon_section = _.some(cartItems, (item) -> item.type != 'redeem_gift')

        # Events

        scope.$watch "state.isCartLoading", (() -> toggleCheckoutButton())
        scope.$watch("errors", (() -> toggleCheckoutButton()), true)
        scope.$on "checkout.cart.items.changed", ($event, payload) ->
          reload("all")
          scope.validateItem = payload.cartValidateItem
          validateCart()
          scope.state.isCartItemInvalid = !payload.success

        scope.$on "checkout.cart.items.changing", (() ->
          element.find(".loading-icon").show()
          $('.shopping-cart button, .shopping-cart input').prop('disabled', true)
        )

        scope.$on "checkout.cart.empty", (() ->
          element.find(".shopping-cart-empty").removeClass("hide")
          element.find(".cart-content").hide()
        )
        scope.$on "checkout.cart.form.changed", ($event, options) ->
          validateCart () ->
            if options.rerender
              reload(["promotions", "summary"])
            else
              element.find(".order-summary .loading-icon").hide()
              element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').hide()
              element.find('.shopping-cart .cart-share-btn').removeClass('disable')

        scope.$on "checkout.cart.form.changing", (() ->
          element.find(".order-summary .loading-icon").show()
          element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').show()
        )

        scope.$on "checkout.cart.form.loaded", () ->
          element.find(".order-form .loading-icon").hide()
          $timeout((() ->
            validateLocalData()
            setCartErrors()
          ), 50)

        scope.$on "checkout.cart.content.loaded", () ->
          if element.find(".shopping-cart .checkout-section-loading").length is 0
            element.find('.shopping-cart .loading-icon, .cart-addon .loading-icon').hide()
            element.find('.shopping-cart .cart-share-btn').removeClass('disable')

          action = if element.find(".cart-promotions .promotion").length > 0 then "show" else "hide"
          element.find(".cart-promotions")[action]()

        scope.$on "checkout.cart.summary.loaded", (() -> element.find(".order-summary .loading-icon").hide())
        scope.$on "checkout.cart.summary.changed", (($event) -> reload(["promotions"]))
        scope.$on "checkout.cart.coupon.remove", (($event, code) -> element.find(".order-summary .loading-icon").show())

        scope.$on "checkout.cart.nothing.changed", (($event, options) ->
          element.find(".order-form .loading-icon").hide()
          element.find(".order-summary .loading-icon").hide()
          element.find(".cart-addon .loading-icon").hide()
          shouldShowTips = options.code != 404 # handle item not found case (no need show error, just not change anything)
          setCartTips(shouldShowTips)
        )

        scope.$on 'checkout.cart.item.updated', (() ->
          setCartTips(false)
        )

        scope.validateLocalData = validateLocalData
        scope.setCartErrors = setCartErrors

        if slFeatureService.hasFeature('cart_addon')
          scope.$on 'checkout.cart.item.removed', (($event, data) ->
            checkAddonSection(data.items)
          )
          checkAddonSection(cart.items)
          checkoutService
            .requestPartial('cart', 'addons')
            .then (res) -> element.find('.cart-addon-container').replaceWith($compile(res.data)(scope))

        sendSlpixelTracking = (cart) ->
          cartItems = []
          pushItem = (item) ->
            if(!_.isObject(item) || !_.isObject(cart) || item.type == 'custom_discount')
              return
            price = cartService.getItemPrice(item)
            cartItems.push {
              productID: item.product_id,
              type: item.type,
              name: $filter('translateModel')(item.product.title_translations),
              currency: price && price.currency_iso,
              price: price && price.dollars,
              quantity: item.quantity,
              variationID: item.variation_id
            }

          if (Array.isArray(cart.items))
            cart.items.forEach (item) ->
              pushItem(item)
              if (Array.isArray(item.addon_items))
                item.addon_items.forEach (addonItem) ->
                  pushItem(addonItem)

          slPixelService.hdPageView(
            'cart', { cartItems: cartItems }
          )

        sendSlpixelTracking(cart)

        # hiido tracking order edit pageview
        hiidoTrackerService.orderEdit.pageView()
        
        # track initiateCheckout event for user enter cart page via link
        trackerService.fbInitiateCheckoutForCartLink(
          $rootScope.currentCart.getItems(),
          $rootScope.currentCart.getSubtotal()
        )
    }
])
