app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('en', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'form.action.delete': 'Delete',
      'recaptcha.language': 'en',
      'paypal.language': 'en_US',

      'form.validation.required': '{{field_name}} is required',
      'form.validation.maxlength': '{{field_name}} is too long',
      'form.validation.maxlength.with.number':
        '{{field_name}} is up to {{max_length}} characters',
      'form.validation.minlength': '{{field_name}} is too short',
      'form.validation.invalid': '{{field_name}} is invalid',
      'form.validation.pattern': '{{field_name}} is invalid',
      'form.validation.pattern.recipient-name':
        'Not allow spaces and symbols, or more than {{limit_length}} characters.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} must input 10 digits numbers',
      'form.validation.pattern.recipient-phone.fix_size':
        'Recipient Contact Number must input {{ max_count }} digits numbers',
      'form.validation.pattern.recipient-phone.above_size':
        'Recipient contact number must input more than {{ max_count }} digits numbers',
      'form.validation.pattern.recipient-phone.beginning_stint':
        'Recipient Contact Number must start with {{ stint_charts.split(",").join(" or ") }}',
      'form.validation.email': '{{field_name}} is invalid',
      'form.validation.id_no':
        "Reminder: Without the recipient's identity id, the import declaration might be affected.",
      'form.validation.passport':
        "Reminder: Without the recipient's passpport number, the import declaration might be affected.",
      'form.validation.unique': '{{field_name}} is taken. Please choose again.',
      'form.validation.confirmation': 'Passwords do not match.',
      'form.validation.policy':
        'You must agree with the terms and conditions to proceed.',
      'form.validation.delivery_option.outlying':
        'The delivery type you have chosen is not available for outlying islands in Taiwan, please change your delivery address or choose another delivery method.',
      'form.validation.delivery_option.local':
        'The delivery type you have chosen is not available for islands in Taiwan, please change your delivery address to outlying islands or choose another delivery method.',
      'form.validation.delivery_option.country':
        'The delivery type you have chosen is not available for the country/region, please change your delivery address or choose another delivery method.',
      'form.validation.delivery_option.region':
        'The delivery type you have chosen is not available for the region, please change your delivery address or choose another delivery method.',
      'form.validation.delivery_option.cross_border.MY':
        'The delivery type you have chosen is not available for {{ area == "west" ? "West" : "East" }} Malaysia, please change your delivery address or choose another delivery method',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.response.load.more.error':
        'Unable to load the data, please try again.',
      'form.validation.email.duplicate':
        'Email is taken. Please enter another email.',
      'form.validation.email.hint': 'Please enter a valid email address.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',
      'form.validation.calling_code': 'Country calling code is required',
      'form.validation.phone.error': ' incorrect, please check again',
      'dropdown.hint': 'Please select',
      'redirect.go_home_in_seconds':
        'Return to Home page in {{seconds}} seconds',

      'trial_order_dialog.title':
        'Welcome to checkout. The store is on free trial with up to creating 10 orders.',
      'trial_order_dialog.content':
        'You may : become a member, subscribe newsletter, purchase add-on items and experience other powerful features. Order limitation will be removed once the shop get upgraded.',
      'trial_order_dialog.checkout': 'Start Checkout',
      'trial_order_dialog.reach_limit.title':
        'Order limit has been reached for trial operation.',
      'trial_order_dialog.reach_limit.content':
        'You can continue shopping and checkout when the store is officially in operation!<br/>If you have any questions about the operation of the store, please contact the store.',
      'trial_order_dialog.reach_limit.check_plan': 'Return to homepage',
      'trial_order_dialog.reach_limit.continue': 'Contact the store',

      'session.signup.title': 'Sign Up',
      'session.terms.facebook': 'Sign Up using Facebook',
      'session.signup.facebook': 'Facebook Login',
      'sessions.or': 'OR',
      'session.terms': 'By continuing, you agree to our terms and conditions.',
      'session.signin.submit': 'Sign In',
      'session.forgot_password': 'Forgot Password',
      'session.signin.title': 'Sign In',
      'session.signin.facebook': 'Facebook Login',
      'session.mobile_signup.check_mobile.title':
        'Please check your mobile number is correct as following, sms is sending to this mobile number',
      'session.mobile_signup.check_mobile.your_number': 'Your mobile number',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Please input Taiwan mobile number (start with 09)',
      'session.mobile_signup.check_mobile.send_code': 'Send me check code',
      'session.mobile_signup.check_mobile.go_back.1': 'you can',
      'session.mobile_signup.check_mobile.go_back.2': 'go back',
      'session.mobile_signup.check_mobile.go_back.3':
        'to correct mobile number if it is wrong',
      'session.mobile_signup.input_code.hint':
        'Please input check code you received',
      'session.mobile_signup.input_code.submit': 'Go',
      'session.mobile_signup.input_code.resend.countdown':
        'Resend check code sms to my mobile ({{resendCountdown}} sec)',
      'session.mobile_signup.input_code.resend':
        'Resend check code sms to my mobile',
      'session.forget_passowrd.check_phone.error':
        'Mobile number incorrect, please check again',
      'session.mobile_center.check_phone.error':
        'Mobile number is already taken',
      'user.sign_up.check_phone.error':
        'This is same as current mobile phone.<br>Please change another phone if you want to update it.',

      'user.quick_signup.title': 'Join member now!',
      'user.quick_signup.title.content':
        "Don't miss out on any exclusive offers!",
      'user.quick_signup.promotion':
        'I want to receive latest updates and promotions',
      'user.quick_signup.policy': 'I agree to the',
      'user.quick_signup.policy.url_content':
        " shop's Terms of Use and Privacy Policy",
      'user.quick_signup.submit': 'Join Now',
      'user.quick_signup.error.format': 'Format Error',
      'user.quick_signup.error.confirmed': 'This mobile no. is already taken. ',
      'user.quick_signup.error.confirmed.url': 'Sign in now.',
      'user.quick_signup.validate.title': 'Continue Signing Up',
      'user.quick_signup.validate.success':
        'Thanks for joining！ <br/> You can complete sign up now, <br/>or ',
      'user.quick_signup.validate.success.shop': 'go shopping now',
      'user.quick_signup.validate.finish_hint':
        'Finish signing up to check and apply store credits right away!',
      'user.quick_signup.validate.phone': 'Verify',
      'user.quick_signup.validate.send.sms': 'Send verification code',
      'user.quick_signup.validate.wrong.number':
        'If mobile no. is incorrect, please ',
      'user.quick_signup.validate.previous': 'go back',
      'user.quick_signup.validate.modify': ' and update',
      'user.quick_signup.validate.hint.password': 'At least 8 characters',
      'user.quick_signup.validate.hint.send':
        'Verification code is sent. You can resend a new one after {{count}}seconds.',
      'user.quick_signup.validate.hint.resend':
        'Have not receive the check code? Please click here to resend.',
      'user.quick_signup.validate.submit': 'Finish Sign Up',
      'user.quick_signup.validate.placeholder.sms': 'Enter verification code',
      'user.quick_signup.validate.placeholder.name': 'Name',
      'user.quick_signup.validate.placeholder.password': 'Enter password',
      'user.sign_up.email_verification.dialog.title':
        'Verification Email is sent to {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        'Please verify the email now and new email address will be updated. If you did not receive it, please input your email again and save.',
      'user.sign_up.email_verification.dialog.confirm': 'Confirm',
      'user.delivery_data.recipient_phone': 'Recipient Phone Number',

      'product.out_of_stock': 'Out of stock',
      'product.addon_products.label': 'Add-on Item',
      'product.bundled_products.label': 'Bundle',
      'product.bundle_group_products.label': 'A + B',
      'product.gift.label': 'Free Gift',
      'product.subscription_product.label': 'Subscription Product',
      'product.addon_products.errors.main_product_out_of_stock':
        "There isn't enough stock for main product",
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Limit {{ message }} per order.',
      'product.addon_products.errors.addon_product_out_of_stock':
        "There isn't enough stock for add-on item",
      'product.addon_products.errors.larger_addon_product':
        'Add-on item quantity cannot be greater than main product quantity',
      'product.addon_products.errors.out_of_stock':
        "There isn't enough stock for add-on {{title}}.",
      'product.addon_products.tips.limit_exceed':
        'When you adjust the quantity, quantity of add-on items can not be greater than that of main products.',
      'product.addon_products.add_to_cart.hint':
        'The item was successfully added to your cart',

      'product.available_time_info.start.1': 'Product available from ',
      'product.available_time_info.start.2': '',
      'product.available_time_info.end.1': 'Product will be unavailable on ',
      'product.available_time_info.end.2': '',

      'product.amazon_product_review.show_more': 'Show more',

      'product_review_comments.orders.back_to_my_account':
        '< Back to My Account',
      'product_review_comments.orders.back_to_the_store': 'Back to Store',
      'product_review_comments.orders.comment': 'Comment',
      'product_review_comments.orders.comment_placeholder':
        '(Optional) Satisfied with your purchase? Tell us your thoughts!',
      'product_review_comments.orders.completed_all_reviews':
        "Congratulations! You've completed all reviews!",
      'product_review_comments.orders.orders_to_view': 'Orders to review',
      'product_review_comments.orders.order_id': 'Order ID:',
      'product_review_comments.orders.purchased_date': 'Purchased Date:',
      'product_review_comments.orders.rate_other_order': 'Rate Other Order',
      'product_review_comments.orders.review': 'Review',
      'product_review_comments.orders.send': 'Send',
      'product_review_comments.orders.thanks_for_your_review':
        'Thanks for your review!',
      'product_review_comments.orders.max_length_of_characters':
        'Within 2000 characters',
      'product_review_comments.orders.write_a_review': 'Write a review',
      'product_review_comments.orders.upload_image': 'Upload image (maximum 9)',

      'product.product_review.out_of_5_stars': 'out of 5 stars',
      'product.product_review.rating_source_amazon': 'Rating Source: Amazon',
      'product.product_review.rating_source_shopline':
        'Rating Source: This Shop',
      'product.product_review.reviews': 'Reviews',
      'product.product_review.stars': 'Stars',
      'product.product_review.no_review': 'No review for this product',
      'product_review_entry.review_description_title':
        'We are waiting for your review!',
      'product_review_entry.review_description_content':
        'Satisfied with your purchase? Tell us your thoughts!',
      'product_review_entry.review_button_text': 'Write a review',

      'product.view_more': 'Show more',
      'product.view_less': 'Hide content',

      'product.add_to_cart': 'ADD TO CART',
      'product.add_to_cart_fail': 'Your cart is full!',
      'product.buy_now': 'BUY NOW',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'product.coming_soon': 'COMING SOON',
      'product.available_time_over': 'AVAILABLE TIME OVER',

      'member.center.load.more': 'More',
      'member.center.load.more.error':
        'Unable to load the data, please try again.',

      'wishlist.signin_register': 'Sign in/up now',
      'wishlist.messages.remove_error': 'Unable to remove wishlist item.',
      'back_in_stock.subscibe.success.message':
        'When product is back in stock, there will be email notification.',
      'back_in_stock.added': 'In Stock Notification On',
      'back_in_stock.avaiable': 'Notify When in Stock',

      orders: 'Orders',
      order: 'Order',
      'orders.fields.billing_address': 'Billing Address',
      'orders.fields.order_number': 'Order No.',
      'orders.fields.order_date': 'Date',
      'orders.fields.order_status': 'Status',
      'orders.fields.order_remarks': 'Order Remarks',
      'orders.fields.customer_name': 'Customer Name',
      'orders.fields.product_subscription_period': '{{period}}th',
      'orders.fields.status.temp': 'Pending',
      'orders.fields.status.pending': 'Pending',
      'orders.fields.status.confirmed': 'Confirmed',
      'orders.fields.status.shipped': 'Shipped',
      'orders.fields.status.completed': 'Completed',
      'orders.fields.status.received': 'Received',
      'orders.fields.status.cancelled': 'Cancelled',
      'orders.fields.status.returning': 'Returning',
      'orders.fields.status.returned': 'Returned',
      'orders.fields.preorder_item': 'Preorder Item',
      'orders.fields.customer': 'Customer',
      'orders.fields.customer.phone': 'Customer phone number',
      'orders.fields.billto': 'Bill to',
      'orders.fields.payment_type': 'Payment Type',
      'orders.fields.payment_instructions': 'Payment Instructions',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'Failed transaction: Maximum order amount for CVS Payment is NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'Failed transaction: Lowest payment amount for CVS Payment is NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'Failed transaction: Maximum order amount for Barcode Payment is NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Failed transaction: Lowest order amount for Barcode Payment is NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'The upper limit per transaction using CVS is NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'The upper limit per transaction using Barcode is NTD20,000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'The upper limit per transaction using WebATM is NTD30,000',
      'orders.fields.delivery_method': 'Delivery Method',
      'orders.fields.delivery_description': 'Description',
      'orders.fields.delivery_fee': 'Delivery Fee',
      'orders.fields.delivery_address': 'Delivery Address',
      'orders.fields.delivery_address.remarks': 'Delivery Remarks',
      'orders.fields.accept_terms.validation.required':
        'You must agree with the terms and conditions to proceed',
      'orders.fields.options.blacklist.error':
        'Your products come with different delivery or payment methods. Please choose other valid methods or place orders accordingly.',
      'orders.show.message.thankyou.title': 'Thank you for order',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': 'Continue shopping now!',

      'orders.show.message.shipped.title': 'Your Item has been shipped',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': 'Order Amount',
      'orders.payment_slip.order_info.instructions': 'Payment Instructions',
      'orders.payment_slip.order_info.read_more': 'View Full Instructions',
      'orders.payment_slip.upload_form.title':
        'After completion of transfer, please upload payment transfer details',
      'orders.payment_slip.upload_form.hint':
        'After confirming the payment, we will arrange shipment as soon as possible!',
      'orders.payment_slip.upload_form.notification':
        'To get latest order notification, please subscribe',
      'orders.payment_slip.upload_form.image_input.title':
        'Upload Transfer Details',
      'orders.payment_slip.upload_form.image_input.description':
        'Please confirm that the image is clear, and includes last 5 digits of the transaction number, transfer time and amount.',
      'orders.payment_slip.upload_form.image_input.change':
        'Click to change image',
      'orders.payment_slip.upload_form.image_input.error':
        'Image size must be smaller than 10MB',
      'orders.payment_slip.upload_form.paid_time': 'Choose Payment Time',
      'orders.payment_slip.upload_form.message': 'Fill in your Payment Remarks',
      'orders.payment_slip.upload_form.message.error':
        'Cannot exceed {{ n }} characters',
      'orders.payment_slip.uploaded.alert':
        'Thank you for uploading payment transfer details, we will confirm as soon as possible!',
      'orders.payment_slip.uploaded.notification':
        'Subscribe to get the latest order information',
      'orders.payment_slip.uploaded.title':
        'Here below is your transfer details',
      'orders.payment_slip.uploaded.paid_time': 'Payment Time:',
      'orders.payment_slip.uploaded.upload_time': 'Update Proof Time:',
      'orders.payment_slip.uploaded.order_link': 'View Order',
      'orders.payment_slip.uploaded_without_login.title':
        'Thank you! Payment transfer details have been uploaded for this order',
      'orders.payment_slip.uploaded_without_login.hint':
        'Please login to view details',
      'orders.payment_slip.footer':
        'The payment information you have filled in is only for order checking and it is safely encrypted. You can complete your payment with ease. ',

      // customer cancel order section
      'orders.show.check_order.title': 'Check order',
      'orders.show.check_order.description':
        'You can view or cancel the order in the <a href="{{pagePath}}">Member Center> Orders</a>',
      'orders.show.customer_cancel_order.confirm.title':
        'Are you sure you want to cancel?',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        'Sorry to see you cancel. Please tell us the reason, and we will try to make your next shopping experience better! Refund will be made after cancellation.',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        'Sorry to see you cancel. Please tell us the reason, and we will try to make your next shopping experience better! It may take a few days to process the refund, please wait patiently.',
      'orders.show.customer_cancel_order.confirm.loading.title': 'Processing',
      'orders.show.customer_cancel_order.confirm.loading.description':
        "Please wait for a moment. Don't leave or return to the previous page.",
      'orders.show.customer_cancel_order.confirm.success.title':
        'Cancellation request is send',
      'orders.show.customer_cancel_order.confirm.success.description':
        'Email notification will be sent after successful cancellation',
      'orders.show.customer_cancel_order.confirm.failed.title':
        'Oops! Cancellation had failed.',
      'orders.show.customer_cancel_order.confirm.failed.description':
        'If the retry fails, please contact us at "Shop and Customer Comments" below.',
      'orders.show.customer_cancel_order.button.cancel': 'Cancel Order',
      'orders.show.customer_cancel_order.button.ok': 'OK',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default':
        'Please select a reason for cancellation',
      'orders.show.customer_cancel_order.reasons.change_mind':
        'Change your mind',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        'The price is more expensive than elsewhere',
      'orders.show.customer_cancel_order.reasons.repeat_purchase':
        'Repeat purchase',
      'orders.show.customer_cancel_order.reasons.wait_too_long':
        'Wait too long for shipment',
      'orders.show.customer_cancel_order.reasons.change_item':
        'Want to change the purchase item',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        'Entered incorrect delivery or payment information',
      'orders.show.customer_cancel_order.reasons.other': 'Other',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        'Please tell us the reason.',
      'orders.show.customer_cancel_order.reasons.hint':
        'Reason for cancellation',

      'orders.index.no_records.title': 'You do not have any orders.',
      'orders.index.no_records.description':
        'Looks like you have never ordered from this shop.',
      'orders.index.no_records.action': 'Go Shopping',
      'orders.index.title': 'Orders',
      'orders.index.action.view': 'View',
      'address.fields.address.recipient_name': 'Recipient Name',
      'address.fields.address': 'Address',
      'address.fields.city': 'City',
      'address.fields.postcode': 'Postcode',
      'address.fields.country': 'Country/Region',

      'orders.fields.subtotal': 'Subtotal',
      'orders.fields.total': 'Total',
      'order.billing_address.same': 'Same as shipping address',

      'orders.coupons.label': 'Coupon Code',
      'orders.coupons': 'Congrats! You are entitled to the following discount:',
      'orders.coupons.placeholder': 'Enter Coupon Code',
      'orders.promotion_coupons.placeholder': 'Enter Coupon Code',

      'orders.coupons.apply': 'Apply Coupon',
      'orders.fields.order_discount': 'Discount applied',
      'orders.fields.order_custom_discount': 'Discount (custom)',
      'orders.coupons.invalid': 'Sorry, your coupon is invalid.',
      'orders.coupons.validation.minamount':
        'Sorry, your order does not meet the required value for this coupon',
      'orders.coupons.invalid.order':
        'Please remove invalid coupon codes before placing your order.',

      'orders.actions.title': 'Manage Order Status',
      'orders.action.cancel': 'Cancel Order',
      'orders.action.confirm': 'Confirm Order',
      'orders.action.ship': 'Mark as Shipped',
      'orders.general.title': 'Order details',
      'orders.payment.title': 'Payment details',
      'orders.delivery.title': 'Delivery details',
      'orders.products.title': 'Product Details',

      'orders.fields.accept_terms':
        "You agree to the Shop's Terms of use and Privacy Policy",
      'orders.action.checkout': 'Place Order',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Recipient Name',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11 Shop Name',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11 Shop Code',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Recipient Name',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Family Mart Shop Name',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Family Mart Shop Code',
      'orders.fields.delivery_data.tw_ezship.location_code.hint':
        'Find a store',
      'orders.fields.delivery_data.tw_ezship.location_code.label': 'Store Code',
      'orders.fields.delivery_data.tw_ezship.location_name.label': 'Store Name',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Recipient Name',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'Reference ID',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        'Family Mart',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label': 'Store',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        'Something went wrong with this delivery option, please refresh the page or <a href="/cart" class="alert-highlight">go back to the previous page to select another delivery option.</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        'Something went wrong with the address, please select again.',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        'Shop Code is required.',

      'lock_inventory.locked_quantity_unit': 'piece(s)',

      // Checkout page error message field name
      'order.customer_name': 'Customer name',
      'order.email': 'Email address',
      'order.customer_email': 'Customer email address',
      'order.customer_phone': 'Customer contact number',
      'order.delivery_data.recipient_name': 'Recipient name',
      'order.delivery_data.recipient_phone': 'Recipient Contact Number',
      'order.delivery_data.consignee_id_no':
        'Recipient ID Number or Passport Number',
      'order.delivery_address.address_1': 'Address',
      'order.delivery_address.city': 'City',
      'order.delivery_address.postcode': 'Postcode',
      'order.delivery_address.state': 'District/State/Province',
      'order.delivery_data.location_code': 'Shop Code',
      'order.delivery_data.location_name': 'Shop Name',
      'order.delivery_data.time_slot': 'Delivery Time',
      'order.delivery_data.scheduled_delivery_date': 'Arrival Date',
      'order.delivery_data.time_slot_key': 'Arrival Time Slot',
      'order.invoice.carrier_number': 'Carrier',
      'order.invoice.mailing_address': 'Address',
      'order.invoice.tax_id': 'Tax ID',
      'order.payment_data.holdername': 'CardHolder Full Name',
      'order.payment_data.expiry_date': 'Expiry Date',
      'order.payment_data.cvc': 'CVC',
      'order.payment_data.credit_card_number': 'Credit Card Number',
      'order.billing_address.title': 'Billing Address',
      'delivery.sfexpress.district': 'SF Express District',
      'delivery.sfexpress.store': 'SF Express Location',
      'delivery_address.region.hk': 'Region',
      'delivery_address.district.hk': 'District',
      'delivery_address.region.tw': 'State/City',
      'delivery_address.district.tw': 'District',
      'save_fields.customer_info.birthday': 'Customer birthday',

      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'Province',
      'delivery_address.district.th': 'District',
      'delivery_address.ward.th': 'Sub-district',
      'delivery_address.street': 'Road/Street',
      'delivery_address.store': '{{ station_name }} Store',

      'delivery_options.fields.delivery_type': 'Delivery Type',
      'delivery_options.fields.delivery_types.pickup': 'Pickup',
      'delivery_options.fields.delivery_types.local': 'Local',
      'delivery_options.fields.delivery_types.email': 'Email',
      'delivery_options.fields.delivery_types.international': 'International',
      'delivery_options.fields.delivery_types.custom': 'Custom',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11 Pickup (TW only)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Family Mart Pickup (TW only)',
      'delivery_options.fields.select_date': 'Arrival Date',

      'delivery_fee.pay.on.delivery':
        'You will be charged for shipping when the goods arrive.',

      'ezship.error.invalid_input':
        'One ore more field(s) is/are invalid or missing',
      'ezship.error.account_not_exist':
        'ezShip account does not exist. Please contact merchant',
      'ezship.error.no_permission':
        'ezShip account has insufficient permission',
      'ezship.error.no_easybag':
        'ezShip account has no Easybag or Minibag available',
      'ezship.error.invalid_store': 'Invalid Pickup Store',
      'ezship.error.invalid_amount':
        'Invalid amount. Max. allowed amount using ezShip is TWD 6000 per order.',
      'ezship.error.invalid_email': 'Invalid email',
      'ezship.error.invalid_mobile': 'Invalid phone number',
      'ezship.error.invalid_name': 'Invalid recipient name',
      'ezship.error.system_error':
        'ezShip system error. Please choose another delivery method or try again later.',

      'payments.fields.types.paypal': 'Credit card or Paypal',
      'payments.fields.types.paypal_express': 'Credit card or Paypal',
      'payments.fields.types.credit_card': 'Credit card',
      'payments.fields.types.bank_transfer': 'Bank Transfer',
      'payments.fields.types.cash_on_delivery': 'Cash on Delivery',
      'payments.fields.types.free_checkout': 'Free Checkout',
      'payments.fields.types.custom': 'Custom',
      'payments.fields.types.allpay_barcode': 'Barcode via Allpay',
      'payments.fields.types.allpay_credit': 'Credit Card via Allpay',
      'payments.fields.types.allpay_cvs': 'CVS via Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM via Allpay',
      'payments.fields.types.asiapay': 'Credit Card via Asiapay',
      'payments.fields.types.prizm': 'Credit Visa / Master',
      'payments.fields.types.esun': 'Esun Credit Card',

      'payment.cc.expirydate': 'Expiry Date',
      'payment.cc.holdername': 'Cardholder Name',
      'payment.cc.number': 'Credit Card Number (Visa / Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'MM/YY',
      'payment.cc.holdername.placeholder': 'Cardholder Name',
      'payment.cc.holdername.hint': 'Same as the full name on card',
      'payment.cc.number.placeholder': 'Credit Card Number (no spaces)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint':
        '3-digit security code usually found on the back of your card.',
      'payment.action.confirm': 'Confirm',
      'payment.cc.update.instructions':
        'Please input your credit card below to update',
      'payment.cc.fields.accept_terms':
        'I agree to the Terms of use and Privacy Policy',
      'payment.cc.fields.accept_terms.validation.required':
        'You must agree with the Terms and Conditions to proceed',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship': 'Pay on Store Pickup via ezShip',

      'cart.title': 'Shopping Cart',
      'cart.clean': 'Checkout now!',
      'cart.item.remove': 'Remove',
      'cart.items.remove': 'Remove',
      'cart.checkout': 'Checkout',
      'cart.over_limit':
        'The shopping cart exceeds the maximum number of <span class="limit-number">{{limit_number}}</span> items, please checkout before adding new items. You may also log in to add these items to your wishlist!',
      'cart.over_limit.login':
        'The shopping cart exceeds the maximum number of <span class="limit-number">{{limit_number}}</span> items, please checkout before adding new items. You may also add this item to your wishlist!',
      'cart.empty': 'Your Shopping Cart is empty.',
      'cart.empty.description': 'Add products to cart to continue.',
      'cart.empty.continue': 'Continue shopping now!',
      'cart.promotion.checkout': 'Checkout',
      'cart.promotion.has_items_selected': '<b>{{quantity}}</b> items selected',
      'cart.promotion.bundle_group.has_items_selected':
        'Group A <b style="color: #f05c53">{{red_quantity}}</b> items selected{{red_reminder}}; Group B <b style="color: #1ba462">{{green_quantity}}</b> items selected{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'Group A <b style="color: #f05c53">{{red_quantity}}</b> items; Group B <b style="color: #1ba462">{{green_quantity}}</b> items',

      'cart.redeem_gift.has_items_selected':
        '<span>{{total_point}}</span> points used, <span>{{rest_point}}</span> points left',
      'cart.redeem_gift.has_items_selected.insufficient_point':
        'Exceed <span>{{rest_point}}</span> points, please remove some gifts.',
      'cart.copy.info.copied': 'Website address copied:',
      'cart.copy.info.affiliate_code': 'Affiliate code ({{code}}), ',
      'cart.copy.info.product_count': '{{count}} products',

      'cart.promotion_reminder.separator': '. ',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">Group A </span> {{gap}} more to go',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">Group B </span> {{gap}} more to go',
      'cart.promotion_reminder.group_gap': ' ({{gap}} more to go)',
      'cart.promotion_reminder.item': 'Buy {{gap}} more ',
      'cart.promotion_reminder.amount': 'Buy {{gap}} more ',
      'cart.promotion_reminder.multiple_step': 'to save more!',
      'cart.promotion_reminder.stackable': 'Buy more to save more!',
      'cart.promotion_reminder.applied.free_shipping': 'Enjoy free shipping. ',
      'cart.promotion_reminder.applied.gift': 'Enjoy free gift. ',
      'cart.promotion_reminder.applied.discount_prefix': '. ',
      'cart.promotion_reminder.applied.discount': 'Enjoy the offer, ',
      'cart.promotion_reminder.applied_highest.free_shipping':
        'Enjoy free shipping!',
      'cart.promotion_reminder.applied_highest.gift': 'Enjoy free gift!',
      'cart.promotion_reminder.applied_highest.discount': 'Enjoy the offer!',
      'cart.promotion_reminder.discount_target.free_shipping':
        'to enjoy free shipping!',
      'cart.promotion_reminder.discount_target.gift': 'to get free gift!',
      'cart.promotion_reminder.discount_target.discount': 'to get the offer!',

      'cart.share.btn.tooltip': 'Copy shopping cart items to friends',
      'checkout.instructions': 'Order Details',
      'checkout.fields.email': 'Customer Email',
      'checkout.fields.email.hint':
        'Please fill in your correct email address as the order confirmation will be sent to your email.',
      'checkout.fields.phone.hint':
        'Please fill in your mobile phone number to receive delivery status update by SMS',
      'checkout.fields.phone.invalid': 'Invalid phone number',
      'checkout.fields.email.placeholder': 'Email Address',
      'checkout.fields.remarks': 'Remarks for Shop',
      'checkout.fields.remarks.placeholder': 'Leave order remarks for the Shop',
      'checkout.fields.delivery_method': 'Delivery Method',
      'checkout.fields.out_of_stock':
        'Sorry, there is not enough stock, please remove it from cart and checkout again.',
      'checkout.fields.phone': 'Contact phone number',
      'checkout.fields.phone.placeholder': 'Enter your contact phone number',
      'checkout.fields.add_new': 'Add new...',
      'checkout.fields.select': 'Select one...',

      'checkout.delivery_option.title': 'Delivery Address',
      'checkout.instalment_amount':
        'approx. {{amount}} x {{period}} instalments',
      'checkout.labels.promotion.free_shipping': '免運',
      'checkout.labels.promotion.coupon': '優惠券',
      'checkout.labels.promotion.promotion': '優惠促銷',
      'checkout.labels.promotion.membership': '會員優惠',

      'checkout.payment_method.title': 'Payment',

      'checkout.discount.applied_promotions': 'Applied Promotions',
      'checkout.discount.applied_coupons': 'Applied Coupons',

      'checkout.promotion.tags.promotion': 'Promotion',
      'checkout.promotion.tags.coupon': 'Coupon',
      'checkout.promotion.tags.membership': 'Member Offer',
      'checkout.promotion.tags.free_shipping': 'Free Shipping',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',

      'checkout.payments.apple_pay.not_supported':
        'The device does not support Apple Pay, please check the following<ul><li>Please LOG IN to the browser with your Apple Account</li>' +
        '<li>Please ADD the payment method to your Apple Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'The device does not support Google Pay, please check the following<ul><li>Please LOG IN to the browser with your Google Account</li>' +
        '<li>Please ADD the payment method to your Google Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payment_failed': 'This transaction failed, please try again.',
      'checkout.cart_over_limit_error':
        'The maximum number of items is 100, please adjust the quantity and purchase again',
      'checkout.timeout':
        'There is an issue with your payment. If you place an order again, there may be a repeat order. Please contact us to check on the payment status.',
      'checkout.payment_general_error':
        'Error processing order via this payment method. Please try other payment method, thank you.',
      'checkout.card_info_error':
        'Please verify the information and try again.',
      'checkout.station_space_confirm_error':
        'Failed to select pickup store, please try again or contact us',
      'checkout.payments_transaction_error':
        'There is some issue with your order. Please check the payment information again or contact your card’s issuing bank directly.',
      'checkout.card_type_not_support_error':
        'The card type is not supported at this moment',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': 'Recipient Name',
      'address.fields.address.city': 'City',
      'address.fields.address.state': 'District/ State/ Province',
      'address.fields.address.postcode': 'Postcode (if applicable)',
      'address.fields.address.country': 'Country/Region',
      'address.fields.address.remarks': 'Remarks',

      'messages.form.send': 'Send',
      'action.add_photo': 'Add Photo',
      'action.send': 'Send',
      'action.load_earlier': 'Load Earlier',
      'action.load_more': 'Load More',

      'users.fields.name': 'Full Name',
      'users.fields.email': 'Email',
      'users.fields.mobile_phone_or_email': 'Email or mobile number',
      'users.fields.password': 'Password',
      'users.fields.name.placeholder': 'Full Name',
      'users.fields.email.placeholder': 'Email',
      'users.fields.password.placeholder': 'Password',
      'users.fields.callingCode': 'Calling Code',
      'users.fields.callingCodeHint': 'Please select country calling code',
      'users.save.success': 'Your changes have been saved.',
      'users.save.email_verification.success':
        'Your changes have been saved. Email address will be updated after verification is completed.',
      'users.save.email_verification.send': 'Verification Email Sent',
      'users.add.phone': 'Add new number',
      'users.add.phone.maximum': 'You may only add up to 5 phone numbers.',
      'users.error.phone.format': 'Phone Number is invalid',
      'users.error.phone.required': 'Phone Number is required',
      'users.add.delivery_address': 'Add new address',
      'users.add.delivery_address.maximum':
        'You may only add up to 5 addresses.',
      'users.error.no_changes': 'There are no changes to be saved.',
      'users.error.maxlength': ' is too long.',
      'users.signup.male': 'Male',
      'users.signup.female': 'Female',
      'users.signup.other': 'Undisclosed',
      'users.signup.email': 'Sign up via Email',
      'users.signup.mobile_phone': 'Sign up via mobile number',
      'users.signup.unconfirmed':
        'Welcome back! In order to protect your account, verification is needed to log in to your account. Verification email has already been sent to {{email}}. Please check it in your mailbox, thank you!',
      'users.signup.verification.hint.send':
        'Verification email is sent. You can resend a new one after {{count}}seconds.',
      'users.einvoice_carrier': 'Sum up E-invoice into your account',
      'users.phone.migrate.notification':
        'To update shop functions, only one of customer’s phone number will be kept from 2019/1/15 onwards. If you have saved more than one phone number, please confirm the latest one is correct.',
      'users.edit_member_info': 'Edit Member Profile',
      'users.member.normal-level': 'Basic',
      'users.membership.expiry_date': 'Membership Expiry Date',
      'users.membership.expire.unlimited': 'Never Expire',
      'users.membership.expire.month_valid':
        '（Valid Period {{months}} Month(s))',
      'users.membership.tier.expire.date.description':
        'Upgrade condition will be checked during midnight. Membership upgrade will not be effective if order has been cancelled/refunded.',
      'users.membership.offer.discount': 'Membership Offer',
      'users.membership.upgrade.next_tier': 'Upgrade to {{tier_name}}',
      'users.membership.upgrade.next_tier.discount': ', get {{discount}}% off',
      'users.membership.extension.condition': 'Extension Condition',
      'users.membership.max_level_tier':
        'Congrats! You are in TOP membership level.',
      'users.membership.spending_within_months':
        'Upgrade with total purchase over {{spending}} within {{months}} month(s).',
      'users.membership.spending_single_purchase':
        'Upgrade with single purchase over {{spending}}.',
      'users.membership.all.spending_within_months':
        'Total purchase over {{spending}} within {{months}} month(s).',
      'users.membership.all.spending_single_purchase':
        'Single purchase over {{spending}}.',
      'users.membership.extend_valid_period':
        '{{ type === "within_interval" ? "Total" : "Single" }} purchase over {{spending}} within membership valid period {{months}} months.',
      'users.membership.all_tiers': 'View All Membership',
      'users.membership.all_tiers.condition': 'Membership',
      'users.membership.all_tiers.description':
        'Membership will downgrade on expiry date if extension condition is not meet.',
      'users.membership.tier.shopping_discount':
        'Member offer {{discount}}% off',
      'users.membership.tier.upgrade.condition':
        'Upgrade Condition of this level :',
      'users.membership.tier.upgrade.condition.dollar':
        'No single purchase over {{dollar}} currently.',
      'users.membership.tier.upgrade.condition.continue': 'Continue Shopping!',
      'users.membership.tier.upgrade.to_next_level.single':
        'Upgrade to next level ?',
      'users.membership.tier.upgrade.to_next_level.within':
        'Total purchase within {{month}} month(s) ',
      'users.membership.tier.upgrade.completed':
        'Congrats! {{ type === "within_interval" ? "" : "Membership" }} upgrade will be effective on midnight.',
      'users.membership.tier.upgrade.gap_dollar': '{{dollar}} more to upgrade',
      'users.membership.tier.expire.date': 'Valid Period :',
      'users.membership.tier.extend.condition': ' Extension Condition :',
      'users.membership.tier.expire.month': '{{month}} Month(s)',

      'membership.profile.subscriptions.orders.update.status':
        'Updates to my orders',
      'membership.profile.subscriptions.orders.comments.new':
        'New comments from the merchant on my orders',
      'membership.profile.subscriptions.messages.new':
        'New messages from the merchant',
      'membership.profile.subscriptions.marketing.news':
        'Store updates and promotions',
      'membership.profile.subscriptions.none': 'None',
      'membership.profile.mobile.number.verified':
        'Mobile number verified and it will replace phone numbers you saved before.',
      'membership.profile.check.code.error':
        'Check code incorrect! Please re-enter it or resend check code sms',
      'membership.profile.verification_hint':
        'If you change email address, verification is required after save. Email will be updated to {{unconfirmed_email}} after verification is completed.',

      'member_points.title': 'Member Points',
      'member_points.unit': 'Point',
      'member_points.point_balance': 'Point Balance',
      'member_points.point_history': 'Point History',
      'member_points.fields.date': 'Date',
      'member_points.rule': 'Points Rules',
      'member_points.display.expire_point': 'points will expire on',
      'member_points.display.successive_expire_point':
        'points will expire in succession after',
      'member_points.fields.expire': '',
      'member_points.fields.successive_expire': '',
      'member_points.fields.remarks': 'Point Change Reason',
      'member_points.fields.value': 'Point Movement',
      'member_points.fields.expiry_date': 'Expiry Date',
      'member_points.fields.member_point_expired': 'Point Expired',
      'member_points.fields.auto_reward': 'Points rewarded from order',
      'member_points.fields.auto_reward_seconds': '',
      'member_points.no_data': 'No point records',
      'member_points.redeem_cash_and_earn_from_order_description':
        'Every {{costMoney}} Earn {{earnPoint}} Points. Every {{costPoint}} Points Discount {{discountMoney}}.',
      'member_points.redeem_cash_description':
        'Every {{costPoint}} Points Discount {{discountMoney}}.',
      'member_points.earn_from_order_description':
        'Every {{costMoney}} Earn {{earnPoint}} Points.',
      'member_points.equal_cash': 'Equal to {{money}}',

      'coupon.title': 'Coupon',
      'coupon.valid': 'Valid',
      'coupon.invalid': 'Invalid',
      'coupon.valid_until':
        'Ends on <span class="coupon-period-time"><div class="coupon-period-tip">Display in your time zone<br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': 'One-Time',
      'coupon.first_shoppers': 'First {{number}} Shoppers Only',
      'coupon.reach_limit': 'Reach limit',
      'coupon.expired': 'Expired',
      'coupon.no_valid_coupon': 'No Valid Coupon',
      'coupon.no_invalid_coupon': 'No Invalid Coupon',
      'coupon.select_coupon': 'Select Coupon',
      'coupon.confirm': 'Confirm',
      'coupon.first_purchase_only': '1st Order',

      'store_credits.title': 'Store Credits',
      'store_credits.credit_balance': 'Credit Balance',
      'store_credits.credit_history': 'Credit History',
      'store_credits.fields.date': 'Date',
      'store_credits.fields.remarks': 'Credit Change Reason',
      'store_credits.fields.value': 'Credit Movement',
      'store_credits.fields.expiry_date': 'Expiry Date',
      'store_credits.fields.balance': 'Credit Balance',
      'store_credits.fields.order_prefix': 'Use of credits in order',
      'store_credits.fields.welcome_credit': 'Welcome Credit',
      'store_credits.fields.member_referral_credit':
        'Refer new member to sign up',
      'store_credits.fields.welcome_member_referral_credit':
        'Sign up with referral link',
      'store_credits.fields.birthday_credit': 'Birthday Credit',
      'store_credits.fields.user_credit_expired': 'Credit Expired',
      'store_credits.fields.never_expires': 'Never Expires',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward':
        'Credits rewarded based on minimum order purchase in order',
      'store_credits.fields.revert_credit':
        'Credits reverted from cancelled order',
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.no_data': 'No credit records',
      'store_credits.bulk_imported': 'Manually added',

      'tax.sales_tax': 'Sales Tax',
      'tax.sales_tax_rate': 'Sales Tax Rate',
      'tax.delivery_tax': 'Delivery Tax',
      'tax.delivery_tax_rate': 'Delivery Tax Rate',
      'tax.calculate_tax': 'Calculate Tax',
      'tax.calculate_tax.info.1': 'Please update tax and click',
      'tax.calculate_tax.info.2': 'before checkout',
      'tax.calculation_title': 'Tax Calculation：',
      'tax.calculation_content':
        '(Total of taxed products - Discounts) * Tax + Delivery fee * Tax',
      'tax.price_updated': 'Price updated',
      'tax.total': 'Total',
      'tax.fee': 'Tax fee',

      'dynamic.delivery.fee.no.state.notice':
        'The shipping rate will be displayed on the checkout page after filling in the shipping address.',
      'dynamic.delivery.fee.no.state.info':
        'The shipping rate will be displayed after confirming the shipping address',
      'dynamic.delivery.fee.calculate.title':
        'Shipping rate calculation method',
      'dynamic.delivery.fee.calculate.desc':
        'Shipping rate for the first 1 kg + (total weight-1 kg) * shipping rate for additional weight',
      'dynamic.delivery.fee.calculate.hint':
        'If the total weight is less than 1 kg, it will be calculated as 1 kg',
      'dynamic.delivery.fee.updated.notice': 'Shipping rate has been updated',
      'dynamic.delivery.fee.calculate.info':
        'Total delivery fee: {{totalDeliveryFee}}',

      'error.404.title': 'We are sorry',
      'error.404.description': "This page doesn't exists",
      'error.401.title': 'We are sorry',
      'error.401.description': 'You are not authorized to access this page',
      'error.403.title': 'We are sorry',
      'error.403.description': 'You are not authorized to access this page',
      'error.403.not_signed_in.message': ", it's possibly because you haven't ",
      'error.403.not_signed_in.anchor': 'login',
      'error.continue': 'Continue shopping now!',

      'orders.comments.title': 'Shop and customer comments',
      'orders.comments.text.name': 'Comment',
      'orders.comments.text.placeholder': 'Enter a comment for this order',

      'orders.fields.payment_fee': 'Payment Fee',

      'orders.invoices.title': 'Invoice',
      'orders.invoices.carrier': 'General Carrier',
      'orders.invoices.carrier_type.member': 'Member',
      'orders.invoices.carrier_type.mobile_barcode': 'Mobile Barcode',
      'orders.invoices.carrier_type.npc_barcode': 'NPC Barcode',
      'orders.invoices.get_invoice': 'I need receipt',

      'orders.notifications.title': 'Order Update Notifications',

      'order.payments.login.confirm': 'Please log in and go to pay again!',

      'model.credit_card.title': 'Your Credit Card Information',
      'model.credit_card.subtitle':
        'Please input your credit card below to update',

      'sms_messenger_checkbox.subscribe': 'Send via SMS',

      'facebook_messenger_checkbox.subscribed': 'Sent via',
      'facebook_messenger_checkbox.turn_off': 'Turn off',
      'facebook_messenger_checkbox.v2.subscribe': 'Send to Messenger',
      'facebook_messenger_checkbox.v2.subscribed': 'Send via Facebook',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'order update notifications',
      'facebook_messenger_checkbox.topics.MARKETING':
        'shop updates and promotions',

      'line_messenger_checkbox.subscribe': 'Send to LINE',
      'line_messenger_checkbox.subscribed': 'Sent via LINE',
      'line_messenger_checkbox.order_notification.subscribed':
        'Sent this order update notifications via',
      'line_messenger_checkbox.button.unsubscribe': 'Turn off',

      'media.validation.file_limit.one': 'You may only upload up to 1 files',
      'media.validation.type': 'only jpeg and png formats are allowed',
      'media.validation.error.file_size':
        'Please upload the image less than 5MB.',
      'media.validation.error.file_attachment_size':
        'Please upload file less than 2MB.',
      'media.validation.error': 'Error uploading image',

      'attachment.upload.description':
        'File must be less than 2MB \nAllowed fil types: pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': 'Image Upload',

      'promotions.section.discount_summary.set.on_item':
        'Get <b>{{ discount_value }}</b> off on <b>Selected Products</b>',
      'promotions.section.discount_summary.set.on_order':
        'Get <b>{{ discount_value }}</b> off on <b>Entire Order</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? currency : "" }}{{ value }}{{ type == "percentage" ? "%":"" }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Selected Products</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Entire Order</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        ' with purchase of <b>Selected Products</b> over <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' with purchase over <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }} items',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }} off.',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}% off.',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }} Free Gift(s) ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        'Selected Products: Buy {{ itemCount }} items for {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        'Selected Products: Buy {{ itemCount }} items for {{ discountValue }} off.',
      'promotions.section.discount_summary.criteria.bundle_amount':
        'Selected Products: Buy {{ itemCount }} items for {{ discountValue }}% off.',
      'promotions.section.discount_summary.criteria.bundle_gift':
        'Selected Products: Buy {{ itemCount }} items for {{ discountValue }} Free Gift(s) ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': '{{ value }}% off',

      'promotions.page.term.title': 'Term & Condition',
      'promotions.page.term.confirm': 'Confirm',
      'promotions.page.term.default':
        '＊Promotion applied is subject to order summary shown in shopping cart.\n＊Customer should checkout with valid payment & delivery method to enjoy promotion.\n＊Customer should login to checkout for member-only promotion.',
      'promotions.page.start_at':
        'Begins on <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        'Ends on <b class="local-datetime">{{date}}</b>',
      'promotions.page.redeem_gift.start_at':
        'Campaign begins on <span class="local-datetime">{{date}}</span>',
      'promotions.page.redeem_gift.end_at':
        'Campaign ends on <b class="local-datetime">{{date}}</b>',
      'promotions.page.display_local_timezone':
        'Display in your time zone<br/>(GMT{{offset}})',

      'promotions.exclude.items.hint.title':
        'Best priced product is not applicable to:',
      'promotions.exclude.items.hint.order_discount': 'Order-Level Discount',
      'promotions.exclude.items.hint.member_tier': 'Membership % Offer',
      'promotions.exclude.items.hint.user_credit': 'Store Credit Usage',
      'promotions.exclude.items.hint.member_point': 'Member Point Usage',

      'product.page.title.product-info': 'Product',
      'product.page.quantity': 'Qty',
      'product.page.unit_price': 'Unit Price',
      'product.page.subtotal': 'Subtotal',
      'product.page.add_to_cart_count':
        'Already added <span class="order-detail-count">{{count}}</span> item(s)',
      'product.member_only': 'Member Only',
      'product.tier_member_only': '{{tierName}} Only',

      'products.quick_cart.quantity': 'Quantity',
      'products.quick_cart.preorder_now': 'PREORDER NOW',
      'products.quick_cart.add_to_cart': 'ADD TO CART',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': 'SOLD OUT',
      'products.quick_cart.out_of_stock_hint':
        'Not enough stock.\n Your item was not added to your cart.',
      'products.quick_cart.low_stock_hint':
        'Not enough stock.\n Please adjust your quantity.',
      'products.quick_cart.insufficient_point':
        'Not enough points, please remove some gifts',
      'products.quick_cart.reached_max_purchase_quantity':
        'Limit {{ message }} per order.',
      'products.quick_cart.quantity_of_stock_hint':
        'Only {{message}} item(s) left.',
      'products.quick_cart.messagetobuy':
        'Please message the shop owner for order details.',
      'products.quick_cart.message': 'MESSAGE',
      'products.quick_cart.show_more': 'More Details',
      'products.message.title': 'Are you sure you want to remove this item?',
      'products.message.button_cancel': 'Cancel',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'Filter',
      'products.category.advance_filter.clear': 'Clear selection',
      'products.category.advance_filter.clear_all': 'Clear all',
      'products.category.advance_filter.apply': 'Apply Filter',
      'products.category.advance_filter.apply.hint':
        'Please select the filter options first',
      'products.category.advance_filter.show_more': 'Show more',
      'products.category.advance_filter.show_less': 'Show less',
      'products.category.advance_filter.type.color': 'Color',
      'products.category.advance_filter.type.size': 'Size',
      'products.category.advance_filter.type.brand': 'Brand',
      'products.category.advance_filter.type.material': 'Material',
      'products.category.advance_filter.type.price':
        'Price Range ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': 'Min',
      'products.category.advance_filter.placeholder.max_price': 'Max',

      'products.variant_selector.hide': 'Hide',
      'products.variant_selector.view_other_variations':
        'View other {{count}} variants',

      'hk_sfplus.region': 'Region',
      'hk_sfplus.area': 'Area',
      'hk_sfplus.district': 'District',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'zeek.station.address': 'Self pickup address',
      'zeek.station.name': 'Self pickup location name',
      'zeek.station.provider': 'Self pickup service provider',
      'zeek.pickup.region': 'Self pickup region',
      'zeek.pickup.area': 'Self pickup district',

      'tcat.time_slot.title': 'Arrival Time Slot',
      'tcat.time_slot.01': 'Before 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': 'Any time slot',

      'line.checkbox.connect.success': 'Subscribe LINE@ success',
      'line.checkbox.connect.failure': 'Subscribe fail, please try login again',

      'return_order.fill_infomation': 'Fill Return Information',
      'return_order.confirmation': 'Return Order Confirmation',
      'return_order.select_return_product': 'Select Return Product',
      'return_order.order_number': 'Order Number',
      'return_order.product_name': 'Product Name',
      'return_order.quantity': 'Quantity',
      'return_order.unit_price': 'Unit Price',
      'return_order.return_quantity': 'Return Quantity',
      'return_order.prcie_amount': 'Subtotal',
      'return_order.return_reason': 'Return Reason',
      'return_order.return_reason.not_expected': 'Not as expected',
      'return_order.return_reason.broke_during_delivery':
        'Parcel damaged on arrival',
      'return_order.return_reason.wrong_variation': 'Size issue',
      'return_order.return_reason.wrong_item': 'Incorrect item received',
      'return_order.return_reason.other': 'Others',
      'return_order.return_reason.admin_return_order': 'Return By Merchant',
      'return_order.return_reason.return_remark':
        'Please specify in additional note',
      'return_order.return_reason.return_remark_placeholder': '60 characters',

      'return_order.return_delivery.info': 'Delivery Details',
      'return_order.return_delivery.delivery_option': 'Return Delivery Type',
      'return_order.return_delivery.status': 'Return Delivery Status',
      'return_order.return_delivery.receiver_name': 'Return Name',
      'return_order.return_delivery.receiver_phone': 'Return Phone',
      'return_order.return_delivery.receiver_address': 'Return Address',
      'return_order.return_delivery.optional': 'Optional',
      'return_order.return_delivery.destination': 'Shipping Destination',

      'return_order.return_payment.info': 'Refund Details',
      'return_order.return_payment.payment_option': 'Return Payment Type',
      'return_order.return_payment.status': 'Refund Status',
      'return_order.return_payment.custom_return':
        'Others（Please contact us for refund details）',
      'return_order.return_payment.bank_transfer_return':
        'Provide refund account',
      'return_order.return_payment.bank_account': 'Bank Name & Account',
      'return_order.return_payment.bank_transfer_placeholder':
        '812 TSBank xxxxxxxxxxxx',
      'return_order.return_payment.shopline_payment': 'Shopline Payment',
      'return_order.form.agree_return_policy':
        'I agree to the shop\'s <a href="{{ link }}" target="_blank" rel="noopener">Return Policy</a>',
      'return_order.form.validation': 'You must agree with the return policy',
      'return_order.form.submit': 'Confirm Return Order',
      'return_order.back_to_order_detail': 'Back to order information page',

      'return_order.detail.order_submitted':
        'Your return order has been submitted!',
      'return_order.detail.confirmation_emailed':
        'An return order confirmation email will be sent to below email',
      'return_order.detail.return_order_no': 'Return Order No.',
      'return_order.detail.apply_date': 'Apply Date',
      'return_order.confirmation.refund_note':
        'The acutal refund amount will be displayed on <a href="{{ link }}">Return Order Information Page</a>.',
      'return_order.confirmation.refund_question':
        'Please <a id="js-contact-us">Contact Us</a> if you have any question.',

      'return_order.inspect.status': 'Return Inspection Status',
      'return_order.inspect.status.pass': 'Pass',
      'return_order.inspect.status.fail': 'Fail',
      'return_order.refund.record': 'Refund Record',
      'return_order.refund.amount': 'Refund Amount',
      'return_order.refund.date': 'Refund Date',
      'return_order.coninue_shopping': 'Continue Shopping',

      'return_order.inspect.status.pending': 'Uninspected',
      'return_order.delivery_status.return_collected': 'Returned',
      'return_order.delivery_status.returning': 'Returning',
      'return_order.delivery_status.pending': 'Returning',
      'return_order.delivery_status.arrived': 'Arrived',
      'return_order.payment_status.pending': 'Pending',
      'return_order.payment_status.refunded': 'Refunded',
      'return_order.payment_record.pending': 'Pending',

      'order_comment_and_message.general_error':
        'System error. Please contact the shop owner or try again later.',
      'order_comment_and_message.rate_limit_error':
        "You've reached today's message limit. please try again tomorrow.",

      'member_referral.discount': 'Member Referral Campaign',
      'member_referral.rules': 'Campaign Rules',
      'member_referral.code.copy_and_share':
        'Share the link with your friend, you both get store credits.',
      'member_referral.code.remarks': 'Campaign Rules',
      'member_referral.generate.code': 'Generate referral link',
      'member_referral.generate.code.success': 'Referral link generated',
      'member_referral.copy.link': 'Copy Link',
      'member_referral.copy.link.success': 'Link Copied',
      'member_referral.welcome_credit.popup.title':
        "Congratulation, You've earned {{reward_value}} credits",
      'member_referral.welcome_credit.popup.content':
        "You've earned  {{reward_value}} credits. You can refer your friends to earn more credits.",
      'member_referral.welcome_credit.popup.continue': 'Shop Now',

      'lock_inventory.tooltip':
        'Reserve till {{expired_time}} \n(Live streamed at {{start_time}} :{{event_name}})\n Display according to your local time zone (GMT {{timezone}})',
      'instagram_banner.description':
        'The browser you’re using right now doesn’t allow cookies, please try another browser if you encounter any login, checkout, or add to cart issues. <span>Copy Link</span>',
      'facebook_banner.description':
        'You’re visiting the website on Facebook in-app browser, please use another browser if you encounter upload image issue. <span>Copy Link</span>',

      'product_set.build': 'Build your product set',
      'product_set.special_offer': 'Product Set Special Offer',
      'product_set.items_needed':
        '<span class="highlight-label">{{ quantity }}</span> item(s) needed',
      'product_set.add_to_set': 'Add to set',
      'product_set.items_needed.diff':
        '{{ currentQuantity }} item(s) selected, you still need <span class="highlight-label">{{ stillNeedQuantity }}</span> item(s) to get your discount!',
      'product_set.confirm': 'Confirm',
      'product_set.items_selected':
        '<span class="highlight-label">{{ quantity }}</span> item(s) selected',
      'product_set.reach_limit': 'Purchase limit reached',
      'product_set.reach_limit.congrats':
        '<span class="highlight-label">{{ quantity }}</span> item(s) selected, congrats on the discount you\'ve got!',
      'product_set.no_items_selected': 'No items selected',
      'product_set.quick_cart.add_to_set': 'Add to set',
      'product_set.exceed_limit':
        'Exceed product set purchase limit, please adjust your quantity.',
      'product_set.set_includes': 'Set Includes:',
      'product_set.quantity': 'Product set quantity',
      'product_set.sold_out': 'Not enough stock',
      'product_set.please_select': 'Please select your item',
      'product_set.not_enough_stock.please_remove':
        'Not enough stock, please remove this item.',
      'product_set.not_enough_stock':
        'Not enough stock, please adjust your quantity.',
    });
    $translateProvider.preferredLanguage('en');
  },
]);
