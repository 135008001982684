/**
 * Directive to display cart count
 **/
app.directive('slCartCount', [
  'cartService',
  function (cartService) {
    return {
      restrict: 'C',
      scope: {},
      link: function (scope, element) {
        function updateCount(count) {
          if (count && count > 0) {
            element.text(count > 99 ? '+' : count);
            element.show();
          } else {
            element.text('');
            element.hide();
          }
        }

        scope.$on('cartItemCountUpdated', function (event, count) {
          updateCount(count);
        });
        updateCount(cartService.getItemCount());
      },
    };
  },
]);
