app.directive('mobileCheckProcess', [
  '$http',
  '$interval',
  '$window',
  'mobilePhoneService',
  'RecaptchaService',
  function ($http, $interval, $window, mobilePhoneService, RecaptchaService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.mobile-check-process.html'),
      scope: {
        resource: '=',
        mobileSignInStep: '@',
        formMode: '@',
        registrationFormSubmit: '&',
        getUserInfo: '&',
      },
      link: function (scope) {
        scope.mobileCodeForm = {
          submitting: false,
        };

        scope.$on('mobile-step-change', function (event, step) {
          scope.mobileSignInStep = step;
        });

        scope.goBack = function ($event) {
          RecaptchaService.reset('submit-btn');
          $event.preventDefault();
          scope.mobileCodeForm.errorMessages = null;
          scope.$emit('mobile-step-change');
        };

        scope.sendCode = function (countryCallingCode, mobilePhone) {
          scope.mobileCodeForm.submitting = true;
          scope.mobileCodeForm.errorMessages = null;

          mobilePhoneService
            .sendCode({
              countryCallingCode: countryCallingCode,
              mobilePhone: mobilePhone,
            })
            .then(
              function () {
                scope.mobileCodeForm.submitting = false;
                scope.mobileSignInStep = 'enter_code';
                scope.resendCountdown = 60;
                var countdownInterval = $interval(function () {
                  scope.resendCountdown--;
                  if (scope.resendCountdown === 0) {
                    $interval.cancel(countdownInterval);
                  }
                }, 1000);
              },
              function (response) {
                scope.mobileCodeForm.submitting = false;
                scope.mobileCodeForm.errorMessages = response.data.error
                  ? [response.data.error]
                  : [response.data.error_messages];
              },
            );
        };

        scope.formSubmit = function () {
          scope.mobileCodeForm.submitting = true;
          scope.mobileCodeForm.errorMessages = null;

          if (!scope.resource.code) {
            scope.mobileCodeForm.submitting = false;
            return;
          }

          if (scope.formMode == 'forgotPassword') {
            mobilePhoneService
              .verifyCode({
                countryCallingCode: scope.resource.countryCallingCode,
                mobilePhone: scope.resource.mobilePhone,
                code: scope.resource.code,
                isForgetPassword: true,
              })
              .then(
                function (res) {
                  $window.location.href =
                    '/users/password/edit?reset_password_token=' +
                    res.data.reset_password_token +
                    $window.location.search.replace('?', '&') +
                    '&reset=true';
                },
                function (error) {
                  scope.mobileCodeForm.errorMessages =
                    error.data.error_messages;
                  scope.mobileCodeForm.submitting = false;
                },
              );
          } else if (
            scope.formMode == 'registration' &&
            typeof scope.registrationFormSubmit === 'function'
          ) {
            scope
              .registrationFormSubmit()
              .then(function () {
                scope.mobileCodeForm.submitting = true;
              })
              .catch(function () {
                scope.mobileCodeForm.submitting = false;
              })
              .finally(function () {
                RecaptchaService.reset('sign-up-recaptcha');
              });
          } else if (
            scope.formMode == 'member_center' &&
            typeof scope.getUserInfo === 'function'
          ) {
            var params = {
              countryCallingCode: scope.resource.countryCallingCode,
              mobilePhone: scope.resource.mobilePhone,
              code: scope.resource.code,
            };

            mobilePhoneService.verifyCode(params).then(
              function success() {
                scope.mobileCodeForm.submitting = false;
                scope.$emit('mobile-step-change');
                scope.getUserInfo();
              },
              function error(error) {
                scope.mobileCodeForm.submitting = false;
                scope.mobileCodeForm.errorMessages = error.data.error_messages;
              },
            );
          }
        };
      },
    };
  },
]);
