import { Payment } from '@payment/payment-sdk';

const SHOPLINE_PAYMENT_CLIENT_ID = 'EC1.0';

app.directive('checkoutShoplinePaymentV2', [
  'logger',
  'mainConfig',
  'cart',
  function (logger, mainConfig, cart) {
    var countryList = [
      'zh-cn',
      'zh-hant',
      'en',
      'de',
      'ru',
      'fr',
      'es',
      'ja',
      'th',
    ];
    var paymentMethodMap = {
      credit: 'CreditCard',
      atm_virtual_account: 'VirtualAccount',
    };
    return {
      restrict: 'A',
      scope: '=',
      link: function (scope) {
        var getCountry = function (countryCode) {
          var targetCountry = countryList.find(function (country) {
            return countryCode === country;
          });
          if (targetCountry) {
            if (targetCountry === 'zh-hant') {
              return 'zh-TW';
            } else if (targetCountry === 'zh-cn') {
              return 'zh-CN';
            }

            return targetCountry;
          }
          return 'en';
        };
        try {
          const amount =
            cart.total.currency_iso === 'TWD'
              ? cart.total.cents * 100
              : cart.total.cents;

          var paymentConfig = {
            clientId: SHOPLINE_PAYMENT_CLIENT_ID,
            paymentMethod:
              paymentMethodMap[
                cart.payment_method.config_data.shopline_payment_payment
              ],
            merchantId: mainConfig.merchantData.sl_payment_merchant_id,
            channelId: 'SLPayments',
            countryCode: mainConfig.merchantData.base_country_code,
            currency: mainConfig.merchantData.base_currency_code,
            language: getCountry(mainConfig.localeData.loadedLanguage.code),
            installmentCounts: cart.payment_method.config_data.installments,
            amount: amount,
            env: mainConfig.shoplinePaymentV2Env,
          };

          new Payment(paymentConfig, '#checkout-shopline-payment-v2-form')
            .init()
            .then(function (data) {
              scope.shoplinePaymentV2Content = data;
            });
        } catch (e) {
          logger.error(e);
        }
      },
    };
  },
]);
