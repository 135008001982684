app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('id', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',

      'form.action.delete': 'Hapus',
      'recaptcha.language': 'en',

      'form.validation.required': '{{field_name}} wajib diisi',
      'form.validation.maxlength': '{{field_name}} terlalu panjang',
      'form.validation.minlength': '{{field_name}} terlalu pendek',
      'form.validation.invalid': '{{field_name}} tidak valid',
      'form.validation.pattern': '{{field_name}} tidak valid',
      'form.validation.pattern.recipient-name':
        'Tidak memperbolehkan spasi dan simbol, atau lebih dari 5 karakter.',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} harus memasukkan 10 digit angka',
      'form.validation.email': '{{field_name}} tidak valid',
      'form.validation.unique': '{{field_name}} dgunakan. Pilih lagi.',
      'form.validation.confirmation': 'Kata sandi tidak cocok.',
      'form.validation.policy':
        'Anda harus menyetujui syarat dan ketentuan untuk melanjutkan.',
      'form.validation.delivery_option.outlying':
        'Jenis pengiriman yang Anda pilih tidak tersedia untuk pulau-pulau terluar di Taiwan, ubah alamat pengiriman atau pilih metode pengiriman lain.',
      'form.validation.delivery_option.local':
        'Jenis pengiriman yang Anda pilih tidak tersedia untuk pulau-pulau di Taiwan, ubah alamat pengiriman ke pulau-pulau terluar atau pilih metode pengiriman lain.',
      'form.validation.delivery_option.country':
        'Jenis pengiriman yang Anda pilih tidak tersedia untuk negara tersebut, ubah alamat pengiriman atau pilih metode pengiriman lain.',
      'form.validation.delivery_option.region':
        'Jenis pengiriman yang Anda pilih tidak tersedia untuk wilayah ini, ubah alamat pengiriman atau pilih metode pengiriman lain.',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',

      'dropdown.hint': 'Pilih',

      'session.signup.title': 'Daftar',
      'session.terms.facebook': 'Daftar menggunakan Facebook',
      'session.signup.facebook': 'Login Facebook',
      'sessions.or': 'ATAU',
      'session.terms':
        'Dengan melanjutkan, berarti Anda menyetujui syarat dan ketentuan kami.',
      'session.signin.submit': 'Login',
      'session.forgot_password': 'Lupa Kata Sandi',
      'session.signin.title': 'Login',
      'session.signin.facebook': 'Login Facebook',
      'session.mobile_signup.check_mobile.title':
        'Periksa nomor ponsel Anda apakah sudah benar, sms akan dikirim ke nomor ponsel ini',
      'session.mobile_signup.check_mobile.your_number': 'Nomor ponsel Anda',
      'session.mobile_signup.check_mobile.your_number.hint':
        'Masukkan nomor ponsel Taiwan (awali dengan 09)',
      'session.mobile_signup.check_mobile.send_code':
        'Kirimi saya kode pemeriksaan',
      'session.mobile_signup.check_mobile.go_back.1': 'Anda bisa',
      'session.mobile_signup.check_mobile.go_back.2': 'kembali',
      'session.mobile_signup.check_mobile.go_back.3':
        'untuk membetulkan nomor ponsel jika salah',
      'session.mobile_signup.input_code.hint':
        'Masukkan kode pemeriksaan yang Anda terima',
      'session.mobile_signup.input_code.submit': 'Pergi',
      'session.mobile_signup.input_code.resend.countdown':
        'Kirim ulang sms kode pemeriksaan ke ponsel saya ({{resendCountdown}} detik)',
      'session.mobile_signup.input_code.resend':
        'Kirim ulang sms kode pemeriksaan ke ponsel saya',
      'session.forget_passowrd.check_phone.error':
        'Nomor ponsel salah, periksa lagi',
      'session.mobile_center.check_phone.error': 'Nomor ponsel sudah digunakan',

      'user.quick_signup.title': 'Gabung anggota sekarang!',
      'user.quick_signup.title.content': 'Jangan lewatkan penawaran eksklusif!',
      'user.quick_signup.promotion':
        'Saya ingin menerima pembaruan dan promosi terbaru',
      'user.quick_signup.policy': 'Saya setuju dengan',
      'user.quick_signup.policy.url_content':
        ' Ketentuan Penggunaan dan Kebijakan Privasi toko',
      'user.quick_signup.submit': 'Gabung Sekarang',
      'user.quick_signup.error.format': 'Kesalahan Format',
      'user.quick_signup.error.confirmed': 'No. ponsel ini telah digunakan. ',
      'user.quick_signup.error.confirmed.url': 'Login sekarang.',
      'user.quick_signup.validate.title': 'Lanjutkan Mendaftar',
      'user.quick_signup.validate.success':
        'Terima kasih telah bergabung dengan kami sebagai anggota！<br/> Anda dapat menyelesaikan proses pendaftaran, <br/>atau',
      'user.quick_signup.validate.success.shop': 'ayo belanja sekarang',
      'user.quick_signup.validate.finish_hint':
        'Selesaikan pendaftaran untuk memeriksa dan menggunakan kredit toko segera!',
      'user.quick_signup.validate.phone': 'Verifikasi',
      'user.quick_signup.validate.send.sms': 'Kirim kode verifikasi',
      'user.quick_signup.validate.wrong.number': 'Jika no. ponsel salah,',
      'user.quick_signup.validate.previous': 'kembali',
      'user.quick_signup.validate.modify': ' dan perbarui',
      'user.quick_signup.validate.hint.password': 'Minimal 8 karakter',
      'user.quick_signup.validate.hint.send':
        'Kode verifikasi dikirim. Anda dapat mengirim ulang kode verifikasi baru setelah {{count}} detik.',
      'user.quick_signup.validate.hint.resend':
        'Belum menerima kode pemeriksaan? Klik di sini untuk mengirim ulang.',
      'user.quick_signup.validate.submit': 'Selesaikan Pendaftaran',
      'user.quick_signup.validate.placeholder.sms': 'Masukkan kode verifikasi',
      'user.quick_signup.validate.placeholder.name': 'Nama',
      'user.quick_signup.validate.placeholder.password': 'Masukkan kata sandi',
      'user.sign_up.email_verification.dialog.title':
        'Email Verifikasi dikirim ke {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        'Verifikasi email sekarang dan alamat email baru akan diperbarui. Jika tidak menerimanya, masukkan kembali email Anda dan simpan.',
      'user.sign_up.email_verification.dialog.confirm': 'Konfirmasi',
      'user.delivery_data.recipient_phone': 'Nomor kontak penerima',

      'product.out_of_stock': 'Persediaan habis',
      'product.addon_products.label': 'Item Add-on',
      'product.bundled_products.label': 'Paket',
      'product.bundle_group_products.label': 'A + B',
      'product.addon_products.errors.main_product_out_of_stock':
        'Stok tidak cukup untuk produk utama',
      'product.addon_products.errors.reached_max_purchase_quantity':
        'Batasi {{message}} per pesanan.',
      'product.addon_products.errors.addon_product_out_of_stock':
        'Stok tidak cukup untuk item add-on',
      'product.addon_products.errors.larger_addon_product':
        'Jumlah item add-on tidak boleh lebih besar dari jumlah produk utama',
      'product.addon_products.errors.out_of_stock':
        'Stok tidak cukup untuk add-on {{title}}.',
      'product.addon_products.tips.limit_exceed':
        'Saat Anda menyesuaikan jumlah, jumlah item add-on tidak boleh lebih besar dari produk utama.',
      'product.addon_products.add_to_cart.hint':
        'Item berhasil ditambahkan ke keranjang Anda',

      'member.center.load.more': 'Lainnya',
      'member.center.load.more.error': 'Tidak dapat memuat data, coba lagi.',

      'wishlist.signin_register': 'Login/daftar sekarang',
      'wishlist.messages.remove_error':
        'Tidak dapat menghapus item di wishlist.',

      orders: 'Pesanan',
      order: 'Pesanan',
      'orders.fields.billing_address': 'Alamat Penagihan',
      'orders.fields.order_number': 'No. Pesanan.',
      'orders.fields.order_date': 'Tanggal',
      'orders.fields.order_status': 'Status',
      'orders.fields.order_remarks': 'Keterangan Pesanan',
      'orders.fields.customer_name': 'Nama Pelanggan',
      'orders.fields.product_subscription_period': '{{period}}',
      'orders.fields.status.temp': 'Tertunda',
      'orders.fields.status.pending': 'Tertunda',
      'orders.fields.status.confirmed': 'Dikonfirmasi',
      'orders.fields.status.shipped': 'Terkirim',
      'orders.fields.status.completed': 'Selesai',
      'orders.fields.status.received': 'Diterima',
      'orders.fields.status.cancelled': 'Dibatalkan',
      'orders.fields.status.returning': 'Pengembalian',
      'orders.fields.status.returned': 'Dikembalikan',
      'orders.fields.preorder_item': 'Preorder Item',
      'orders.fields.customer': 'Pelanggan',
      'orders.fields.customer.phone': 'Nomor telepon pelanggan',
      'orders.fields.billto': 'Tagihan kepada',
      'orders.fields.payment_type': 'Jenis Pembayaran',
      'orders.fields.payment_instructions': 'Instruksi Pembayaran',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        'Transaksi gagal: Jumlah pesanan maksimum untuk Pembayaran CVS adalah NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        'Transaksi gagal: Jumlah pembayaran terendah untuk Pembayaran CVS adalah NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        'Transaksi gagal: Jumlah pesanan maksimum untuk Pembayaran Barcode adalah NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        'Transaksi gagal: Jumlah pesanan terendah untuk Pembayaran Barcode adalah NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'Batas atas per transaksi menggunakan CVS adalah NTD20.000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'Batas atas per transaksi menggunakan Barcode adalah NTD20.000',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'Batas atas per transaksi menggunakan WebATM adalah NTD30.000',
      'orders.fields.delivery_method': 'Metode Pengiriman',
      'orders.fields.delivery_description': 'Deskripsi',
      'orders.fields.delivery_fee': 'Biaya Pengiriman',
      'orders.fields.delivery_address': 'Alamat Pengiriman',
      'orders.fields.delivery_address.remarks': 'Keterangan Pengiriman',
      'orders.fields.accept_terms.validation.required':
        'Anda harus menyetujui syarat dan ketentuan untuk melanjutkan',
      'orders.fields.options.blacklist.error':
        'Produk Anda berisi metode pengiriman atau pembayaran yang berbeda. Pilih metode lain yang valid atau masukkan pesanan yang sesuai.',
      'orders.show.message.thankyou.title': 'Terima kasih atas pesanannya',
      'orders.show.message.thankyou.description': '',
      'orders.show.thankyou.continue': 'Lanjutkan belanja sekarang !',

      'orders.show.message.shipped.title': 'Item Anda telah dikirim',
      'orders.show.message.shipped.description': '',

      'orders.payment_slip.order_info.amount': 'Nomor Pesanan',
      'orders.payment_slip.order_info.instructions': 'instruksi pembayaran',
      'orders.payment_slip.order_info.read_more': 'Petunjuk View Full',
      'orders.payment_slip.upload_form.title':
        'Setelah selesai transfer, silakan upload rincian pembayaran transfer',
      'orders.payment_slip.upload_form.hint':
        'Setelah konfirmasi pembayaran, kami akan mengatur pengiriman secepat mungkin!',
      'orders.payment_slip.upload_form.notification':
        'Untuk mendapatkan pesanan pemberitahuan terbaru, silakan berlangganan',
      'orders.payment_slip.upload_form.image_input.title': 'Memuat transfer',
      'orders.payment_slip.upload_form.image_input.description':
        'Harap mengkonfirmasi bahwa gambar terlihat jelas, dan termasuk 5 digit terakhir dari jumlah transaksi, waktu dan jumlah transfer.',
      'orders.payment_slip.upload_form.image_input.change':
        'Klik untuk mengubah citra',
      'orders.payment_slip.upload_form.image_input.error':
        'ukuran gambar harus lebih kecil dari 10 MB',
      'orders.payment_slip.upload_form.paid_time': 'Masa Pembayaran pilih',
      'orders.payment_slip.upload_form.message':
        'Masukkan Catatan Pembayaran Anda',
      'orders.payment_slip.upload_form.message.error':
        'Tidak boleh melebihi {{ n }} aksara',
      'orders.payment_slip.uploaded.alert':
        'Terima kasih untuk pembayaran meng-upload informasi transfer, kami akan mengkonfirmasi secepatnya!',
      'orders.payment_slip.uploaded.notification':
        'Berlangganan untuk mendapatkan informasi pesanan terbaru',
      'orders.payment_slip.uploaded.title':
        'Berikut di bawah ini adalah detail transfer Anda',
      'orders.payment_slip.uploaded.paid_time': 'Waktu pembayaran:',
      'orders.payment_slip.uploaded.upload_time': 'Memperbarui Waktu Bukti:',
      'orders.payment_slip.uploaded.order_link': 'Tampilan Pemesanan',
      'orders.payment_slip.uploaded_without_login.title':
        'Terima kasih! Maklumat pemindahan pembayaran telah dimuat naik untuk pesanan ini',
      'orders.payment_slip.uploaded_without_login.hint':
        'Harap masuk untuk melihat rincian',
      'orders.payment_slip.footer':
        'Maklumat pembayaran anda mengisi hanya untuk pemeriksaan bayaran dan telah dilindungi dengan sistem sekuriti. Anda boleh membuat bayaran dengan lega.',

      'orders.index.no_records.title': 'Anda tidak memiliki pesanan apa pun.',
      'orders.index.no_records.description':
        'Sepertinya Anda belum pernah memesan dari toko ini.',
      'orders.index.no_records.action': 'Ayo Belanja',
      'orders.index.title': 'Pesanan',
      'orders.index.action.view': 'Lihat',
      'address.fields.address.recipient_name': 'Nama Penerima',
      'address.fields.address': 'Alamat',
      'address.fields.city': 'Kota',
      'address.fields.postcode': 'Kode Pos',
      'address.fields.country': 'Negara',

      'orders.fields.subtotal': 'Subtotal',
      'orders.fields.total': 'Total',
      'order.billing_address.same': 'Sama seperti alamat pengiriman',

      'orders.coupons.label': 'Kode Kupon',
      'orders.coupons': 'Selamat! Anda berhak mendapatkan diskon berikut:',
      'orders.coupons.placeholder': 'Masukkan Kode Kupon',
      'orders.promotion_coupons.placeholder': 'Masukkan Kode Kupon',

      'orders.coupons.apply': 'Gunakan Kupon',
      'orders.fields.order_discount': 'Diskon diterapkan',
      'orders.fields.order_custom_discount': 'Diskon (khusus)',
      'orders.coupons.invalid': 'Maaf, kupon Anda tidak valid.',
      'orders.coupons.validation.minamount':
        'Maaf, pesanan Anda tidak memenuhi nilai yang diperlukan untuk kupon ini',
      'orders.coupons.invalid.order':
        'Hapus kode kupon yang tidak valid sebelum memasukkan pesanan.',

      'orders.actions.title': 'Kelola Status Pesanan',
      'orders.action.cancel': 'Batalkan Pesanan',
      'orders.action.confirm': 'Konfirmasi Pesanan',
      'orders.action.ship': 'Tandai sebagai Terkirim',
      'orders.general.title': 'Detail pesanan',
      'orders.payment.title': 'Detail pembayaran',
      'orders.delivery.title': 'Detail pengiriman',
      'orders.products.title': 'Detail Produk',

      'orders.fields.accept_terms':
        'Anda menyetujui Ketentuan penggunaan dan Kebijakan Privasi Toko',
      'orders.action.checkout': 'Masukkan Pesanan',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        'Nama Toko 7-11',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        'Kode Toko 7-11',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'Nama Toko Family Mart',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'Kode Toko Family Mart',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': 'Cari toko',
      'orders.fields.delivery_data.tw_ezship.location_code.label': 'Kode Toko',
      'orders.fields.delivery_data.tw_ezship.location_name.label': 'Nama Toko',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        'Nama Penerima',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': 'ID Referensi',

      // Checkout page error message field name:
      'order.customer_name': 'Nama pelanggan',
      'order.email': 'Alamat email',
      'order.customer_email': 'Alamat email pelanggan',
      'order.customer_phone': 'Nomor kontak pelanggan',
      'order.delivery_data.recipient_name': 'Nama penerima',
      'order.delivery_data.recipient_phone': 'Nomor kontak penerima',
      'order.delivery_address.address_1': 'Alamat',
      'order.delivery_address.city': 'Kota',
      'order.delivery_address.state': 'Distrik/Negara Bagian/Provinsi',
      'order.delivery_data.location_code': 'Kode Toko',
      'order.delivery_data.location_name': 'Nama Toko',
      'order.delivery_data.time_slot': 'Waktu Pengiriman',
      'order.delivery_data.scheduled_delivery_date': 'Tanggal kedatangan',
      'order.delivery_data.time_slot_key': 'Slot Waktu Kedatangan',
      'order.invoice.carrier_number': 'Operator',
      'order.invoice.mailing_address': 'Alamat',
      'order.invoice.tax_id': 'ID Pajak',
      'order.payment_data.holdername': 'Nama Lengkap Pemegang Kartu',
      'order.payment_data.expiry_date': 'Tanggal Kedaluwarsa',
      'order.payment_data.cvc': 'CVC ',
      'order.payment_data.credit_card_number': 'Nomor Kartu Kredit',
      'delivery.sfexpress.district': 'SF Express District ',
      'delivery.sfexpress.store': 'Lokasi SF Express',
      'delivery_address.region.hk': 'Wilayah',
      'delivery_address.district.hk': 'Distrik',
      'delivery_address.region.tw': 'Negara Bagian/Kota',
      'delivery_address.district.tw': 'Distrik',
      'save_fields.customer_info.birthday': 'Ulang tahun pelanggan',

      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'Province',
      'delivery_address.district.th': 'District',
      'delivery_address.ward.th': 'Sub-district',

      'delivery_options.fields.delivery_type': 'Jenis Pengiriman',
      'delivery_options.fields.delivery_types.pickup': 'Pengambilan',
      'delivery_options.fields.delivery_types.local': 'Lokal',
      'delivery_options.fields.delivery_types.email': 'Email',
      'delivery_options.fields.delivery_types.international': 'Internasional',
      'delivery_options.fields.delivery_types.custom': 'Khusus',
      'delivery_options.fields.delivery_types.tw_simple_711':
        'Pengambilan 7-11 (khusus TW)',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'Pengambilan Family Mart (khusus TW)',
      'delivery_options.fields.select_date': 'Tanggal Kedatangan',

      'ezship.error.invalid_input':
        'Satu kolom lainnya tidak valid atau tidak ada',
      'ezship.error.account_not_exist':
        'Akun ezShip tidak ada. Hubungi pedagang',
      'ezship.error.no_permission': 'Akun ezShip tidak memiliki cukup izin',
      'ezship.error.no_easybag':
        'Akun ezShip tidak memiliki Easybag atau Minibag yang tersedia',
      'ezship.error.invalid_store': 'Toko Pengambilan Tidak Valid',
      'ezship.error.invalid_amount':
        'Jumlah tidak valid. Jumlah maksimal yang diizinkan menggunakan ezShip adalah TWD 6000 per pesanan.',
      'ezship.error.invalid_email': 'Email tidak valid',
      'ezship.error.invalid_mobile': 'Nomor telepon tidak valid',
      'ezship.error.invalid_name': 'Nama penerima tidak valid',
      'ezship.error.system_error':
        'Kesalahan sistem ezShip. Pilih metode pengiriman lain atau coba lagi nanti.',

      'payments.fields.types.paypal': 'Kartu kredit atau Paypal',
      'payments.fields.types.paypal_express': 'Kartu kredit atau Paypal',
      'payments.fields.types.credit_card': 'Kartu kredit',
      'payments.fields.types.bank_transfer': 'Transfer Bank',
      'payments.fields.types.cash_on_delivery': 'Pembayaran Tunai',
      'payments.fields.types.free_checkout': 'Checkout Gratis',
      'payments.fields.types.custom': 'Khusus',
      'payments.fields.types.allpay_barcode': 'Barcode melalui Allpay',
      'payments.fields.types.allpay_credit': 'Kartu Kredit melalui Allpay',
      'payments.fields.types.allpay_cvs': 'CVS melalui Allpay',
      'payments.fields.types.allpay_webatm': 'WebATM melalui Allpay ',
      'payments.fields.types.asiapay': 'Kartu Kredit melalui Asiapay',
      'payments.fields.types.prizm': 'Visa Kredit/Master',
      'payments.fields.types.esun': 'Kartu Kredit Esun',

      'payment.cc.expirydate': 'Tanggal Kedaluwarsa',
      'payment.cc.holdername': 'Nama Pemegang Kartu',
      'payment.cc.number': 'Nomor Kartu Kredit (Visa/Master)',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': 'BB/TT',
      'payment.cc.holdername.placeholder': 'Nama Pemegang Kartu',
      'payment.cc.holdername.hint': 'Sama seperti nama lengkap di kartu',
      'payment.cc.number.placeholder': 'Nomor Kartu Kredit (tanpa spasi)',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint':
        'Kode keamanan 3 digit biasanya ada di belakang kartu Anda.',
      'payment.action.confirm': 'Konfirmasi',
      'payment.cc.update.instructions':
        'Masukkan kartu kredit Anda di bawah ini untuk memperbarui',
      'payment.cc.fields.accept_terms':
        'Saya setuju dengan Ketentuan penggunaan dan Kebijakan Privasi',
      'payment.cc.fields.accept_terms.validation.required':
        'Anda harus menyetujui syarat dan ketentuan untuk melanjutkan',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',

      'payments.fields.types.ezship':
        'Bayar pada Pengambilan Toko melalui ezShip',

      'cart.title': 'Kereta Belanja',
      'cart.item.remove': 'Hapus',
      'cart.items.remove': 'Hapus',
      'cart.checkout': 'Checkout',
      'cart.empty': 'Keranjang Belanja Anda kosong.',
      'cart.empty.description':
        'Tambahkan produk ke keranjang untuk melanjutkan.',
      'cart.empty.continue': 'Lanjutkan belanja sekarang!',

      'checkout.instructions': 'Detail pesanan',
      'checkout.fields.email': 'Email Pelanggan',
      'checkout.fields.email.hint':
        'Isi alamat email Anda dengan benar karena konfirmasi pesanan akan dikirim ke email Anda.',
      'checkout.fields.phone.hint':
        'Isi nomor ponsel Anda agar menerima pembaruan status pengiriman melalui SMS',
      'checkout.fields.phone.invalid': 'Nomor telepon tidak valid',
      'checkout.fields.email.placeholder': 'Alamat Email',
      'checkout.fields.remarks': 'Keterangan untuk Toko',
      'checkout.fields.remarks.placeholder':
        'Beri keterangan pesanan untuk Toko',
      'checkout.fields.delivery_method': 'Metode Pengiriman',
      'checkout.fields.out_of_stock':
        'Maaf, stok tidak cukup, hapus dari keranjang dan checkout lagi.',
      'checkout.fields.phone': 'Nomor telepon kontak',
      'checkout.fields.phone.placeholder': 'Masukkan nomor telepon kontak Anda',
      'checkout.fields.add_new': 'Tambah baru...',

      'checkout.delivery_option.title': 'Alamat Pengiriman',
      'checkout.instalment_amount': 'sekitar {{amount}} x {{period} angsuran',
      'checkout.labels.promotion.free_shipping': '',
      'checkout.labels.promotion.coupon': '',
      'checkout.labels.promotion.promotion': '',
      'checkout.labels.promotion.membership': '',

      'checkout.payment_method.title': 'Pembayaran',

      'checkout.discount.applied_promotions': 'Promosi yang Berlaku',
      'checkout.discount.applied_coupons': 'Kupon yang Berlaku',

      'checkout.promotion.tags.promotion': 'Promosi',
      'checkout.promotion.tags.coupon': 'Kupon',
      'checkout.promotion.tags.membership': 'Penawaran Anggota',
      'checkout.promotion.tags.free_shipping': 'Gratis Ongkir',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',

      'checkout.payments.apple_pay.not_supported':
        'Perangkat tidak mendukung Apple Pay, periksa<ul><li>LOGIN ke browser dengan Akun Apple Anda</li>' +
        '<li>Please ADD the payment method to your Apple Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payments.google_pay.not_supported':
        'Perangkat tidak mendukung Google Pay, periksa<ul><li>LOGIN ke browser dengan Akun Google Anda</li>' +
        '<li>Please ADD the payment method to your Google Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payment_failed': 'Transaksi ini gagal, coba lagi.',
      'checkout.cart_over_limit_error':
        'The maximum number of items is 100, please adjust the quantity and purchase again',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': 'Nama Penerima',
      'address.fields.address.city': 'Kota',
      'address.fields.address.state': 'Distrik/Negara Bagian/Provinsi',
      'address.fields.address.postcode': 'Kode pos (jika ada)',
      'address.fields.address.country': 'Negara',
      'address.fields.address.remarks': 'Keterangan',

      'messages.form.send': 'Kirim',
      'action.add_photo': 'Tambahkan Foto',
      'action.send': 'Kirim',
      'action.load_earlier': 'Muat Sebelumnya',
      'action.load_more': 'Muat Lebih Banyak',

      'users.fields.name': 'Nama Lengkap',
      'users.fields.email': 'Email',
      'users.fields.mobile_phone_or_email': 'Email atau nomor ponsel',
      'users.fields.password': 'Kata sandi',
      'users.fields.name.placeholder': 'Nama Lengkap',
      'users.fields.email.placeholder': 'Email',
      'users.fields.password.placeholder': 'Kata sandi',
      'users.save.success': 'Perubahan yang Anda buat sudah disimpan.',
      'users.save.email_verification.success':
        'Perubahan yang Anda buat sudah disimpan. Alamat email akan diperbarui setelah verifikasi selesai.',
      'users.add.phone': 'Tambahkan nomor baru',
      'users.add.phone.maximum':
        'Anda hanya dapat menambahkan hingga 5 nomor telepon.',
      'users.error.phone.format': 'Nomor Telepon tidak valid',
      'users.error.phone.required': 'Nomor Telepon diperlukan',
      'users.add.delivery_address': 'Tambahkan alamat baru',
      'users.add.delivery_address.maximum':
        'Anda hanya dapat menambahkan hingga 5 alamat.',
      'users.error.no_changes': 'Tidak ada perubahan untuk disimpan.',
      'users.error.maxlength': ' terlalu panjang.',
      'users.signup.male': 'Pria',
      'users.signup.female': 'Wanita',
      'users.signup.other': 'Dirahasiakan',
      'users.signup.email': 'Daftar melalui Email',
      'users.signup.mobile_phone': 'Daftar melalui nomor ponsel',
      'users.signup.unconfirmed':
        'Selamat datang kembali! Untuk melindungi akun Anda, verifikasi diperlukan untuk login ke akun Anda. Email verifikasi telah dikirim ke {{email}}. Periksa di kotak surat Anda, terima kasih!',
      'users.signup.verification.hint.send':
        'Email verifikasi dikirim. Anda dapat mengirim ulang kode verifikasi baru setelah {{count}} detik.',
      'users.einvoice_carrier':
        'Jumlahkan faktur elektronik ke dalam akun Anda',

      'membership.profile.subscriptions.orders.update.status':
        'Pembaruan untuk pesanan saya',
      'membership.profile.subscriptions.orders.comments.new':
        'Komentar baru dari pedagang tentang pesanan saya',
      'membership.profile.subscriptions.messages.new':
        'Pesan baru dari pedagang',
      'membership.profile.subscriptions.marketing.news':
        'Pembaruan dan promosi toko',
      'membership.profile.subscriptions.none': 'Tidak ada',
      'membership.profile.mobile.number.verified':
        'Nomor ponsel terverifikasi dan akan menggantikan nomor telepon yang Anda simpan sebelumnya.',
      'membership.profile.check.code.error':
        'Kode pemeriksaan salah! Masukkan kembali atau kirim ulang sms kode pemeriksaan',

      'store_credits.title': 'Kredit Toko',
      'store_credits.credit_balance': 'Saldo Kredit',
      'store_credits.credit_history': 'Riwayat Kredit',
      'store_credits.fields.date': 'Tanggal',
      'store_credits.fields.remarks': 'Alasan Perubahan Kredit',
      'store_credits.fields.value': 'Pergerakan Kredit',
      'store_credits.fields.expiry_date': 'Tanggal Kedaluwarsa',
      'store_credits.fields.balance': 'Saldo Kredit',
      'store_credits.fields.order_prefix': 'Penggunaan kredit pada pesanan',
      'store_credits.fields.welcome_credit': 'Kredit Selamat Datang',
      'store_credits.fields.birthday_credit': 'Kredit Ulang Tahun',
      'store_credits.fields.user_credit_expired': 'Kredit Kedaluwarsa',
      'store_credits.fields.never_expires': 'Tidak Pernah Kedaluwarsa',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward':
        'Kredit diberikan berdasarkan pembelian pesanan minimum pada pesanan',
      'store_credits.fields.revert_credit':
        'Kredit dikembalikan dari pesanan yang dibatalkan',
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.no_data': 'Tidak ada catatan kredit',

      'error.404.title': 'Mohon maaf',
      'error.404.description': 'Halaman ini tidak ada',
      'error.401.title': 'Mohon maaf',
      'error.401.description': 'Anda tidak diperbolehkan mengakses halaman ini',
      'error.403.title': 'Mohon maaf',
      'error.403.description': 'Anda tidak diperbolehkan mengakses halaman ini',
      'error.403.not_signed_in.message': ', mungkin karena Anda belum',
      'error.403.not_signed_in.anchor': 'login',
      'error.continue': 'Lanjutkan belanja sekarang !',

      'orders.comments.title': 'Komentar toko dan pelanggan',
      'orders.comments.text.name': 'Komentar',
      'orders.comments.text.placeholder': 'Masukkan komentar untuk pesanan ini',

      'orders.fields.payment_fee': 'Biaya Pembayaran',

      'orders.invoices.title': 'Faktur',
      'orders.invoices.carrier': 'Operator Umum',
      'orders.invoices.carrier_type.member': 'Anggota',
      'orders.invoices.carrier_type.mobile_barcode': 'Barcode Seluler',
      'orders.invoices.carrier_type.npc_barcode': 'Barcode NPC',
      'orders.invoices.get_invoice': 'Saya perlu tanda terima',

      'orders.notifications.title': 'Notifikasi Pembaruan Pesanan',

      'order.payments.login.confirm': 'Login dan buka untuk membayar lagi!',

      'model.credit_card.title': 'Informasi Kartu Kredit Anda',
      'model.credit_card.subtitle':
        'Masukkan kartu kredit Anda di bawah ini untuk memperbarui',

      'facebook_messenger_checkbox.subscribed':
        'Dikirim ke {{topic}} melalui <img src="https://{{image_host}}/assets/misc/fb_messenger_36x.png" class="fb-app-icon">Messenger',
      'facebook_messenger_checkbox.turn_off': 'Nonaktifkan',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        'Notifikasi Pembaruan Pesanan',
      'facebook_messenger_checkbox.topics.MARKETING':
        'pembaruan dan promosi toko',

      'media.validation.type': 'hanya format jpeg dan png yang diizinkan',
      'media.validation.error': 'Kesalahan saat mengunggah gambar',

      'imagepicker.title': 'Unggahan Gambar',
      'promotions.section.discount_summary.set.on_item':
        'Dapatkan potongan <b>{{ discount_value }}</b> untuk <b>Produk yang Dipilih</b>',

      'promotions.section.discount_summary.set.on_order':
        'Dapatkan potongan <b>{{ discount_value }}</b> untuk <b>Keseluruhan Pesanan</b>',
      'promotions.section.discount_summary.discount_value':
        '<b>{{ type == "amount" ? Currency : ""  }}{{ value }}{{ type == "percentage" ? "%": ""  }} </b> ',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>Produk yang Dipilih</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>Keseluruhan Pesanan</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        ' dengan pembelian <b>Produk yang Dipilih</b> lebih dari <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        ' dengan pembelian lebih dari <b>{{ condition_value }}</b>',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{count}} item ',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': 'potongan {{value}}%',

      'promotions.page.display_local_timezone':
        'Paparkan di zon waktu anda<br/>(GMT{{offset}})',

      'product.page.title.product-info': 'Produk',
      'product.page.quantity': 'Jml',
      'product.page.unit_price': 'Harga Unit',
      'product.page.subtotal': 'Subtotal',
      'product.page.add_to_cart_count':
        'Sudah menambahkan <span class="order-detail-count">{{count}}</span> item(s)',

      'products.quick_cart.quantity': 'Jumlah',
      'products.quick_cart.preorder_now': 'PREORDER NOW ',
      'products.quick_cart.add_to_cart': 'TAMBAHKAN KE KERANJANG',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': 'HABIS TERJUAL',
      'products.quick_cart.out_of_stock_hint':
        'Stok tidak cukup.\n Item tidak ditambahkan ke keranjang Anda.',
      'products.quick_cart.low_stock_hint':
        'Stok tidak cukup.\n Sesuaikan kuantitas Anda.',
      'products.quick_cart.reached_max_purchase_quantity':
        'Batasi {{message}} per pesanan.',
      'products.quick_cart.quantity_of_stock_hint':
        'Hanya {{message}} item tersisa.',
      'products.quick_cart.messagetobuy':
        'Kirimi pesan pemilik toko untuk meminta detail pesanan.',
      'products.quick_cart.message': 'PESAN',
      'products.quick_cart.show_more': 'Detail Selengkapnya',
      'products.message.title': 'Yakin ingin menghapus item ini?',
      'products.message.button_cancel': 'Batal',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'Saring',
      'products.category.advance_filter.clear': 'Hapus pilihan',
      'products.category.advance_filter.clear_all': 'Bersihkan semua',
      'products.category.advance_filter.apply': 'Terapkan Filter',
      'products.category.advance_filter.apply.hint':
        'Harap pilih opsi filter terlebih dahulu',
      'products.category.advance_filter.show_more': 'Menampilkan lebih banyak',
      'products.category.advance_filter.show_less': 'Tampilkan lebih sedikit',
      'products.category.advance_filter.type.color': 'Warna',
      'products.category.advance_filter.type.size': 'Ukuran',
      'products.category.advance_filter.type.brand': 'Merek',
      'products.category.advance_filter.type.material': 'Bahan',
      'products.category.advance_filter.type.price':
        'Kisaran harga ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': 'Min',
      'products.category.advance_filter.placeholder.max_price': 'Max',

      'hk_sfplus.region': 'Wilayah',
      'hk_sfplus.area': 'Daerah',
      'hk_sfplus.district': 'Distrik',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': 'Slot Waktu Kedatangan',
      'tcat.time_slot.01': 'Sebelum pukul 13:00',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec: '
      'tcat.time_slot.04': 'Slot kapan saja',
    });
    $translateProvider.preferredLanguage('en');
  },
]);
